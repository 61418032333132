.about-us {
    padding-top: ac(90px, 60px);
    padding-bottom: ac(91px, 90px);
    background-color: var(--cl-blue);
    z-index: 1;

    &__decor-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        overflow: hidden;
    }

    &__decor {
        position: absolute;
        width: ac(900px, 600px);
        height: ac(900px, 600px);
        bottom: ac(-213px, -160px);
        left: 50%;
        transform: translateX(calc(-50% + 131px));
    }

    &__circle {
        position: absolute;
        right: ac(-365px, -240px);
        bottom: ac(-390px, -190px);
        width: ac(977px, 500px);
        height: ac(977px, 500px);
        border-radius: 100%;
        overflow: hidden;
        z-index: -1;

        img {
            height: 90%;
            /* width: ac(615px, 300px, 768, 1440);
            height: ac(600px, 340px, 768, 1440); */
            /* object-position: ac(0px, -100px, 768, 1440) top; */
            object-position: 100% top;
        }
    }

    &__title, &__descr, &__subtitle {
        color: var(--cl-white);
        width: 100%;
    }

    &__suptitle {
        margin-bottom: ac(17px, 11px);
        font-family: var(--font-sec);
        font-size: ac(20px, 18px);
        line-height: 24px;
        color: var(--cl-white);
        opacity: .5 !important;
    }

    &__subtitle {
        margin-top: ac(62px, 38px);
        padding-top: ac(18px, 20px);
        border-top: 1px solid var(--cl-white);
        font-size: ac(20px, 18px);
        line-height: 32px;
    }

    &__descr {
        margin-top: 21px;
        padding-left: ac(108px, 0px);
        line-height: 26px;

        p:not(:last-child) {
            margin-bottom: 12px;
        }
    }

    &__btn {
        position: absolute;
        left: 50%;
        width: 0;
        height: 0;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        background-color: var(--cl-white);
        text-align: center;
        z-index: 2;
       
        overflow: hidden;

        &:hover {
            background-color: var(--cl-green);

            span:first-child {
                color: var(--cl-white);
            }

            .about-us__arrow {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14'%3E%3Cg fill='none' stroke='%23fff'%3E%3Cpath d='M.354 13.222h12.868V.354' data-name='Контур 19'/%3E%3Cpath d='m.354.354 12.952 12.952' data-name='Контур 20'/%3E%3C/g%3E%3C/svg%3E");
            }
        }

        span:first-child {
            font-size: ac(20px, 14px);
            line-height: ac(24px, 18px);
            font-weight: 600;
            color: var(--cl-navy);
            margin-bottom: ac(8px, 5px);
            transition: .3s ease;
        }

        &:nth-child(1) {
            bottom: ac(490px, 298px);
            transform: translateX(calc(ac(37px, 75px) - 50%));
            transition: background-color .3s ease, width 1s 1.5s cubic-bezier(0.075, 0.82, 0.165, 1.3), height 1s 1.5s cubic-bezier(0.075, 0.82, 0.165, 1.3);

            &.active {
                width: ac(124px, 100px);
                height: ac(124px, 100px);
            }
        }

        &:nth-child(2) {
            bottom: ac(427px, 260px);
            transform: translateX(calc(ac(349px, 275px) - 50%));
            
            transition: background-color .3s ease, width 1s 1.8s cubic-bezier(0.075, 0.82, 0.165, 1.3), height 1s 1.8s cubic-bezier(0.075, 0.82, 0.165, 1.3);
            &.active {
                width: ac(171px, 130px);
                height: ac(171px, 130px);
            }
        }

        &:nth-child(3) {
            bottom: ac(66px, 15px);
            transform: translateX(calc(ac(399px, 310px) - 50%));
            transform-origin: 100% 50%;
            transition: background-color .3s ease, width 1s 1.1s cubic-bezier(0.075, 0.82, 0.165, 1.3), height 1s 1.1s cubic-bezier(0.075, 0.82, 0.165, 1.3);

            &.active {
                width: ac(171px, 130px);
                height: ac(171px, 130px);
            }
        }
    }

    &__arrow {
        width: 14px;
        height: 14px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left bottom;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14'%3E%3Cg fill='none' stroke='%2311293c'%3E%3Cpath d='M.354 13.222h12.868V.354' data-name='Контур 19'/%3E%3Cpath d='m.354.354 12.952 12.952' data-name='Контур 20'/%3E%3C/g%3E%3C/svg%3E");
        transition: .3s ease;
    }


    @mixin md {
        &__title, &__descr, &__subtitle {
            max-width: ac(580px, 350px);
        }
    }

    @mixin max-md {
        padding-bottom: 450px;

        &__decor {
            width: 550px;
            height: 550px;
            bottom: -98px;
            transform: translateX(calc(-50% - 46px));
        }
   
        &__circle {
            right: -170px;
            bottom: -90px;
            width: 420px;
            height: 420px;
    
            img {
                object-position: right top;
            }
        }

        &__btn {
            span:first-child {
                letter-spacing: 0px;
            }
    
            &:nth-child(1) {
                bottom: 323px;
                transform: translateX(calc(-191px + 50%));

                &.active {
                    width: 88px;
                    height: 88px;
                }
            }
    
            &:nth-child(2) {
                bottom: 291px;
                transform: translateX(calc(50% - 18px));

                &.active {
                    width: 104px;
                    height: 104px;
                }
            }
    
            &:nth-child(3) {
                bottom: 70px;
                transform: translateX(calc(50% + 12px));

                &.active {
                    width: 108px;
                    height: 108px;
                }
            }
        }
    }

    @mixin max-xs {
        .about-us__btn:nth-child(1) {
            transform: translateX(calc(50% - 200px));
        }
        .about-us__btn:nth-child(2) {
            transform: translateX(calc(50% - 28px));
        }
        .about-us__btn:nth-child(3) {
            transform: translateX(calc(50% + 2px));
        }

        .about-us__decor {
            transform: translateX(calc(-50% - 56px));
        }
    }
}