.details {
    margin-top: ac(80px, 60px);

    &__wrapper {
        gap: ac(64px, 20px);
    }

    &--job {
        .details {
            &__wrapper {
                gap: ac(80px, 20px);
            }

            &__sidebar {
                padding-top: ac(88px, 20px);
            }

            &__sticky {
                gap: ac(56px, 15px);
            }

            &__photo {
                width: 248px;
                height: 248px;
                margin-bottom: ac(23px, 15px);
            }

            &__pos {
                margin-bottom: ac(31px, 15px);
            }

            &__video {
                max-width: 330px;

                .plyr--full-ui .plyr__video-embed>.plyr__video-embed__container {
                    padding-bottom: 0;
                }

                .plyr--vimeo {
                    .plyr__controls {
                        opacity: 0 !important;
                        pointer-events: none;
                    }

                    .plyr__poster {
                        pointer-events: none;
                    }
                }

                &>span {
                    margin-top: ac(24px, 15px);
                    display: block;
                    text-align: center;
                    font-size: ac(26px, 20px);
                    font-weight: 500;
                    line-height: ac(36px, 28px);
                }

                iframe {
                    width: 100%;
                }

                iframe,
                &>.plyr {
                    height: ac(588px, 412px, 1023, 1024);
                }

                .plyr__volume input[type=range] {
                    max-width: 35px;
                    min-width: 35px;
                }

            }

            &__author-box {
                border-radius: 193px;
                border: 1px solid #08304D;
                padding: ac(32px, 13px) 32px ac(64px, 42px);
                align-items: center;
            }
        }
    }

    &__video-box {
        margin-top: ac(48px, 20px);
    }

    &__title {
        margin-bottom: ac(24px, 15px);
        font-size: ac(32px, 24px);
        line-height: ac(44px, 34px);
        color: var(--cl-dark-green);
    }

    &__subtitle {
        margin-bottom: ac(24px, 15px);
        font-size: ac(20px, 18px);
        line-height: ac(32px, 26px);
    }

    iframe,
    &__video {
        border-radius: 20px;
    }

    &__text {
        img {
            margin-top: ac(39px, 20px);
            position: relative;
            border-radius: 20px;
            overflow: hidden;
            filter: brightness(0.8);
            height: auto;
            width: auto;
        }

        h1 {
            margin-top: .8em;
            margin-bottom: .4em;
        }

        h2 {
            &:not(:first-child) {
                margin-top: ac(40px, 25px);
            }
            &:not(:last-child) {
                margin-bottom: ac(34px, 20px);
            }
        }

        h3 {
            &:not(:first-child) {
                margin-top: ac(40px, 20px);
            }
            &:not(:last-child) {
                margin-bottom: ac(24px, 15px);
            }
            font-weight: 400;
            font-size: ac(32px, 26px);
            line-height: ac(44px, 34px);
        }

        h4,h5,h6 {
            font-family: var(--font-sec);
            &:not(:first-child) {
                margin-top: ac(30px, 20px);
            }
            &:not(:last-child) {
                margin-bottom: ac(20px, 15px);
            }
        }

        h4 {
            font-size: ac(30px, 24px);
            line-height: ac(40px, 32px);
        }

        h5 {
            font-size: ac(26px, 22px);
            line-height: ac(36px, 30px);
        }

        h6 {
            font-size: ac(20px, 18px);
            line-height: ac(30px, 24px);
            font-family: var(--font-main);
        }

        &>ul, &>ol {
            &:not(:last-child) {
                margin-bottom: ac(30px, 15px);
            }
        }

        p {
            &:not(:last-child) {
                margin-bottom: ac(16px, 10px);
            }

        }

        p, li {
            font-size: ac(20px, 16px);
            line-height: ac(32px, 22px);
            font-weight: 400;
            
            a {
                display: inline-block;
                color: var(--cl-green);
                word-break: break-word;
                font-weight: 400;
                transition: .3s ease;
    
                &:hover {
                    color: var(--cl-yellow-sec);
                    text-decoration: none;
                }
            }
        }

        ul, ol {
            &:not(:first-child) {
                margin-top: ac(25px, 15px);
            }

            li {
                font-weight: 600;
                line-height: ac(34px, 22px);

               
            }
        }

        ul {
            &>li {
                position: relative;
                padding-left: ac(49px, 20px);

                &:not(:last-child) {
                    margin-bottom: ac(24px, 15px);
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: ac(0px, 3px);
                    left: 0;
                    width: ac(34px, 15px);
                    height: ac(32px, 15px);
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='34' height='32' fill='none'%3E%3Cpath fill='%2381B6BE' d='M3.09 12.163a2.685 2.685 0 0 1 2.256 2.292c1.603.676 3.21.836 4.43.824a5.943 5.943 0 0 1 2.868-2.864c-.052-2.902-.834-4.375-1.443-5.087a3.7 3.7 0 0 1-2.193-5.935 3.697 3.697 0 1 1 5.03 5.32c-.089 1.744.154 3.535.53 5.17a5.983 5.983 0 0 1 4.416 1.346c1.71-1.321 2.99-3.45 3.609-4.619a3.703 3.703 0 1 1 2.07 2.069 13.452 13.452 0 0 0-4.561 3.781 5.946 5.946 0 0 1 1.023 3.713c2.76 1.696 4.7 2.12 5.962 2.112a3.699 3.699 0 1 1-.797 2.119c-1.939-.897-4.066-1.417-5.895-1.718a5.948 5.948 0 0 1-2.98 2.644c.15 1.6.485 2.635.854 3.304a2.683 2.683 0 1 1-1.594.449 11.005 11.005 0 0 0-.791-3.357 5.966 5.966 0 0 1-6.667-6.15c-1.924-1.098-3.242-1.486-4.136-1.549a2.684 2.684 0 1 1-1.992-3.862v-.002Z'/%3E%3C/svg%3E") center / contain no-repeat;
                }
            }
        }

        ol {
            list-style-type: auto;
            list-style-position: inside;
        }
    }

    &__btn-box {
        justify-content: center;
        margin-top: ac(40px, 30px);

        .btn {
            font-weight: 700;

            &:nth-child(2) {
                border: 1px solid var(--cl-black);
            }
        }
    }

    &__bottom {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: ac(39px, 20px);
        padding-left: ac(10px, 0px);
        gap: 20px;
    }

    &__share {
        font-size: 18px;
        line-height: 36px;
        font-weight: 700;
        color: var(--cl-dark-green);

        span {
            width: 32px;
            height: 32px;
            margin-right: 12px;

            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='%234D8739' d='M23 20a5 5 0 0 0-3.89 1.89l-7.31-4.57a4.46 4.46 0 0 0 0-2.64l7.31-4.57A5 5 0 1 0 18 7c.004.447.072.892.2 1.32l-7.31 4.57a5 5 0 1 0 0 6.22l7.31 4.57A4.788 4.788 0 0 0 18 25a5 5 0 1 0 5-5Zm0-16a3 3 0 1 1 0 6 3 3 0 0 1 0-6ZM7 19a3 3 0 1 1 0-5.999 3 3 0 0 1 0 6Zm16 9a3 3 0 1 1 0-5.999 3 3 0 0 1 0 6Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h32v32H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        }
    }

    &__soc-box {
        &:not(.details__soc-box--alt) {
            padding: 16px;
            border-radius: 200px;
            border: 1px solid rgba(77, 135, 57, 0.10);
            background-color:#F6F9F5;
        }
    }

    &__soc {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: var(--cl-green);
        border-radius: 100%;
        border: 1px solid var(--cl-white);
        transition: .3s ease;

        span {
            width: 14px;
            height: 12px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            transition: .3s ease;
        }

        &--msn {
            span {
                width: 14px;
                height: 12px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='12' fill='none'%3E%3Cpath fill='%23fff' d='M1.05 11.6c-.28 0-.525-.105-.735-.315A1.007 1.007 0 0 1 0 10.55v-9.1C0 1.17.105.925.315.715.525.505.77.4 1.05.4h11.9c.28 0 .525.105.735.315.21.21.315.455.315.735v9.1c0 .28-.105.525-.315.735-.21.21-.455.315-.735.315H1.05ZM7 6.315l5.95-3.903V1.45L7 5.265 1.05 1.45v.963L7 6.315Z'/%3E%3C/svg%3E");
            }
            &:hover {
                span {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='12' fill='none'%3E%3Cpath fill='%23569C3A' d='M1.05 11.6c-.28 0-.525-.105-.735-.315A1.007 1.007 0 0 1 0 10.55v-9.1C0 1.17.105.925.315.715.525.505.77.4 1.05.4h11.9c.28 0 .525.105.735.315.21.21.315.455.315.735v9.1c0 .28-.105.525-.315.735-.21.21-.455.315-.735.315H1.05ZM7 6.315l5.95-3.903V1.45L7 5.265 1.05 1.45v.963L7 6.315Z'/%3E%3C/svg%3E");
                }
            }
        }
        &--f {
            span {
                width: 10px;
                height: 16px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16' fill='none'%3E%3Cpath fill='%23fff' d='m8.724 9.002.444-2.896H6.39v-1.88a1.448 1.448 0 0 1 1.632-1.564h1.263V.196A15.405 15.405 0 0 0 7.043 0C4.755 0 3.26 1.387 3.26 3.898v2.207H.715v2.896h2.544V16h3.13V9l2.335.002Z'/%3E%3C/svg%3E");
            }
            &:hover {
                span {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16' fill='none'%3E%3Cpath fill='%23569C3A' d='m8.724 9.002.444-2.896H6.39v-1.88a1.448 1.448 0 0 1 1.632-1.564h1.263V.196A15.405 15.405 0 0 0 7.043 0C4.755 0 3.26 1.387 3.26 3.898v2.207H.715v2.896h2.544V16h3.13V9l2.335.002Z'/%3E%3C/svg%3E");
                }
            }
        }
        &--in {
            span {
                width: 17px;
                height: 16px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='16' fill='none'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M3.93 16H.34V5.204H3.93V16ZM2.136 3.73h-.024C.908 3.73.128 2.903.128 1.866.128.805.93 0 2.159 0c1.228 0 1.983.806 2.007 1.865 0 1.037-.779 1.866-2.03 1.866ZM16.872 16h-3.59v-5.776c0-1.45-.518-2.44-1.817-2.44-.992 0-1.582.666-1.841 1.312-.095.23-.12.551-.12.875V16h-3.59s.049-9.783 0-10.796h3.59v1.53C9.982 6 10.834 4.951 12.74 4.951c2.362 0 4.133 1.543 4.133 4.858V16Z' clip-rule='evenodd'/%3E%3C/svg%3E");
            }
            &:hover {
                span {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='16' fill='none'%3E%3Cpath fill='%23569C3A' fill-rule='evenodd' d='M3.93 16H.34V5.204H3.93V16ZM2.136 3.73h-.024C.908 3.73.128 2.903.128 1.866.128.805.93 0 2.159 0c1.228 0 1.983.806 2.007 1.865 0 1.037-.779 1.866-2.03 1.866ZM16.872 16h-3.59v-5.776c0-1.45-.518-2.44-1.817-2.44-.992 0-1.582.666-1.841 1.312-.095.23-.12.551-.12.875V16h-3.59s.049-9.783 0-10.796h3.59v1.53C9.982 6 10.834 4.951 12.74 4.951c2.362 0 4.133 1.543 4.133 4.858V16Z' clip-rule='evenodd'/%3E%3C/svg%3E");
                }
            }
        }

        &:hover {
            border-color: var(--cl-green);
            background-color: var(--cl-white);
        }
    }

    &__sticky {
        gap: ac(32px, 15px);
    }

    &__author-box {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 20px;
        border: 1px solid rgba(0, 0, 0, 0.12);
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
        overflow: hidden;

        ul {
            &>li {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                font-family: var(--font-sec);
                position: relative;
                padding-left: 24px;

                &:not(:last-child) {
                    margin-bottom: ac(20px, 15px);
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 10px;
                    left: 0;
                    width: 16px;
                    height: 1px;
                    background-color: var(--cl-dark-green);
                }
            }
        }
    }

    &__head {
        padding: ac(32px, 15px, 320, 1440) ac(32px, 15px, 320, 1440) ac(24px, 15px, 320, 1440);
        font-size: ac(26px, 18px);
        line-height: ac(36px, 28px);
        font-weight: 500;
    }

    &__info {
        width: 100%;
        padding: ac(24px, 15px, 320, 1440) ac(32px, 15px, 320, 1440) ac(64px, 15px, 320, 1440);
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        background-color: #F6F9F5;
    }

    &__photo {
        width: 80px;
        height: 80px;
        border-radius: 100%;
        background-color: var(--cl-green);
        overflow: hidden;
    }

    &__btn {
        display: inline-flex;
        padding-bottom: 5px;
        font-size: 18px;
        font-weight: 600;
        border-bottom: 1px solid var(--cl-black);
        transition: .3s ease;

        &::after {
            content: '';
            width: 14px;
            height: 14px;
            margin-left: 25px;
            transform: translate(-10px, 3px);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none'%3E%3Cpath stroke='%23000' d='M.524 13.392h12.868V.524M.524.524l12.952 12.952'/%3E%3C/svg%3E");
            transition: .3s ease;
        }

        &:hover {
            color: var(--cl-green);
            border-color: var(--cl-green);

            &::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none'%3E%3Cpath stroke='%23569C3A' d='M.524 13.392h12.868V.524M.524.524l12.952 12.952'/%3E%3C/svg%3E");
            }
        }
    }

    &__name {
        margin-bottom: 8px;
        font-size: 26px;
        line-height: 36px;
        font-weight: 500;
    }

    &__pos {
        font-size: 18px;
        line-height: ac(34px, 26px);
        text-align: center;
        font-weight: 600;
        color: var(--cl-dark-green);
    }

    &__text {
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
    }

    @mixin lg {
        &__text-box {
            width: 100%;
            max-width: ac(856px, 450px);
            flex-shrink: 0;
        }

        &--job {
            .details__text-box {
                max-width: ac(888px, 450px);
            }

            .details__sidebar {
                padding-top: 0;
                max-width: 330px;
            }
        }
    }

    @mixin max-lg {
        &__author-box {
            max-width: 400px;
        }

        &--job {
            .details__sticky {
                justify-content: center;
                align-items: center;
            }

            .details__author-box {
                max-width: 330px;
            }
        }
    }

    @mixin max-md {
        &__author-box {
            margin: 0 auto;
        }

        &__sticky {
            flex-direction: column;
        }

        &__info {
            justify-content: center;
        }

        &__name-box {
            align-items: center;
        }
    }

}