.franchise {
    overflow: hidden;
    margin-top: ac(80px, 60px);
    padding-top: ac(80px, 60px);
    padding-bottom: ac(120px, 60px);
    background-color: var(--cl-navy);
    z-index: 1;

    &__title, &__suptitle {
        color: var(--cl-white);
    }

    [data-aos^="fade"][data-aos^="fade"].aos-animate.franchise__suptitle {
        opacity: .5;
    }

    &__suptitle {
        font-size: ac(20px, 18px);
        font-family: var(--font-sec);
        font-weight: 400;
        opacity: .5;
        margin-bottom: 15px;
    }

    &__title {
        margin: 0 auto;
    }

    &__decor {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: ac(1505px, 700px);
        height: ac(1288px, 700px);
        z-index: -1;
    }

    &__slider-box {
        width: 100%;
        max-width: 1028px;
        margin: ac(60px, 25px) auto 0;
        border: 1px solid rgba(255, 255, 255, 0.30);
        background-color: #11293C;
        border-radius: ac(40px, 20px);
        padding: ac(40px, 20px);
        overflow: hidden;
    }

    &__slide {
        margin-top: 24px;
        height: auto;

        &:nth-child(1) {
            .franchise__descr {
                max-width: ac(200px, 150px);
            }
        }
        &:nth-child(3) {
            .franchise__descr {
                max-width: 125px;
            }
        }
        &:nth-child(6) {
            .franchise__descr {
                max-width: 125px;
            }
        }
    }    

    .swiper-grid-column > .swiper-wrapper {
        flex-direction: row;
        margin-top: -24px;
    }

    &__card {
        height: 100%;
        padding: ac(40px, 20px) ac(32px, 15px);
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.20);
        background-color: var(--cl-navy);
        transition: .3s ease;

        &:hover {
            background-color: var(--cl-black-o);
        }
    }

    &__descr {
        margin-top: 16px;
        color: var(--cl-white);
        font-size: ac(20px, 18px);
        line-height: ac(26px, 22px);
        font-weight: 600;
        text-align: center;
    }
    
}