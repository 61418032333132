@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap');

:root {
 
  --cl-light-green: #F6F9F5;
  --cl-black-o: rgba(0, 0, 0, .2);
  --cl-white: #FFFFFF;

  --cl-blue: #11293C;
  --cl-light-blue: #81B6BE;
  --cl-dark-green: #4D8739;
  --cl-white-o: rgba(255, 255, 255, .5);
  --cl-green: #569C3A;
  --cl-pink: #824499;
  --cl-black: #000000;
  --cl-orange: #BB7023;
  --cl-green-alt: #64844B;
  --cl-orange-sec: #C87E27;
  --cl-navy-sec: #08304D;
  --cl-navy: #07293E;
  --cl-yellow-sec: #D4DF40;
 
  --font-main: 'Cabin', sans-serif;
  --font-sec: 'Inter', sans-serif;

  --vh: calc(100vh / 100);
}