.results {
    margin-top: ac(120px, 60px);
    padding: ac(80px, 60px) 0 ac(120px, 60px);
    background-color: var(--cl-navy);

    &--franchising {
        overflow: hidden;

        .results {
            &__card {
                background-color: var(--cl-green);
                
                &:not(:last-child) {
                    &::before {
                        content: '';
                        position: absolute;

                        bottom: -5%;
                        left: 50%;
                        transform: translate(-50%, 50%);
                        width: ac(140px, 97px, 375, 1024);
                        height: ac(320px, 233px, 375, 1024);
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='97' height='233' fill='none'%3E%3Cpath fill='%234D8739' d='M3.202 233C18.53 188.988 26.718 116.251 0 0l97 3.829c-11.2 67.446-18.261 147.85-9.833 226.079L3.202 233Z'/%3E%3C/svg%3E") center / contain no-repeat;

                        z-index: -1;
                    }
                }

                &::after {
                    width: ac(900px, 500px, 320, 1024);
                    height: ac(1434px, 800px, 320, 1024);
                }

                &:last-child {
                    &::after {
                        right: unset;
                        bottom: unset;
                        left: 50%;
                        top: ac(-250px, -120px, 320, 1024);
                        transform: translateX(-50%) rotate(90deg);
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1049' height='1634' fill='none'%3E%3Cpath fill='%231F3E50' d='M249.038 999.741c65.54 2.119 649.882 497.859 721.986 633.839l65.516-144.13c-111.808-31.12-684.445-570.462-637.489-682.419 18.165-50.357-5.121-50.263-31.83-101.357-50.237-96.298-167.984-131.299-263.728-80.59C7.737 675.759-27.033 796.22 23.204 892.518c49.382 87.463 106.27 137.062 225.834 107.223Z'/%3E%3Cpath fill='%231F3E50' d='M202.905 627.367c65.574.222 707.883-493.478 784.786-626.8l60.339 146.372c-112.846 27.109-704.339 545.706-661.403 659.266 16.36 50.972-6.908 50.048-35.422 100.158-53.637 94.447-161.896 98.477-255.772 44.391C1.543 896.7 30.838 848.727 84.475 754.28c52.468-85.648.006-160.997 118.43-126.913Z'/%3E%3C/svg%3E") center / contain no-repeat;
                    }
                }
                &:first-child {
                    &::after {
                        right: unset;
                        top: unset;
                        left: 50%;
                        bottom: ac(-250px, -120px, 320, 1024);
                        transform: translateX(-50%) rotate(90deg);
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1049' height='1634' fill='none'%3E%3Cpath fill='%231F3E50' d='M799.678 999.741c-65.54 2.119-649.882 497.859-721.986 633.839l-65.516-144.13c111.807-31.12 684.444-570.462 637.489-682.419-18.166-50.357 5.12-50.263 31.829-101.357 50.238-96.298 167.984-131.299 263.728-80.59 95.758 50.675 130.528 171.136 80.288 267.434-49.381 87.463-106.268 137.062-225.832 107.223Z'/%3E%3Cpath fill='%231F3E50' d='M845.81 627.367c-65.574.222-707.882-493.478-784.786-626.8L.686 146.94c112.845 27.109 704.338 545.706 661.402 659.266-16.359 50.972 6.909 50.048 35.422 100.158 53.637 94.447 161.896 98.477 255.772 44.391 93.888-54.054 64.598-102.027 10.958-196.474-52.467-85.648-.005-160.997-118.43-126.913Z'/%3E%3C/svg%3E") center / contain no-repeat;
                    }
                }
            }

           
            &__subtitle {
                position: relative;
                margin: ac(60px, 30px) auto 0;
                color: var(--cl-white);
                font-family: var(--font-sec);
                font-size: ac(26px, 19px);
                line-height: ac(40px, 28px);
                z-index: 2;
            }
        }
    }

    &__bg {
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + ac(240px, 50px, 320, 1440)), calc(-50% + ac(220px, 50px, 320, 1440)));
        width: ac(2498px, 1500px, 320, 1440);
        height: ac(2136px, 1500px, 320, 1440);
        z-index: 1;
    }

    &__decor {
        top: ac(-231px, -120px, 768, 1440);
        right: ac(-191px, -50px, 320, 1440);
        width: ac(248px, 100px, 320, 1440);
        height: ac(261px, 110px, 320, 1440);
        z-index: 1;
    }

    &__counter, &__name, &__descr, &__btn, &__title {
        color: var(--cl-white);
    }

    &__card-box {
        margin-top: ac(60px, 30px);
        justify-content: center;
    }

    &__card {
        position: relative;

        width: ac(416px, 280px, 320, 1024);
        height: ac(416px, 280px, 320, 1024);
        background-color: var(--cl-orange-sec);
        border-radius: 100%;
        padding: ac(80px, 30px, 320, 1024) ac(48px, 25px, 320, 1024) ac(64px, 35px, 320, 1024);
        
        &::after {
            content: '';
            position: absolute;
            width: ac(779px, 500px, 320, 1024);
            height: ac(636px, 400px, 320, 1024);
            z-index: -1;
        }
        
        &:last-child {
            &::after {
                left: ac(5px, 15px, 320, 1024);
                top: ac(5px, 15px, 320, 1024);
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='779' height='636' fill='none'%3E%3Cpath fill='%23BB7023' d='M302.1 379.54c64.855-9.679 329.208 135.128 424.541 255.96l51.959-92.461c-89.953-29.176-360.5-237-373-327.5 8.835-52.799-18.057-100.528-53.5-146-66.701-85.721-193.41-90.566-278.5-23.5-85.11 67.034-97.701 191.779-31 277.5 64.274 77.183 147.231 106.812 259.5 56.001Z'/%3E%3C/svg%3E") center / contain no-repeat;
            }
        }
        &:first-child {
            &::after {
                right: ac(5px, 15px, 320, 1024);
                top: ac(5px, 15px, 320, 1024);
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='779' height='636' fill='none'%3E%3Cpath fill='%23BB7023' d='M476.5 379.54c-64.856-9.679-329.209 135.128-424.54 255.96L0 543.039c89.952-29.176 360.5-237 373-327.5-8.835-52.799 18.056-100.528 53.5-146 66.701-85.721 193.409-90.566 278.5-23.5 85.109 67.034 97.701 191.779 31 277.5-64.274 77.183-147.231 106.812-259.5 56.001Z'/%3E%3C/svg%3E") right top / contain no-repeat;
                z-index: -1;
            }
        }
    }

    &__counter {
        margin-bottom: ac(8px, 2px);
        color: var(--cl-white);
        font-size: ac(56px, 30px);
        line-height: ac(72px, 42px);
        font-weight: 600;

        span:nth-child(2) {
            color: var(--cl-yellow-sec);
        }
    }

    &__name {
        margin-bottom: ac(17px, 10px);
        font-size: 26px;
        font-weight: 500;
        line-height: 36px;
    }

    &__btn {
        display: inline-flex;
        padding-bottom: 5px;
        font-size: 18px;
        font-weight: 600;
        border-bottom: 1px solid var(--cl-white);
        transition: .3s ease;
        
        &::after {
            content: '';
            width: 14px;
            height: 14px;
            margin-left: 25px;
            transform: translateX(-10px);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none'%3E%3Cpath stroke='%23fff' d='M.524 13.392h12.868V.524M.524.524l12.952 12.952'/%3E%3C/svg%3E");
            transition: .3s ease;
        }

        &:hover {
            color: var(--cl-navy);
            border-color: var(--cl-navy);

            &::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none'%3E%3Cpath stroke='%2307293E' d='M.524 13.392h12.868V.524M.524.524l12.952 12.952'/%3E%3C/svg%3E");
            }
        }
    }

    &__descr {
        font-size: ac(16px, 13px, 320);
        line-height: ac(26px, 18px, 320);
    }

    @mixin lg {
        &__card {
            padding: ac(80px, 20px, 1024, 1440) ac(48px, 20px, 1024, 1440) ac(64px, 35px, 1024, 1440);
            width: ac(416px, 310px, 1024, 1440);
            height: ac(416px, 310px, 1024, 1440);

            &::after {
                width: ac(779px, 500px, 1024, 1440);
                height: ac(636px, 400px, 1024, 1440);
            }
            
            &:last-child {
                &::after {
                    left: ac(5px, 15px, 1024, 1440);
                    top: ac(5px, 15px, 1024, 1440);
                }
            }
            &:first-child {
                &::after {
                    right: ac(5px, 15px, 1024, 1440);
                    top: ac(5px, 15px, 1024, 1440);
                }
            }
        }

        &--franchising {
            .results__card {
                &:not(:last-child) {
                    &::before {
                        top: 50%;
                        left: unset;
                        right: -5%;
                        transform: translate(50%, -50%);
                        width: ac(335px, 200px, 1024, 1440);
                        height: ac(140px, 100px, 1024, 1440);
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='335' height='140' fill='none'%3E%3Cpath fill='%234D8739' d='M0 4.592C63.23 26.572 167.725 38.313 334.734 0l-5.5 139.098c-96.895-16.062-212.406-26.187-324.792-14.101L0 4.592Z'/%3E%3C/svg%3E") center / contain no-repeat;
                    }
                }

                &::after {
                    width: ac(1049px, 700px, 1024, 1440);
                    height: ac(1634px, 900px, 1024, 1440);
                }

                &:last-child {
                    &::after {
                        left: ac(5px, 15px, 320, 1024);
                        top: 50%;
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1049' height='1634' fill='none'%3E%3Cpath fill='%231F3E50' d='M249.038 999.741c65.54 2.119 649.882 497.859 721.986 633.839l65.516-144.13c-111.808-31.12-684.445-570.462-637.489-682.419 18.165-50.357-5.121-50.263-31.83-101.357-50.237-96.298-167.984-131.299-263.728-80.59C7.737 675.759-27.033 796.22 23.204 892.518c49.382 87.463 106.27 137.062 225.834 107.223Z'/%3E%3Cpath fill='%231F3E50' d='M202.905 627.367c65.574.222 707.883-493.478 784.786-626.8l60.339 146.372c-112.846 27.109-704.339 545.706-661.403 659.266 16.36 50.972-6.908 50.048-35.422 100.158-53.637 94.447-161.896 98.477-255.772 44.391C1.543 896.7 30.838 848.727 84.475 754.28c52.468-85.648.006-160.997 118.43-126.913Z'/%3E%3C/svg%3E") center / contain no-repeat;
                        transform: translateY(-49%);
                    }
                }
                &:first-child {
                    &::after {
                        left: unset;
                        bottom: unset;
                        right: ac(5px, 15px, 320, 1024);
                        top: 50%;
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1049' height='1634' fill='none'%3E%3Cpath fill='%231F3E50' d='M799.678 999.741c-65.54 2.119-649.882 497.859-721.986 633.839l-65.516-144.13c111.807-31.12 684.444-570.462 637.489-682.419-18.166-50.357 5.12-50.263 31.829-101.357 50.238-96.298 167.984-131.299 263.728-80.59 95.758 50.675 130.528 171.136 80.288 267.434-49.381 87.463-106.268 137.062-225.832 107.223Z'/%3E%3Cpath fill='%231F3E50' d='M845.81 627.367c-65.574.222-707.882-493.478-784.786-626.8L.686 146.94c112.845 27.109 704.338 545.706 661.402 659.266-16.359 50.972 6.909 50.048 35.422 100.158 53.637 94.447 161.896 98.477 255.772 44.391 93.888-54.054 64.598-102.027 10.958-196.474-52.467-85.648-.005-160.997-118.43-126.913Z'/%3E%3C/svg%3E") center / contain no-repeat;
                        transform: translateY(-49%);
                    }
                }
            }
        }
    }
}