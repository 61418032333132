.job-list {
    margin-top: ac(80px, 60px);

    &__wrapper {
        width: 100%;
        margin-top: ac(89px, 30px);
        gap: ac(80px, 20px);
    }

    &__col {
        width: 100%;
        max-width: ac(932px, 700px, 1024, 1440);
        flex-shrink: 0;
    }

    &__list {
        gap: ac(57px, 20px);
    }

    &__range {
        display: flex;
        flex-direction: column;
    }

    &__range-info {
        padding: 10px;
        margin-bottom: 20px;
        width: 100%;
        border-radius: 5px;
        border: 1px solid #C0C0C0;
        background:  #F6F9F5;

        font-size: 14px;
        line-height: 20px;
        font-family: var(--font-sec);
    }

    .single-range {
        padding-right: 10px;
        margin-bottom: 13px;
        height: 2px;
        border-radius: 12px;
        border: none;
        box-shadow: none;
        background: var(--cl-navy-sec);
    
        &-container {
            width: 100%;
            padding-top: 11px;
        }
    
        .noUi-connect {
            background: var(--cl-dark-green);
        }
    
        .noUi-handle {
            background: var(--cl-dark-green);
            width: 16px!important;
            height: 16px;
            cursor: pointer;
            border-radius: 2px;
            box-shadow: none;
            top: -7px;
            right: -16px!important;
            border: none;
    
            &:before,
            &:after {
                content: none;
            }
        }
    }

    .pagination {
        margin-top: 24px;
    }

    .jobs__card {
        border-color: var(--cl-dark-green);

        &:hover {
            border-color: var(--cl-orange);
        }
    }

    .jobs__name {
        color: var(--cl-dark-green);
        margin-right: ac(129px, 25px);
        padding-bottom: 0;
    }

    .jobs__info, .jobs__info {
        color: var(--cl-black);
    }

    .jobs__info:not(:last-child) {
        margin-bottom: 13px;
    }

    .jobs__arrow {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14'%3E%3Cg fill='none' stroke='%23000'%3E%3Cpath d='M.354 13.222h12.868V.354' data-name='Контур 19'/%3E%3Cpath d='m.354.354 12.952 12.952' data-name='Контур 20'/%3E%3C/g%3E%3C/svg%3E");
    }

    &__filter-box {
        margin-top: ac(-40px, -20px);
        width: 100%;
    }

    &__title {
        font-size: ac(32px, 24px);
        line-height: ac(44px, 34px);
        font-weight: 400;
        margin-bottom: ac(40px, 20px);
    }

    &__form {
        flex-direction: column;
        align-items: flex-start;
        gap: 28px;

        .btn {
            width: 100%;
            justify-content: center;
            border: 2px solid var(--cl-black);
            line-height: ac(52px, 36px);

            &:last-child {
                margin-top: -12px;
            }
        }
    }

    &__accordion-box {
        width: 100%;

        &.is-active {
            .job-list__head {
                span {
                    background-color: var(--cl-white);

                    &::after {
                        height: 0;
                    }

                    &::before, &::after {
                        background-color: var(--cl-dark-green);
                    }
                }
            } 
            .job-list__descr {
                max-height: 300px;
                visibility: visible;
            }
        }
    }

    &__item {
        width: 100%;
        
        .insights__select {
            height: 40px;
            width: 100%;

            &:not(:last-child) {
                margin-bottom: 16px;
            }

            .choices__inner {
                padding-left: 0px !important;
            }
        }
    }

    &__name {
        margin-bottom: 17px;
    }

    &__name, &__head {
        font-size: ac(20px, 16px);
        line-height: ac(32px, 20px);
        font-weight: 600;
    }

    &__head {
        cursor: pointer;
        transition: .3s ease;
        user-select: none;

        span {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border: 1px solid var(--cl-dark-green);
            border-radius: 100%;
            margin-right: 9px;
            background-color: var(--cl-dark-green);

            &::before, &::after {
                content: '';
                position: absolute;
                background-color: var(--cl-white);
                transition: .3s ease;
            }

            &::before {
                width: 60%;
                height: 1px;
            }

            &::after {
                height: 60%;
                width: 1px;
            }
        }

        &:hover {
            color: var(--cl-dark-green);
        }
    }

    &__descr {
        max-height: 0;
        overflow: hidden;
        visibility: hidden;
        transition: .3s ease;
    }

    &__checkbox {
        cursor: pointer;
        padding-left: 8px;

        input:checked + {
            .job-list__check {
                border-color: var(--cl-dark-green);
                background-color: var(--cl-dark-green);
            }
        }

        &:hover {
            .job-list__label {
                color: var(--cl-dark-green);
            }

            .job-list__check {
                border-color: var(--cl-dark-green);
            }
        }
    }

    &__scroll {
        width: 100%;
        max-height: 190px;

        .simplebar-vertical {
            background-color: rgba(86, 156, 58, 0.2) !important;
        }
        .simplebar-scrollbar:before {
            background-color: var(--cl-green);
            border-radius: 0px;
        }
    }

    &__checkbox-box {
        padding-top: 10px;
        gap: 18px;
        max-height: 200px;
    }

    &__check {
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        border-radius: 2px;
        border: 1px solid #616161;
        transition: .3s ease;

        &::after {
            content: '';
            width: 8px;
            height: 6px;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' fill='none'%3E%3Cpath fill='%23fff' d='M7.765.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L.22 3.53a.75.75 0 1 1 1.06-1.06l1.705 1.704L6.705.235a.75.75 0 0 1 1.06-.03Z'/%3E%3C/svg%3E") center / contain no-repeat;
        }
    }

    &__label {
        transition: .3s ease;
    }

    &__layout {
		position: fixed;
		width: 100%;
		height: 100vh;
		top: 0;
		left: 0;
		background-color: var(--cl-white);
		visibility: hidden;
		opacity: 0;
		transition: 0.3s ease;
		z-index: 2;

		&.active {
			opacity: 0.3;
			visibility: visible;
			cursor: pointer;
		}
	}

    &__burger-box {
        margin-left: auto;
        align-items: center;
        font-size: 20px;
        gap: 10px;
        cursor: pointer;
        transition: .3s ease;
        z-index: 1;

        &:hover {
            color: var(--cl-green);

            .job-list__burger {
                border-color: var(--cl-green);

                span {
                    background-color: var(--cl-green);
                }
            }
        }
    }

    &__burger {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-left: auto;
		width: 34px;
		height: 34px;
		background: transparent;
		border-radius: 100%;
		border: 1px solid var(--cl-black);
		transition: .3s ease;

		span {
			pointer-events: none;
			width: 17px;
			height: 2px;
			background-color: var(--cl-black);
			transition: 0.3s ease;

			&:nth-child(2) {
                width: 13px;
				margin: 3px 0;
			}
			&:nth-child(3) {
                width: 7px;
			}
		}
    }
    .simplebar-track.simplebar-vertical {
        width: 9px;
        right: 0px;
    }

    .simplebar-scrollbar::before {
        width: 5px;
        background-color: var(--cl-green);
        opacity: 1;
    }
    @mixin lg {
        &__burger-box {
            display: none;
        }

        &__filter-box {
            &>.simplebar-wrapper {
                &>.simplebar-mask {
                    &, &>.simplebar-offset>.simplebar-content-wrapper {
                        overflow: visible !important;
                    }
                }
            }
        }
    }

    @mixin max-lg {
        &__col {
            max-width: unset;
            margin-right: 0;
        }

        &__filter-box {
            position: fixed;
            top: 70px;
            left: 0;
            
            margin-top: 0;
            padding: 20px;
            width: 100%;
		    max-width: 400px;

            background: #F6F9F5;
            transform: translateX(-100%);
            opacity: 0;
            visibility: hidden;
            transition: 0.3s ease;
            z-index: 10;

            height: calc((var(--vh) * 100) - 70px);



            &.active {
                transform: translateX(0%);
                opacity: 1;
                visibility: visible;

                .job-list__burger {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    width: 35px;
                    height: 35px;
                    span {
                        &:nth-child(2) {
                            width: 0;
                            opacity: 0;
                        }
              
                        &:nth-child(1) {
                            width: 19px;
                            transform: rotate(45deg) translate(3px, 3px);
                        }
              
                        &:nth-child(3) {
                            width: 19px;
                            transform: rotate(-45deg) translate(4px, -4px);
                        }
                      }
                }
            }
        }
    }
}