.values {
    margin-top: ac(80px, 60px);

    &--industries {
        z-index: 1;
    }

    &__decor {
        top: ac(-205px, -110px, 320, 1440);
        right: ac(-168px, -40px, 320, 1440);
        width: ac(210px, 120px, 320, 1440);
        height: ac(221px, 120px, 320, 1440);
    }

    &__wrapper {
        padding-bottom: ac(108px, 80px, 375, 1440);
    }

    &__slider {
        margin-top: ac(48px, 25px);
    }

    &__card {
        justify-content: center;
        width: 100%;
        padding: ac(35px, 20px, 320, 1440) ac(48px, 20px, 320, 1440) ac(64px, 30px, 320, 1440);
        border-radius: 20px;
        overflow: hidden;
        border: 1px solid var(--cl-navy-sec);
        transition: .5s ease;
    }

    &__icon-box {
        width: 64px;
        height: 64px;
        border-radius: 100%;
        background-color: var(--cl-navy-sec);
        border: 1px solid var(--cl-navy-sec);
    }

    &__icon {
        width: 40px;
        height: 40px;
    }

    &__name {
        margin-top: ac(15px, 10px);
        font-size: ac(32px, 24px);
        line-height: ac(36px, 28px);
    }

    &__simplebar {
        margin-top: ac(28px, 12px);
        width: calc(100% + 16px);
        max-height: calc(ac(32px, 24px) * 7);
    }

    &__descr {
        padding: 0 8px;
        font-size: ac(20px, 18px);
        line-height: ac(32px, 24px);

        &>*:not(:last-child) {
            margin-bottom: ac(16px, 1px);
        }
    }

    &__nav {
        position: absolute;
        bottom: -20px;
        left: 0;
        display: flex;
        width: 100%;
        justify-content: center;
        user-select: none;
        z-index: 10;
    }

    &__btn {
        transition: .3s ease;
        width: 80px;
        height: 80px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;

        &.swiper-button-lock {
            display: none !important;
        }

        &.swiper-button-disabled {
            pointer-events: none;
            opacity: .5;
        }

        &--next {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' fill='none'%3E%3Cg filter='url(%23a)'%3E%3Cpath fill='%23F6F9F5' d='M5 30a30 30 0 1 1 60 0 30 30 0 0 1-60 0Z'/%3E%3C/g%3E%3Cpath stroke='%2364844B' d='m35.099 38.941 9.1-9.099-9.1-9.099M26 29.842h18.317'/%3E%3Cdefs%3E%3Cfilter id='a' width='80' height='80' x='0' y='0' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' result='hardAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dx='5' dy='10'/%3E%3CfeGaussianBlur stdDeviation='5'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeBlend in2='BackgroundImageFix' result='effect1_dropShadow_505_4301'/%3E%3CfeBlend in='SourceGraphic' in2='effect1_dropShadow_505_4301' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' fill='none'%3E%3Cg filter='url(%23a)'%3E%3Cpath fill='%23569C3A' d='M5 30a30 30 0 1 1 60 0 30 30 0 0 1-60 0Z'/%3E%3C/g%3E%3Cpath stroke='%23fff' d='m35.099 38.941 9.1-9.099-9.1-9.099M26 29.842h18.317'/%3E%3Cdefs%3E%3Cfilter id='a' width='80' height='80' x='0' y='0' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' result='hardAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dx='5' dy='10'/%3E%3CfeGaussianBlur stdDeviation='5'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeBlend in2='BackgroundImageFix' result='effect1_dropShadow_505_4301'/%3E%3CfeBlend in='SourceGraphic' in2='effect1_dropShadow_505_4301' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
            }
        }

        &--prev {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' fill='none'%3E%3Cg filter='url(%23a)'%3E%3Cpath fill='%23F6F9F5' d='M5 30a30 30 0 1 1 60 0 30 30 0 0 1-60 0Z'/%3E%3C/g%3E%3Cpath stroke='%2364844B' d='m35.218 20.743-9.1 9.099 9.1 9.1M44.317 29.842H26'/%3E%3Cdefs%3E%3Cfilter id='a' width='80' height='80' x='0' y='0' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' result='hardAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dx='5' dy='10'/%3E%3CfeGaussianBlur stdDeviation='5'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeBlend in2='BackgroundImageFix' result='effect1_dropShadow_505_4295'/%3E%3CfeBlend in='SourceGraphic' in2='effect1_dropShadow_505_4295' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' fill='none'%3E%3Cg filter='url(%23a)'%3E%3Cpath fill='%23569C3A' d='M5 30a30 30 0 1 1 60 0 30 30 0 0 1-60 0Z'/%3E%3C/g%3E%3Cpath stroke='%23fff' d='m35.218 20.743-9.1 9.099 9.1 9.1M44.317 29.842H26'/%3E%3Cdefs%3E%3Cfilter id='a' width='80' height='80' x='0' y='0' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' result='hardAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dx='5' dy='10'/%3E%3CfeGaussianBlur stdDeviation='5'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeBlend in2='BackgroundImageFix' result='effect1_dropShadow_505_4295'/%3E%3CfeBlend in='SourceGraphic' in2='effect1_dropShadow_505_4295' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
            }
        }

        @mixin max-xs {
            width: 60px;
            height: 60px;
        }
    }

    @mixin md {
        &__wrapper {
            min-height: ac(712px, 580px);
        }
       
        &__slider-box {
            margin: 0 auto;
            max-width: ac(632px, 400px);
        }

        &__slide {
            &.swiper-slide {
                position: relative;
                height: 516px;

                .values__card {
                    min-height: 100%;
                    max-width: 100%;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%); 
                }

                &:not(.swiper-slide-active, .swiper-slide-prev, .swiper-slide-next) {
                    opacity: .5;

                    .values__simplebar {
                        margin-top: 0;
                    }
                    .values__card {
                        padding: 54px 26px 52px;
                        max-width: 217px;
                        right: ac(-350px, -200px);
                        min-height: 217px;
                    }

                    .values__name {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }

                &.swiper-slide-active {
                    .values__card {
                        background-color: var(--cl-navy-sec);
                        height: 100%;
                    }

                    .values__icon-box {
                        width: 112px;
                        height: 112px;
                        border-color: var(--cl-orange);
                    }

                    .values__icon {
                        width: 64px;
                        height: 64px;
                    }

                    .values__simplebar {
                        opacity: 1;
                        max-height: calc(ac(32px, 24px) * 7);
                        transition: max-height 0.3s 0.2s linear, opacity .5s .45s ease;
                    }

                    .values__name {
                        color: var(--cl-white);
                    }

                    .values__descr {
                        color: var(--cl-white);
                    }
                    
                }

                &.swiper-slide-next {
                    .values__card {
                        right: unset;
                        left: 0;
                    }

                    &+.swiper-slide {
                        .values__card {
                            right: unset;
                            left: ac(-350px, -200px);
                        }
                    }
                }
    
                &.swiper-slide-prev, &.swiper-slide-next {
                    .values__card {
                        max-width: 284px;
                        padding: 59px 46px 75px;
                        min-height: 283px;
                    }

                    .values__simplebar {
                        margin-top: 0;
                    }

                    .values__name {
                        font-size: ac(20px, 18px);
                        line-height: ac(34px, 24px);
                        font-weight: 600;
                    }
                }
            }
        }

        &__simplebar {
            max-height: 0;
            opacity: 0;
        }
    }

    @mixin max-md {
        &__slider-box {
            display: flex;
        }

        &__card {
            height: 100%;
        }

        [data-simplebar] {
            .simplebar-vertical {
                background-color: var(--cl-black-o);
                .simplebar-scrollbar:before {
                    background-color: var(--cl-black);
                }
            }
        }
    }

    @mixin max-xs {
        &__wrapper {
            padding-bottom: 60px;
        }
    }
}