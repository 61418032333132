.solutions {
    position: relative;
    margin-top: ac(120px, 60px);
    background-color: var(--cl-blue);
    padding: ac(80px, 60px) 0 ac(94px, 60px);
    color: var(--cl-white);

    &::before {
        content: '';
        position: absolute;
        bottom: ac(-115px, -50px, 320, 1440);
        right: ac(-117px, -50px, 320, 1440);
        width: ac(274px, 100px, 320, 1440);
        height: ac(284px, 100px, 320, 1440);
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='271' height='284' fill='none'%3E%3Cpath fill='%23CED03F' d='M103.477 257.698c1.542-10.149 9.624-17.685 19.317-18.984 5.692-13.498 7.03-27.028 6.922-37.292-10.862-5.074-19.284-13.748-24.167-24.152-24.476.422-36.9 7.001-42.898 12.126l-.002.006c-3.024 15.975-18.001 27.076-34.395 25.076-17.106-2.086-29.297-17.618-27.22-34.702 2.072-17.085 17.62-29.25 34.725-27.164 9.08 1.1 16.773 5.998 21.689 12.902 14.708.754 29.81-1.284 43.6-4.442a49.951 49.951 0 0 1 1.455-18.087 49.915 49.915 0 0 1 9.871-19.086C101.221 109.494 83.26 98.712 73.4 93.501a31.15 31.15 0 0 1-17.384 1.65c-16.926-3.232-28.04-19.548-24.82-36.459 3.214-16.906 19.551-27.987 36.48-24.766 16.926 3.232 28.04 19.548 24.82 36.453a31.31 31.31 0 0 1-1.661 5.705c8.834 17.33 20.682 29.727 31.917 38.41 9.023-6.133 20.001-9.306 31.311-8.603 14.283-23.23 17.851-39.557 17.773-50.182-9.457-7.485-14.086-20.124-10.772-32.495C165.52 6.586 182.622-3.272 199.272 1.19c16.651 4.462 26.533 21.55 22.077 38.178-3.867 14.435-17.278 23.767-31.638 23.052-7.553 16.32-11.926 34.225-14.456 49.623 10.259 5.628 18.049 14.599 22.317 25.099 13.501-1.267 22.221-4.075 27.86-7.182a22.82 22.82 0 0 1 .758-4.959c3.232-12.06 15.637-19.202 27.702-15.969 12.065 3.233 19.247 15.629 16.017 27.683-3.23 12.054-15.637 19.201-27.713 15.965a22.56 22.56 0 0 1-12.97-9.298c-10.236.675-19.783 3.243-28.305 6.65.749 6.17.35 12.595-1.355 18.962-6.184 23.079-27.519 38.069-50.475 37.141-9.248 16.193-12.512 27.287-13.036 34.812 8.502 4.278 13.686 13.672 12.179 23.576-1.88 12.337-13.417 20.814-25.772 18.934-12.353-1.886-20.848-13.416-18.973-25.755l-.012-.003Z'/%3E%3C/svg%3E") center / contain no-repeat;
        z-index: 2;
    }

    &__slider {
        z-index: 3;
    }

    &__decor-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    &__decor {
        position: absolute;
        top: ac(-490px, -100px, 320, 1440);
        left: 50%;
        transform: translateX(calc(-50% + ac(345px, 0px, 320, 1440)));

        width: ac(1191px, 500px, 320, 1440);
        height: ac(1376px, 600px, 320, 1440);
    }

    .slider-nav {
        transform: translateY(50%);
    }

    &__img {
        border-radius: 20px;
        overflow: hidden;
    }

    &__col {
        padding-top: ac(9px, 0px);
    }

    &__suptitle {
        font-size: ac(20px, 16px);
        line-height: 1em;
        opacity: .5;
        font-family: var(--font-sec);
    }

    &__title {
        margin-top: ac(18px, 15px);
        padding-bottom: ac(23px, 15px);
        border-bottom: 1px solid var(--cl-light-blue);
    }

    &__subtitle {
        margin-top: ac(21px, 15px);
        font-size: ac(20px, 16px);
        line-height: ac(32px, 20px);
    }

    &__text {
        margin-top: ac(20px, 10px);

        &>*:not(:last-child) {
            margin-bottom: ac(12px, 5px);
        }
    }

    &__slider {
        margin-top: ac(65px, 25px);
        display: grid;
        margin-left: 0;
    }

    &__slide {
        &.swiper-slide-thumb-active, &:hover {
            .solutions__card {
                background-color: var(--cl-dark-green);

                .solutions__step {
                    color: var(--cl-white);
                    opacity: 1;
                }
            }
        }
    }

    &__card {
        padding: ac(28px, 20px) ac(32px, 15px) ac(26px, 20px);
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.50);
        background-color: var(--cl-navy);
        transition: .3s ease;
    }

    &__step {
        margin-bottom: 11px;
        font-family: var(--font-sec);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: var(--cl-light-blue);
        opacity: .5;
        transition: .3s ease;
    }

    &__name {
        margin-bottom: ac(19px, 15px);
        display: inline-flex;
        align-items: center;
        font-size: ac(26px, 20px);
        font-weight: 500;
        line-height: ac(36px, 30px);
        min-height: calc(ac(36px, 30px) * 2);
    }

    &__sep {
        margin-bottom: ac(21px, 15px);
        width: 100%;
        height: 1px;
        opacity: 0.4;
        background: var(--cl-white);
    }

    &__scroll {
        width: 100%;
        max-height: calc(26px * 5);
    }

    &__descr {
        padding-right: 10px;
    }

    @mixin md {
        &__img {
            max-width: ac(666px, 300px);
            height: ac(444px, 200px);
            margin-right: ac(48px, 20px);
        }

        &__subtitle, &__text {
            padding-left: ac(16px, 5px);
        }
    }

    @mixin max-md {
        &__img {
            max-width: ac(566px, 300px, 320, 768);
            height: ac(344px, 180px, 320, 768);
            margin: 0 auto 25px;
        }
    }
}