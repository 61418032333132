h1, .h1 {
	font-size: ac(70px, 34px);
	line-height: ac(88px, 45px);
  font-weight: 700;

  &.sm {
    font-size: ac(56px, 30px);
    line-height: ac(72px, 40px);
    font-weight: 600;

    @mixin max-xs {
      font-size: 26px;
      line-height: 120%;
    }
  }

  @mixin max-sm {
    line-height: 133%;
    font-weight: 600;
  }
  @mixin max-xs {
    font-size: 30px;
    line-height: 120%;
  }
}

h2, .h2 {
  font-size: ac(46px, 30px);
  line-height: ac(56px, 44px);
  font-weight: 600;

  &.lg {
    font-size: ac(56px, 30px);
    line-height: ac(72px, 42px);
  }

  @mixin max-sm {
    line-height: 120%;
  }
}

.not-found, .thanks, .title-small {
  font-size: ac(38px, 20px);
  line-height: ac(48px, 26px);
  font-weight: 600;
}
/* 
p {
  font-size: ac(18px, 16px);
  font-weight: 400;
  color: var(--cl-medium-grey);

  line-height: 144%;

  @mixin max-sm {
    line-height: 162%;
  }
}

li {
  font-size: ac(18px, 16px);
  font-weight: 400;
  color: var(--cl-medium-grey);

  line-height: 144%;

  @mixin max-sm {
    line-height: 162%;
  }
} */

a {
  font-size: ac(20px, 16px);
  font-weight: 500;
  display: inline-block;
  line-height: 100%;
}

.content-element {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  }

  h4 {
    color: var(--cl-medium-grey);
    font-weight: 500;

    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  ol,
  ul {
    li {
      list-style-position: inside;
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  li,
  p {
    /*Not Design*/
    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }
}

.dark-bg {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: var(--cl-white);
  }
}
