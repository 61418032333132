.step {
    margin-top: ac(80px, 60px);
    z-index: 2;

    &__decor {
        right: ac(-280px, -100px);
        bottom: ac(-190px, -60px);
        width: ac(442px, 150px);
        height: ac(464px, 150px);
        z-index: -1;
    }

    &__video {
        border-radius: 20px;
        overflow: hidden;
        z-index: 1;

        iframe {
            height: calc(100% + 2px);
            width: calc(100% + 2px);
            margin-left: -1px;
            margin-top: -1px;
        }
    }

    &__suptitle {
        font-size: ac(20px, 16px);
        line-height: 1em;
        font-family: var(--font-sec);
        color: var(--cl-dark-green);
    }

    &__title {
        margin-top: ac(14px, 10px);
        padding-bottom: ac(23px, 15px);
        border-bottom: 1px solid #A3D68F;
    }

    &__subtitle {
        margin-top: ac(21px, 15px);
        font-size: ac(20px, 16px);
        line-height: ac(32px, 20px);
    }

    &__descr {
        margin-top: ac(20px, 10px);

        &>*:not(:last-child) {
            margin-bottom: ac(12px, 5px);
        }
    }

    &__btn {
        margin-top: ac(36px, 20px);
    }

    @mixin md {
        &__video {
            margin-right: ac(0px, 20px, 1366, 1440);
            margin-top: 46px;
            height: ac(376px, 164px);
        }

        &__col {
            max-width: ac(566px, 400px);
            margin-right: ac(48px, 20px);
        }

        &__subtitle, &__descr { 
            padding-left: ac(16px, 5px);
        }

        &__btn {
            margin-left: ac(16px, 5px);
        }
    }

    @mixin max-md {
        &__video {
            max-width: ac(566px, 300px, 320, 768);
            height: ac(318px, 159px, 320, 768);
            margin: 25px auto 0;
        }
    }
}