.process {
    margin-top: ac(80px, 60px);
    padding: ac(120px, 60px) 0 ac(80px, 30px);
    background-color: #7D508D;

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__wrapper {
        position: relative;
        z-index: 1;
    }

    &__slider-box {
        position: relative;
        width: 100%;
        padding-bottom: 15px;
    }

    &__slider {
        max-width: 425px;
        margin-left: 0;
    }

    &__scroll {
        width: 100%;
        max-height: calc(25px * 12);
        margin-top: 16px;
    }

    &__slide {
        &.swiper-slide-active, &:hover {
            .process__card {
                background-color: rgba(255,255,255, .8);
    
                .simplebar-track.simplebar-vertical {
                    background-color: rgba(0,0,0, .2);
                }
        
                .simplebar-scrollbar:before {
                    background: rgba(0,0,0, 1) !important;
                }

                .process__descr, .process__name, .process__num {
                    color: var(--cl-black);
                }
    
                .process__num {
                    border-color: var(--cl-black);
                }
    
                &::before {
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='152' height='176' fill='none'%3E%3Cpath fill='%23000' d='M113.721 10.522a14.128 14.128 0 0 0-1.629 8.767 14.104 14.104 0 0 0 3.948 7.99c-2.326 8.857-6.819 16.072-10.782 21.116A31.294 31.294 0 0 0 84.11 51.17c-11.942-9.517-15.58-17.503-16.595-22.325l.002-.004a19.468 19.468 0 0 0 1.621-12.966A19.44 19.44 0 0 0 49.82.64a19.477 19.477 0 0 0-18.813 15.889A19.455 19.455 0 0 0 33.05 29.43a19.438 19.438 0 0 0 9.742 8.697 19.46 19.46 0 0 0 13.052.573l.004.001c7.557 5.22 14.246 11.973 19.864 18.783a31.467 31.467 0 0 0-8.556 22.732c-11.001 2.899-23.988 1.409-30.848.243a19.445 19.445 0 0 0-10.043-8.331 19.468 19.468 0 0 0-13.053-.11A19.49 19.49 0 0 0 .265 92.945a19.452 19.452 0 0 0 30.457 13.418 19.49 19.49 0 0 0 7.573-10.639 70.837 70.837 0 0 1 30.413-6.895 31.263 31.263 0 0 0 12.206 16.173c-1.786 16.946-6.239 26.396-10.325 31.632a19.488 19.488 0 0 0-18.998 16.746 19.45 19.45 0 0 0 13.588 21.348 19.48 19.48 0 0 0 13.471-.784 19.495 19.495 0 0 0 9.734-9.352 19.477 19.477 0 0 0 1.323-13.43 19.451 19.451 0 0 0-7.725-11.057c2.482-10.961 7.138-21.496 11.75-30.091a31.315 31.315 0 0 0 20.594-3.952c6.192 5.762 9.436 10.47 11.04 14.155a14.13 14.13 0 0 0 .001 16.342 14.108 14.108 0 0 0 15.423 5.39 14.14 14.14 0 0 0 10.199-12.777 14.114 14.114 0 0 0-8.678-13.841 14.07 14.07 0 0 0-9.956-.323c-4.803-4.229-8.546-9.131-11.464-14.063a31.259 31.259 0 0 0 6.551-9.914c5.733-13.792.891-29.335-10.811-37.604 1.593-11.543 4.204-18.283 6.812-22.212a14.124 14.124 0 0 0 13.796-5.228 14.14 14.14 0 0 0 2.878-7.103 14.107 14.107 0 0 0-13.572-15.657 14.13 14.13 0 0 0-12.824 7.297' opacity='.1'/%3E%3C/svg%3E") center / contain no-repeat;
                }
            }
        }
    }

    &__subtitle {
        color: var(--cl-white);
        opacity: .5;
        font-size: ac(20px, 18px);
        line-height: 1em;
        font-family: var(--font-sec);
        margin-bottom: ac(20px, 10px);
    }

    &__title {
        margin-bottom: ac(40px, 20px);
        color: var(--cl-white);
    }

    &__nav {
        display: flex;
        margin-left: auto;
        align-items: center;
        gap: ac(64px, 30px);
        margin-top: ac(50px, 20px);

        .slider-nav  {
            position: relative;
            right: 0;
            left: unset;
            width: auto;
        }
    }

    &__scrollbar {
        position: relative !important;


        width: 100% !important;
        left: 0 !important;
        bottom: 0 !important;
        top: 0 !important;
        right: 0 !important;
        background: #fff;
        height: 2px !important;
        border-radius: 0 !important;
        opacity: .3;

        .swiper-scrollbar-drag {
            top: -4px;
            background: #fff;
            border-radius: 24px !important;
            height: 10px;
        }
    }

    &__num {
        margin-right: 16px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: ac(60px, 40px);
        height: ac(60px, 40px);
        font-size: ac(26px, 20px);
        font-weight: 500;
        line-height: ac(36px, 26px);
        border-radius: 100%;
        border: 1px solid var(--cl-white);
        color: var(--cl-white);
        transition: .3s ease;
    }

    &__name {
        font-size: ac(20px, 18px);
        line-height: ac(34px, 26px);
        font-weight: 600;
        color: var(--cl-white);
        transition: .3s ease;
    }

    &__descr {
        padding-right: 10px;
        color: var(--cl-white);
        transition: .3s ease;

        &>*:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    &__card {
        position: relative;
        height: 100%;
        padding: ac(34px, 15px);
        background-color: #4B3055;
        border-radius: 20px;
        overflow: hidden;
        transition: .3s ease;

        &::before {
            content: '';
            bottom: -60px;
            right: -0px;
            position: absolute;
            width: 152px;
            height: 176px;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='152' height='176' fill='none'%3E%3Cpath fill='%23fff' d='M113.721 10.522a14.128 14.128 0 0 0-1.629 8.767 14.104 14.104 0 0 0 3.948 7.99c-2.326 8.857-6.819 16.072-10.782 21.116A31.294 31.294 0 0 0 84.11 51.17c-11.942-9.517-15.58-17.503-16.595-22.325l.002-.004a19.468 19.468 0 0 0 1.621-12.966A19.44 19.44 0 0 0 49.82.64a19.477 19.477 0 0 0-18.813 15.889A19.455 19.455 0 0 0 33.05 29.43a19.438 19.438 0 0 0 9.742 8.697 19.46 19.46 0 0 0 13.052.573l.004.001c7.557 5.22 14.246 11.973 19.864 18.783a31.467 31.467 0 0 0-8.556 22.732c-11.001 2.899-23.988 1.409-30.848.243a19.445 19.445 0 0 0-10.043-8.331 19.468 19.468 0 0 0-13.053-.11A19.49 19.49 0 0 0 .265 92.945a19.452 19.452 0 0 0 30.457 13.418 19.49 19.49 0 0 0 7.573-10.639 70.837 70.837 0 0 1 30.413-6.895 31.263 31.263 0 0 0 12.206 16.173c-1.786 16.946-6.239 26.396-10.325 31.632a19.488 19.488 0 0 0-18.998 16.746 19.45 19.45 0 0 0 13.588 21.348 19.48 19.48 0 0 0 13.471-.784 19.495 19.495 0 0 0 9.734-9.352 19.477 19.477 0 0 0 1.323-13.43 19.451 19.451 0 0 0-7.725-11.057c2.482-10.961 7.138-21.496 11.75-30.091a31.315 31.315 0 0 0 20.594-3.952c6.192 5.762 9.436 10.47 11.04 14.155a14.13 14.13 0 0 0 .001 16.342 14.108 14.108 0 0 0 15.423 5.39 14.14 14.14 0 0 0 10.199-12.777 14.114 14.114 0 0 0-8.678-13.841 14.07 14.07 0 0 0-9.956-.323c-4.803-4.229-8.546-9.131-11.464-14.063a31.259 31.259 0 0 0 6.551-9.914c5.733-13.792.891-29.335-10.811-37.604 1.593-11.543 4.204-18.283 6.812-22.212a14.124 14.124 0 0 0 13.796-5.228 14.14 14.14 0 0 0 2.878-7.103 14.107 14.107 0 0 0-13.572-15.657 14.13 14.13 0 0 0-12.824 7.297' opacity='.1'/%3E%3C/svg%3E") center / contain no-repeat;
            transition: .3s ease;
        }
    }

    @mixin lg {
        &__title-box {
            margin-top: ac(89px, 30px);
            max-width: ac(352px, 260px, 1024, 1440);
            margin-right: ac(64px, 20px);
        }

        &__slider-box {
            overflow: hidden;
            flex-shrink: 0;
        }

        &__nav {
            max-width: ac(813px, 660px, 1024, 1440);
            margin-right: ac(50px, 0px);
        }
    }

    @mixin max-lg {
        &__slider-box {
            margin-top: 25px;
        }
    }
}