.map {
  margin-top: ac(95px, 60px);

  &__title {
    width: 100%;
  }

  &__thumb-box {
    margin-top: 24px;
    overflow: hidden;

    .swiper {
      margin-left: -44px;
      margin-right: -44px;
      overflow: hidden !important;
      padding: 0 44px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        z-index: 2;
        pointer-events: none;
      }
    }

    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      height: 44px;
      border-radius: 5px;
      padding: 0 ac(24px, 5px);
      border: 1px solid rgba(0, 0, 0, 0.05);
      background-color: #f6f9f5;
      color: var(--cl-black);
      cursor: pointer;
      transition: color 0.3s ease, background-color 0.3s ease;

      &:hover,
      &-thumb-active {
        background-color: var(--cl-black);
        color: var(--cl-white);
      }
    }
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    cursor: pointer;
    z-index: 2;

    &--prev {
      background: linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 25%
      );
      .map__arrow-icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath stroke='%23000' d='m9.052 17-8-8 8-8m8 8H.947'/%3E%3C/svg%3E");
      }

      &:hover {
        .map__arrow-icon {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath stroke='%234D8739' d='m9.052 17-8-8 8-8m8 8H.947'/%3E%3C/svg%3E");
        }
      }
    }

    &--next {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 25%
      );
      .map__arrow-icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath stroke='%23000' d='m8.948 17 8-8-8-8m-8 8h16.105'/%3E%3C/svg%3E");
      }

      &:hover {
        .map__arrow-icon {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath stroke='%234D8739' d='m8.948 17 8-8-8-8m-8 8h16.105'/%3E%3C/svg%3E");
        }
      }
    }
  }

  &__arrow-icon {
    width: 18px;
    height: 18px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.3s ease;
  }

  &__box {
    width: 100%;
    margin-top: ac(43px, 20px);
    gap: ac(32px, 20px);
  }

  &__first-text {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: 0.3s 0.1s ease;
    overflow: hidden;
    border-radius: 20px;

    &.is-disabled {
      visibility: hidden;
      opacity: 0;
    }

    .map__card {
      height: 100%;

      .simplebar-track.simplebar-vertical {
        top: 10px;
        bottom: 10px;
        width: 10px;
        background-color: transparent;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 4px;
          width: 2px;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.4);
        }

        .simplebar-scrollbar {
          &:before {
            inset: 0 !important;
            opacity: 1;
            border-radius: 24px;
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2) 0%,
                rgba(0, 0, 0, 0.2) 100%
              ),
              #4d8739;
          }
        }
      }
    }

    .map__region {
      margin-bottom: 15px;
      margin-top: 25px;

      &:first-child {
        margin-top: 0;
        font-size: ac(32px, 24px);
        line-height: ac(44px, 34px);
      }
    }
  }

  &__slider {
    display: grid;
    grid-template-columns: minmax(0, 1fr);

    .swiper-slide {
      opacity: 0 !important;

      &-active {
        opacity: 1 !important;
      }
    }
  }

  &__card {
    padding: ac(31px, 20px) ac(22px, 10px) ac(40px, 20px) ac(42px, 15px);
    background-color: var(--cl-navy);
    border-radius: 20px;
    color: var(--cl-white);
  }

  &__region {
    font-size: ac(40px, 26px);
    font-weight: 600;
    line-height: ac(48px, 30px);
    margin-bottom: 9px;
  }

  &__subtitle {
    & > *,
    & {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    h2 {
      font-size: ac(32px, 24px);
      line-height: ac(44px, 34px);
      font-family: var(--font-sec);
      margin-bottom: 8px !important;
    }

    h3 {
      font-size: ac(20px, 18px);
      line-height: ac(24px, 20px);
      font-family: var(--font-sec);
      margin-bottom: 6px !important;
    }

    h4 {
      font-size: ac(18px, 16px);
      line-height: ac(24px, 20px);
      font-family: var(--font-sec);
      margin-bottom: 6px !important;
    }
  }

  &__descr {
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: 0.3s ease;
  }

  &__main {
    position: relative;
    width: 100%;
    z-index: 2;
  }

  &__area,
  &__map-gb {
    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__map-gb {
    z-index: 2;
    transition: opacity 0.3s ease;

    #map_gb {
      & > g {
        cursor: pointer;

        path {
          transition: 0.3s linear;
        }

        &:hover {
          path {
            opacity: 0.5;
          }
        }
      }
    }

    &.is-disabled {
      opacity: 0.2;

      #map_gb {
        g {
          path {
            fill: #88c1cd;
            pointer-events: none;
          }
        }
      }
    }
  }

  &__areas {
    width: 100%;
    position: relative;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;

    &.is-active {
      visibility: visible;
      opacity: 1;
    }
  }

  &__close {
    position: absolute;
    top: ac(20px, 10px, 320, 1440);
    right: ac(20px, 10px, 320, 1440);
    width: 32px;
    height: 32px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath fill='%23000' d='m25.333 8.547-1.88-1.88L16 14.12 8.546 6.667l-1.88 1.88L14.12 16l-7.453 7.453 1.88 1.88L16 17.88l7.453 7.453 1.88-1.88L17.88 16l7.453-7.453Z'/%3E%3C/svg%3E");
    cursor: pointer;
    transition: 0.3s ease;
    z-index: 4;

    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath fill='%23569C3A' d='m25.333 8.547-1.88-1.88L16 14.12 8.546 6.667l-1.88 1.88L14.12 16l-7.453 7.453 1.88 1.88L16 17.88l7.453 7.453 1.88-1.88L17.88 16l7.453-7.453Z'/%3E%3C/svg%3E");
    }
  }

  &__area {
    width: 100%;
    height: 582px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
    overflow: hidden;

    svg {
      position: absolute;
      width: 130%;
      height: 130%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      & > g {
        & > g {
          path {
            transition: 0.3s linear;
          }
          &.is-active,
          &:hover {
            path {
              cursor: pointer;
              fill: var(--cl-green);
              stroke: var(--cl-white);
            }
          }
        }
      }
    }
  }

  &__scroll {
    position: relative;
    max-height: 552px;
    width: 100%;

    &.is-bottom {
      &::before {
        opacity: 0;
      }
    }

    .details__text {
      padding-right: 15px;
      h4,
      h5,
      h6 {
        font-family: var(--font-main);

        &:not(:first-child) {
          margin-top: ac(24px, 18px);
        }
        &:not(:last-child) {
          margin-bottom: ac(8px, 5px);
        }
      }

      p {
        &:not(:last-child) {
          margin-bottom: ac(12px, 8px);
        }
      }
    }

    &::before {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: calc(100% - 15px);
      pointer-events: none;
      height: calc(100% - 32px);
      background: linear-gradient(
        0deg,
        rgba(7, 41, 62, 1) 0%,
        rgba(7, 41, 62, 0) 40%
      );
      z-index: 1;
      transition: 0.3s ease;
    }

    .simplebar-track.simplebar-vertical {
      top: 0px;
      bottom: 0px;
      width: 10px;
      background-color: transparent;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 4px;
        width: 2px;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.4);
      }

      .simplebar-scrollbar {
        &:before {
          inset: 0 !important;
          opacity: 1;
          border-radius: 24px;
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.2) 0%,
              rgba(0, 0, 0, 0.2) 100%
            ),
            #4d8739;
        }
      }
    }
  }

  &__legend-box {
    max-width: 364px;
    width: 100%;
    padding: 13px;
    border-radius: 12px;
    border: 1px solid #c0c0c0;
    background: rgba(255, 255, 255, 0.4);
    gap: ac(16px, 10px) ac(24px, 15px);
    z-index: 5;
  }

  &__legend {
    gap: ac(12px, 5px);

    font-size: ac(20px, 16px);
    font-weight: 600;
    line-height: ac(24px, 20px);
    text-transform: capitalize;
  }

  &__icon {
    width: ac(50px, 36px);
    height: ac(50px, 36px);
    flex-shrink: 0;

    img {
      object-fit: contain;
    }
  }

  @mixin lg {
    &__legend-box {
      position: absolute;
      right: 0;
      top: ac(-185px, -149px);
    }
    &__thumb-box {
      .swiper {
        max-width: ac(625px, 400px, 1024, 1440);
      }
    }
    &__map-gb {
      left: 50%;
      top: ac(-249px, -100px, 1024, 1440);
      transform: translateX(-50%);
      width: ac(625px, 400px, 1024, 1440);
      height: ac(975px, 700px, 1024, 1440);
    }

    &__slider {
      max-width: ac(625px, 400px);
      margin-left: 0;
    }

    &__areas {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      border: 1px solid var(--cl-green);
      border-radius: 20px;
    }

    &__card {
      height: 100%;
    }
  }

  @mixin max-lg {
    &__legend-box {
      margin: 0 auto;
      padding: 5px;
      max-width: unset;
    }
    &__thumb-box {
      margin: 16px auto 0;
    }

    &__box {
      margin: 24px auto 0;
      width: 100%;
      max-width: 535px;
      flex-direction: column-reverse;
      gap: 8px;
    }

    &__main {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px;
      border: 1px solid var(--cl-green);
      border-radius: 20px;
    }

    &__map-gb {
      max-width: 330px;
      max-height: 396px;
      width: 100%;
      height: calc(100% - 24px);
    }

    &__area {
      height: 419px;
    }
  }

  @mixin max-md {
    &__card {
      padding: 20px 5px 20px 15px;
    }

    &__scroll {
      .simplebar-track.simplebar-vertical {
        width: 6px;

        &::before {
          left: 2px;
        }
      }
    }

    &__area {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
