.industries {
    margin-top: ac(80px, 60px);

    &__wrapper {
        gap: ac(37px, 20px);
    }

    &__suptitle {
        margin-bottom: ac(15px, 10px);
        font-family: var(--font-sec);
        font-size: ac(20px, 16px);
        line-height: ac(25px, 20px);
        font-weight: 400;
        color: var(--cl-dark-green);
    }

    &__col {
        width: 100%;
    }

    &__tabs {
        margin-top: ac(41px, 25px);
        gap: 10px 11px;
    }

    &__tab {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: ac(11px, 5px, 320, 1440) ac(30px, 10px, 320, 1440) ac(11px, 5px, 320, 1440) ac(31px, 10px, 320, 1440);
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        color: var(--cl-dark-green);
        background: #F6F9F5;
        font-size: 16px;
        line-height: 26px;
        cursor: pointer;
        transition: .3s ease;

        &:hover, &.is-active {
            background: #5B8643;
            color: var(--cl-white);
        }
    }

    &__text-box {
        position: relative;
        margin-top: ac(53px, 25px);
        height: 443px;
    }

    &__text {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 100%;

        opacity: 0;
        visibility: hidden;
        transform: translateX(-50%);
        transition: .5s ease;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: ac(29px, 20px);
            width: calc(100% - 25px);
            height: 1px;
            background-color: var(--cl-dark-green);
            z-index: -1;
        }

        &.is-active {
            opacity: 1;
            visibility: visible;
            transform: translateX(0%);
        }
    }

    &__name {
        padding-left: 16px;
        font-size: ac(32px, 24px);
        line-height: ac(44px, 34px);

        span {
            width: 40px;
            height: 40px;
            margin-right: 24px;
        }
    }

    &__scroll {
        width: 100%;
        max-height: 272px;
        margin-top: ac(30px, 20px);
        margin-bottom: ac(42px, 20px);

        .simplebar-track.simplebar-vertical {
            background-color: rgba(0,0,0, .2);
        }

        .simplebar-scrollbar:before {
            background: rgba(0,0,0, 1) !important;
        }
    }

    &__descr, &__subtitle {
        font-size: ac(20px, 16px);
        padding-right: 12px;
    }

    &__subtitle {
        font-weight: 600;
        line-height: ac(34px, 26px);
        margin-bottom: ac(28px, 20px);
    }

    &__descr {
        line-height: ac(32px, 24px);

        p {
            &:not(:last-child) {
                margin-bottom: ac(15px, 10px);
            }
        }
    }

    &__btn {
        margin-right: 19px;
        margin-top: auto;
        margin-left: auto;
    }

    &__img-box {
        position: relative;
        margin-top: 17px;
        width: ac(493px, 440px, 1024, 1440);
        height: ac(725px, 650px, 1024, 1440);

        &::before {
            content: '';
            position: absolute;
            top: ac(20px, 10px);
            left: ac(17px, 10px);
            width: 100%;
            height: 100%;
            border-radius: 500px;
            background-color: #BB7023; 
        }
    }

    &__img {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 500px;

        opacity: 0;
        visibility: hidden;
        transform: translate(17px, 20px);
        transition: .5s ease;

        &.is-active {
            opacity: 1;
            visibility: visible;
            transform: translate(0%);
        }
    }

    @mixin lg {
        &__col {
            max-width: ac(720px, 530px, 1024, 1440);
            flex-shrink: 0;
        }
    }

    @mixin max-lg {
        &__wrapper {
            flex-direction: column;
        }

        &__scroll {
            max-height: ac(146px, 300px, 320, 1024);
        }

        &__text-box {
            margin: 20px auto 0;
            height: ac(300px, 450px, 320, 1024);
        }

        &__img-box {
            margin: 0 auto;
            width: 100%;
            max-width: 700px;
            height: ac(400px, 180px, 320, 1024);
        }
    }

    @mixin max-sm {
        &__tabs {
            gap: 6px;
        }
        &__tab {
            width: calc(50% - 3px);
            text-align: center;
        }
    }
}