.popup-team {
  position: relative;
  max-width: 1080px;
  max-height: 85vh;
  padding: ac(24px, 5px);
  border-radius: ac(28px, 20px);
  border: 1px solid rgba(8, 48, 77, 0.2);
  background: #f6f9f5;

  display: grid;
  grid-template-rows: minmax(0, 1fr);

  .simplebar-track.simplebar-vertical {
    width: ac(10px, 5px);
    top: 20px !important;
    bottom: 20px !important;
    right: 0px;
    background: #f6f9f5;
  }

  .simplebar-scrollbar::before {
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    background-color: #88c1cd !important;
    opacity: 1 !important;

  }

  &__wrapper {
    display: flex;
    border-radius: 20px;
    border: 1px solid rgba(8, 48, 77, 0.2);
  }

  &__title {
    max-width: 380px;
    line-height: ac(42px, 30px);
    font-size: ac(30px, 22px);
    font-weight: 600;
    margin-bottom: ac(20px, 15px);
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: ac(43px, 15px);
    right: ac(44px, 15px);
    width: 44px;
    height: 44px;
    border: 1px solid #c0c0c0;
    border-radius: 100%;
    transition: 0.3s ease;
    cursor: pointer;
    z-index: 5;

    &::after {
      content: '';
      width: 12px;
      height: 12px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none'%3E%3Cpath fill='%23000' d='m7.06 6 4.41-4.41A.75.75 0 0 0 10.41.53L6 4.94 1.59.53A.75.75 0 1 0 .53 1.59L4.94 6 .53 10.41a.75.75 0 0 0 1.06 1.06L6 7.06l4.41 4.41a.75.75 0 1 0 1.06-1.06L7.06 6Z'/%3E%3C/svg%3E");
      cursor: pointer;
      transition: 0.3s ease;
    }

    &:hover {
      background-color: var(--cl-green);

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none'%3E%3Cpath fill='%23fff' d='m7.06 6 4.41-4.41A.75.75 0 0 0 10.41.53L6 4.94 1.59.53A.75.75 0 1 0 .53 1.59L4.94 6 .53 10.41a.75.75 0 0 0 1.06 1.06L6 7.06l4.41 4.41a.75.75 0 1 0 1.06-1.06L7.06 6Z'/%3E%3C/svg%3E");
      }
    }
  }

  &__card {
    width: 100%;
    max-width: ac(310px, 240px);
    padding: ac(31px, 20px);
    background-color: var(--cl-white);
    flex-shrink: 0;
    border-radius: 20px 0 0 20px;
    border-right: 1px solid rgba(8, 48, 77, 0.2);

    .team-list__img {
      margin-bottom: 0;

      &--orange {
        background-color: #c87e27;
      }
    }

    .team-list__name {
      margin-top: ac(25px, 20px);
    }

    .details__soc-box {
      margin-top: 23px;
    }

    .team-list__btn {
      margin-top: 25px;
      padding-bottom: 7px;

      &:after {
        transform: translate(-9px, 3px);
      }

      &:hover {
        color: var(--cl-green);
        border-color: var(--cl-green);

        &:after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none'%3E%3Cpath stroke='%23569C3A' d='M.524 13.392h12.868V.524M.524.524l12.952 12.952'/%3E%3C/svg%3E");
        }
      }
    }
  }

  &__text-box {
    width: 100%;
    padding: ac(38px, 20px) ac(30px, 20px) ac(38px, 20px) ac(38px, 20px);
    /* overflow: auto; */

    .details__text {
      padding-right: ac(50px, 40px);
    }
  }

  &__scroll {
    /* max-height: ac(463px, 300px); */
    height: 100%;

    .simplebar-track.simplebar-vertical {
      width: ac(18px, 10px);
      top: 60px !important;
      right: 0px;
      border-radius: 100px;
      border: 1px solid rgba(136, 193, 205, 0.3);
      background: #f6f9f5;
    }

    .simplebar-scrollbar::before {
      top: 2px !important;
      left: 2px !important;
      right: 2px !important;
      bottom: 2px !important;
      background-color: #88c1cd !important;
      opacity: 1;
      border-radius: 100px !important;
    }
  }

  @mixin max-md {
    max-width: 500px;

    &__scroll {
      max-height: unset;
    }

    &__wrapper {
      flex-direction: column;

      .team__card {
        margin: 0 auto;
      }
    }

    &__text-box {
      padding: 15px 5px 15px 15px;
    }

    &__card {
      border-radius: 20px 20px 0 0;
      max-width: unset;
      margin: 0px auto;
      border-right: 0;
      border-bottom: 1px solid rgba(8, 48, 77, 0.2);
      background-color: transparent;
    }
  }
}
