.hero {
  padding-top: 170px;
  padding-bottom: 15px;
  height: 100vh;
  min-height: 700px;

  display: flex;
  transition: height 0.3s ease;

  &--franchising {
    padding-bottom: 137px;

    .hero {
      &__subtitle {
        font-family: var(--font-sec);
        font-size: ac(26px, 20px);
        line-height: ac(40px, 30px);
        font-weight: 400;
      }
    }
  }

  &--industries,
  &--team,
  &--about {
    padding-top: 108px;

    .btn {
      margin-top: ac(48px, 25px);
    }

    .hero {
      &__wrapper {
        align-items: flex-start;
      }

      &__title,
      &__subtitle {
        text-align: left;
      }

      &__bg {
        img {
          object-position: right;
        }
      }

      &__subtitle {
        margin-top: ac(26px, 20px);
        font-family: var(--font-sec);
        font-size: ac(26px, 18px);
        line-height: ac(40px, 24px);
        font-weight: 400;
      }
    }
  }

  &--team {
    padding-bottom: 60px;
  }

  &--industries {
    background-color: #b27335;
    padding-top: 182px;
    overflow: hidden;

    .btn {
      margin-top: ac(32px, 15px);
    }

    .hero {
      &__decor {
        position: absolute;
        bottom: ac(-547px, -250px, 320, 1440);
        right: ac(-287px, -150px, 320, 1440);
        width: ac(942px, 300px, 320, 1440);
        height: ac(949px, 300px, 320, 1440);
        z-index: -1;
      }
      &__bg {
        left: unset;
        right: 0;
        width: ac(574px, 150px, 320, 1440);
        clip-path: url('#hero__mask');
        overflow: hidden;
        z-index: 1;

        img {
          object-position: calc(50% - 80px);
        }
      }

      &__wrapper {
        position: relative;
        z-index: 2;
      }

      &__title-box {
        max-width: ac(710px, 500px);
      }

      &__subtitle {
        margin-top: 11px;
      }
    }
  }

  &--job {
    background-color: #679a48;
    padding-top: 108px;
    height: 80vh;
    min-height: 680px;

    .btn {
      margin-top: ac(32px, 0px);
    }

    .hero {
      &__wrapper {
        padding-top: 20px;
        padding-bottom: 10px;
        flex-direction: row;
        gap: ac(80px, 20px);
      }

      &__decor {
        position: absolute;
        width: ac(686px, 200px, 1024, 1440);
        height: ac(728px, 300px, 1024, 1440);
        left: 50%;
        bottom: ac(-80px, -100px);
        z-index: 1;
        transform: translateX(calc(-50% - ac(-300px, -200px)));
      }

      &__title-box {
        max-width: ac(632px, 300px);
        z-index: 2;
      }

      &__title {
        margin-top: -4px;
        text-align: left;
      }

      &__suptitle {
        text-transform: capitalize;
        display: inline-flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 8px;
        font-size: ac(18px, 16px);
        line-height: ac(34px, 30px);
        font-weight: 700;
        color: var(--cl-white);

        & > span {
          font-size: ac(20px, 18px);
          font-weight: 400;
          font-family: var(--font-sec);
          opacity: 0.5;
        }
      }

      &__col {
        display: flex;
        flex-direction: column;
        gap: ac(28px, 15px);
        padding: ac(48px, 25px) ac(40px, 15px) ac(40px, 25px);
        width: 100%;
        border-radius: 20px;
        background-color: var(--cl-white);
        z-index: 2;
      }

      &__row {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--cl-dark-green);
        gap: 5px;
        padding-bottom: 7px;
      }

      &__key {
        padding-left: 8px;
        display: inline-flex;
        align-items: center;
        color: var(--cl-dark-green);
        font-size: ac(18px, 16px);
        font-weight: 600;
        margin-right: 20px;
        gap: 14px;

        span {
          width: 32px;
          height: 32px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }

        &--salary {
          span {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='mdi-currency-gbp' viewBox='0 0 24 24'%3E%3Cpath fill='#4d8739' d='M14 21C15.93 21 17.62 19.83 18 18L16.25 17.12C16 18.21 15.33 19 14 19H9.1C9.93 18 10.6 16.66 10.6 15C10.6 14.65 10.57 14.31 10.5 14H14V12H9.82C9 10.42 8 9.6 8 8C8 6.07 9.57 4.5 11.5 4.5C13 4.5 14.29 5.45 14.78 6.78L16.63 6C15.83 3.95 13.84 2.5 11.5 2.5C8.46 2.5 6 4.96 6 8C6 9.78 6.79 10.9 7.5 12H6V14H8.47C8.55 14.31 8.6 14.64 8.6 15C8.6 17.7 6 19 6 19V21H14Z' /%3E%3C/svg%3E");
          }
        }
        &--type {
          span {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cg fill='%234D8739' clip-path='url(%23a)'%3E%3Cpath d='M20 6H8v2h12V6ZM20 10H8v2h12v-2ZM14 14H8v2h6v-2ZM12 24H8v2h4v-2ZM29.707 19.293l-3-3a.998.998 0 0 0-1.414 0L16 25.586V30h4.414l9.293-9.293a1 1 0 0 0 0-1.414ZM19.586 28H18v-1.586l5-5L24.586 23l-5 5ZM26 21.586 24.414 20 26 18.414 27.586 20 26 21.586Z'/%3E%3Cpath d='M12 30H6a2.002 2.002 0 0 1-2-2V4a2.002 2.002 0 0 1 2-2h16a2.002 2.002 0 0 1 2 2v10h-2V4H6v24h6v2Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h32v32H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
          }
        }
        &--industry {
          span {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='%234D8739' d='M29.53 6.15a1 1 0 0 0-1 0L20 10.38V7a1 1 0 0 0-1.45-.89L10 10.38V3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v25h28V7a1 1 0 0 0-.47-.85ZM22 26h-4v-7h4v7Zm6 0h-4v-8a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v8H4V4h4v9.62l10-5v5l10-5V26Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h32v32H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
          }
        }
        &--location {
          span {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cg fill='%234D8739' clip-path='url(%23a)'%3E%3Cpath d='M16 18a5 5 0 1 1 0-10 5 5 0 0 1 0 10Zm0-8a3 3 0 1 0 0 5.999A3 3 0 0 0 16 10Z'/%3E%3Cpath d='m16 30-8.436-9.949c-.047-.057-.348-.451-.348-.451A10.889 10.889 0 0 1 5 13a11 11 0 1 1 22 0c.004 2.382-.774 4.7-2.215 6.597l-.001.003s-.3.394-.345.447L16 30ZM8.812 18.395c.002 0 .234.308.287.374L16 26.908l6.91-8.15c.044-.055.278-.365.279-.366A8.9 8.9 0 0 0 25 13a9 9 0 0 0-18 0 8.905 8.905 0 0 0 1.813 5.395Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h32v32H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
          }
        }
      }

      &__value {
        font-size: ac(20px, 18px);
        line-height: ac(32px, 26px);
      }
    }
  }

  &--insights {
    /* min-height: ac(938px, 500px); */
    padding-bottom: 350px;

    .hero__subtitle {
      margin-top: 30px;
    }

    .hero__bg {
      img {
        object-position: top;
      }
    }
  }

  &--details {
    /* min-height: 500px; */
    padding-top: 108px;
    overflow: hidden;

    .hero {
      &__decor {
        bottom: ac(-168px, -70px, 320, 1440);
        right: ac(-288px, -50px, 320, 1440);
        width: ac(678px, 100px, 320, 1440);
        height: ac(679px, 100px, 320, 1440);
        z-index: -1;
      }

      &__wrapper {
        position: relative;
        align-items: flex-start;
      }

      &__title {
        margin-top: 17px;
        text-align: left;
        max-width: 25ch;
      }

      &__info,
      &__suptitle {
        display: inline-flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 8px;
        font-size: ac(18px, 16px);
        line-height: ac(34px, 30px);
        font-weight: 700;
        color: var(--cl-white);

        & > span {
          font-size: ac(20px, 18px);
          font-weight: 400;
          font-family: var(--font-sec);
          opacity: 0.5;
        }
      }

      &__info-box {
        margin-top: 49px;
      }

      &__tag {
        margin-top: 2px;

        padding: 0px 8px;
        background-color: var(--cl-white);
        color: var(--cl-black);
        border-radius: 30px;
        font-family: var(--font-sec);
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;

        &:nth-child(1 of .hero__tag) {
          margin-left: 6px;
        }
      }

      &__sep {
        margin: 0 ac(29px, 15px);
        height: 100%;
        width: 1px;
        background-color: var(--cl-white);
        opacity: 0.5;
      }
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.4;
      background-color: var(--cl-black);
    }
  }

  &__wrapper {
    margin: auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }

  &__title,
  &__subtitle {
    color: var(--cl-white);
    text-align: center;
  }

  &__subtitle {
    margin-top: 20px;
    font-size: ac(32px, 24px);
    line-height: ac(36px, 28px);
  }

  &__search-box {
    margin-top: ac(72px, 54px);

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__form-title {
    font-size: ac(26px, 20px);
    font-weight: 500;
    color: var(--cl-white);
  }

  &__form {
    position: relative;
    margin-top: ac(25px, 22px);
    display: flex;
    align-items: center;
    width: 100%;
    padding: ac(15px, 10px);
    border-radius: 100px;
    background-color: var(--cl-white);

    input {
      padding-left: 67px;
    }

    .default-input {
      width: 100%;
      max-width: ac(324px, 240px);
      border-left: 1px solid var(--cl-black);

      input {
        padding-left: 20px;
        padding-right: ac(70px, 30px);
      }
    }

    .choices {
      width: 100%;
      max-width: ac(324px, 160px);

      &::before {
        right: ac(48px, 5px);
      }

      &__inner {
        padding-right: ac(70px, 30px) !important;
        border-left: 1px solid var(--cl-black) !important;
      }
    }
  }

  &__btn {
    background-color: var(--cl-black);
    color: var(--cl-white);
    border-radius: 35px;
    line-height: 68px;
    padding: 0 ac(51px, 25px);
    font-weight: 700;
    font-size: 18px;
    border: 1px solid var(--cl-black);
    margin-left: ac(38px, 0px);
    transition: 0.3s ease;

    &:hover {
      background-color: var(--cl-white);
      color: var(--cl-black);
    }
  }

  @mixin max-lg {
    padding-top: 90px;
    padding-bottom: 64px;
  }

  @media (max-height: 720px) and (max-width: 450px) {
    padding-bottom: 12vh;
  }

  @mixin md {
    &__form {
      &::after {
        content: '';
        position: absolute;
        left: 34px;
        top: 50%;
        transform: translateY(-50%);
        width: 33px;
        height: 33px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33' height='33'%3E%3Cg data-name='Сгруппировать 68'%3E%3Cg fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' data-name='Icon feather-search'%3E%3Cpath d='M29.482 14.991A14.491 14.491 0 1 1 14.991.5a14.491 14.491 0 0 1 14.491 14.491Z' data-name='Контур 24'/%3E%3Cpath d='m32.5 32.5-6.563-6.563' data-name='Контур 25'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
          center / contain no-repeat;
      }
    }

    &--team,
    &--about {
      .hero__wrapper {
        max-width: 794px;
      }
    }
  }

  @media (max-width: 900px) {
    &__form {
      max-width: 700px;
      flex-direction: column;
      border-radius: 18px;
      padding: 18px 20px 16px;

      &::after {
        display: none;
      }

      .default-input {
        max-width: unset;
        border-left: 0;

        input {
          padding: 0;
        }
      }

      input {
        &,
        &::placeholder {
          font-size: 16px;
          line-height: 50px;
        }
      }

      input,
      .choices__inner {
        padding: 0 !important;
      }

      input,
      .choices {
        margin-right: 0px;
        border-bottom: 1px solid var(--cl-black) !important;
      }

      .choices {
        max-width: unset;
      }

      .choices__inner {
        border-left: 0px solid var(--cl-black) !important;
      }

      .choices__list {
        &--single {
          .choices__item {
            font-size: 16px;
            line-height: 50px;
          }
        }
      }
    }

    &__btn {
      margin: 20px auto 0;
      width: 100%;
      max-width: 295px;
      line-height: 48px;
    }
  }

  @mixin max-md {
    height: auto;
    min-height: 100vh;
    align-items: center;

    &--about,
    &--job,
    &--insights {
      .hero__wrapper {
        justify-content: center;
      }
    }

    &__wrapper {
      justify-content: flex-end;
    }

    &__btn {
      margin: 20px auto 0;
      width: 100%;
      max-width: 295px;
      line-height: 48px;
    }

    &--details {
      .hero__info-box {
        flex-direction: column;
        gap: 10px;
      }

      .hero__sep {
        width: 100%;
        height: 1px;
        margin: 0;
      }
    }

    &--industries {
      .hero__bg {
        width: 100%;
      }

      .hero__title-box {
        max-width: unset;
        justify-content: center;
      }

      .hero__subtitle,
      .hero__title {
        text-align: center;
      }

      .btn {
        margin-top: 0;
      }
    }

    &--job {
      .hero__title-box {
        max-width: unset;
        justify-content: center;
      }

      .hero__title {
        text-align: center;
      }

      .hero__wrapper {
        flex-direction: column;
      }
    }
  }

  @mixin max-sm {
    &--board {
      .hero__bg {
        img {
          object-position: 30%;
        }
      }
    }
    &--job {
      .hero__row {
        flex-direction: column;
        align-items: center;
      }

      .hero__col {
        max-width: 400px;
      }
    }
  }
}
