.who-we {
    margin-top: ac(120px, 60px);

    &--industries {
        margin-top: 0;
        background-color: var(--cl-navy);
        padding: ac(120px, 60px) 0 ac(120px, 60px);
        color: var(--cl-white);
        overflow: hidden;

        .who-we {
            &__suptitle {
                opacity: .5;
                color: var(--cl-white);
            }

            &__title {
                border-color: rgba(255, 255, 255, .5);
            }

            &__decor {
                width: ac(438px, 150px, 320, 1440);
                height: ac(506px, 150px, 320, 1440);
                z-index: 2;
            }
        }
    }

    &--culture {
        margin-top: ac(80px, 20px);
        background-color: var(--cl-navy);
        padding: ac(143px, 60px) 0 ac(100px, 60px);
        color: var(--cl-white);
        overflow: hidden;

        .who-we {
            &__decor {
                &:nth-child(1 of .who-we__decor) {
                    width: ac(336px, 100px, 320, 1440);
                    height: ac(353px, 100px, 320, 1440);
                }

                &:nth-child(2 of .who-we__decor) {
                    width: ac(438px, 150px, 320, 1440);
                    height: ac(506px, 150px, 320, 1440);
                    z-index: 2;
                }
            }

            &__img {
                position: relative;
                overflow: visible;
                z-index: 1;

                img {
                    border-radius: 20px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    bottom: -4%;
                    right: -2%;
                    width: 97%;
                    height: 97%;
                    border-radius: 32px;
                    background-color: var(--cl-orange);
                    z-index: -1;
                }
            }

            &__suptitle {
                &.aos-animate {
                    opacity: .5;
                }
                opacity: 0;
                color: var(--cl-white);
            }

            &__title {
                border-color: rgba(255, 255, 255, .5);
            }

            &__col {
                padding-top: 0;
            }
        }
    }

    &__decor {
        width: ac(516px, 100px, 320, 1440);
        height: ac(597px, 100px, 320, 1440);
    }

    &__img {
        border-radius: 20px;
        overflow: hidden;
    }

    &__col {
        padding-top: ac(13px, 0px);
    }

    &__suptitle {
        font-size: ac(20px, 16px);
        line-height: 1em;
        color: var(--cl-green);
        font-family: var(--font-sec);
    }

    &__title {
        margin-top: ac(22px, 15px);
        padding-bottom: ac(23px, 15px);
        border-bottom: 1px solid var(--cl-light-blue);
    }

    &__subtitle {
        margin-top: ac(21px, 15px);
        font-size: ac(20px, 16px);
        line-height: ac(32px, 20px);
    }

    &__descr {
        margin-top: ac(20px, 10px);

        &>*:not(:last-child) {
            margin-bottom: ac(12px, 5px);
        }
    }

    @mixin md {
        &__img {
            max-width: ac(666px, 300px);
            height: ac(444px, 200px);
            margin-right: ac(48px, 20px);
        }

        &__subtitle, &__descr {
            padding-left: ac(16px, 5px);
        }

        &__decor {
            bottom: ac(-334px, -150px);
            left: ac(-225px, -50px);
        }

        &--industries {
            .who-we__decor {
                bottom: ac(128px, -150px);
                left: ac(-149px, -50px);
            }
        }

        &--culture {
            .who-we__wrapper {
                padding-left: ac(39px, 0px);
            }
    
            .who-we__img {
                margin-right: ac(87px, 20px);
                margin-top: 3px;
                width: ac(588px, 300px);
                height: ac(392px, 200px);
            }

            .who-we__decor {
                &:nth-child(1 of .who-we__decor) {
                    top: ac(-293px, -150px);
                    right: ac(-37px, -50px);
                    left: unset;
                }

                &:nth-child(2 of .who-we__decor) {
                    bottom: ac(108px, -150px);
                    left: ac(-149px, -50px);
                }
            }
        }
    }

    @mixin max-md {
        &__img {
            max-width: ac(566px, 300px, 320, 768);
            height: ac(344px, 180px, 320, 768);
            margin: 0 auto 25px;
        }

        &__decor {
            top: -50px;
            left: ac(-100px, -50px, 320, 768);
        }

        &--culture {
            .who-we__decor {
                &:nth-child(1 of .who-we__decor) {
                    right: ac(-40px, -10px, 320, 768);
                    left: unset;
                }
            }
        }
    }
}