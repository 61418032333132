.get-it {
    background-color: #5B8643;
    padding: ac(128px, 60px) 0 ac(125px, 60px);
    overflow: hidden;
    z-index: 1;

    &__decor {
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% - ac(246px, 50px, 320, 1440)), calc(-50% - ac(489px, 50px, 320, 1440)));
        width: ac(2498px, 1500px, 320, 1440);
        height: ac(2136px, 1500px, 320, 1440);
        z-index: -1;
    }

    &__col {
        color: var(--cl-white);
    }

    &__img {
        border-radius: 20px;
        overflow: hidden;
    }

    &__suptitle {
        font-size: ac(20px, 16px);
        line-height: 1em;
        font-family: var(--font-sec);
        opacity: .5;
    }

    &__title {
        margin-top: ac(14px, 10px);
        padding-bottom: ac(23px, 15px);
        border-bottom: 1px solid rgba(255, 255, 255, .5);
    }

    &__subtitle {
        margin-top: ac(21px, 15px);
        font-size: ac(20px, 16px);
        line-height: ac(32px, 20px);
    }

    &__descr {
        margin-top: ac(20px, 10px);

        &>*:not(:last-child) {
            margin-bottom: ac(12px, 5px);
        }
    }

    @mixin md {
        &__img {
            margin-top: 26px;
            max-width: ac(564px, 300px);
            height: ac(376px, 200px);
        }

        &__col {
            max-width: ac(566px, 400px);
            margin-right: ac(99px, 20px);
        }

        &__subtitle, &__descr {
            padding-left: ac(16px, 5px);
        }
    }

    @mixin max-md {
        &__img {
            max-width: ac(566px, 300px, 320, 768);
            height: ac(344px, 180px, 320, 768);
            margin: 25px auto 0;
        }
    }
}