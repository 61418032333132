.destination {
    margin-top: ac(104px, 60px);

    &--franchising {
        .destination {
            &__decor {
                top: ac(-300px, -100px, 320, 1440);
                width: ac(509px, 200px, 320, 1440);
                height: ac(540px, 150px, 320, 1440);
            }

            &__name, &__descr {
                color: var(--cl-black);
            }

            &__head {
                border-color: rgba(0,0,0, .5);
            }

            &__card {
                min-height: ac(474px, 300px);

                &--yellow {
                    background-color: #D4DF40;
                }

                &--blue {
                    background-color: #88C1CD;
                    &::before {
                        display: none;
                    }
                
                }
            }
        }
    }

    &__wrapper {
        margin-top: ac(48px, 20px);
    }

    &__title {
        position: relative;
        z-index: 1;
    }

    &__decor {
        position: absolute;
        top: ac(-264px, -100px, 320, 1440);
        right: -93px;
        width: ac(459px, 200px, 320, 1440);
        height: ac(394px, 150px, 320, 1440);
    }

    &__card {
        position: relative;
        padding: ac(48px, 20px);
        border-radius: 20px;
        background-color: var(--cl-pink);

        &--green {
            background-color: var(--cl-green);
        }

        &::before {
            content: '';
            position: absolute;
            bottom: ac(75px, 40px);
            left: 50%;
            transform: translateX(-50%);
            width: calc(100% - ac(48px, 20px) * 2);
            height: 1px;
            background-color: var(--cl-black);
        }
    }

    &__head {
        padding-bottom: 23px;
        border-bottom: 1px solid var(--cl-white-o);
    }

    &__name {
        width: calc(100% - ac(178px, 120px));
        font-size: ac(46px, 24px);
        line-height: ac(56px, 34px);
        font-weight: 600;
        color: var(--cl-white);
    }

    &__icon {
        width: ac(158px, 100px);
        height: ac(129px, 80px);

        img {
            object-position: right bottom;
        }
    }

    &__simplebar {
        margin-bottom: 23px;
        margin-top: ac(25px, 15px);
        width: calc(100% + 16px);
        max-height: calc(ac(32px, 25px) * 7);
    }

    &__descr {
        padding-right: 16px;
        font-size: ac(20px, 16px);
        line-height: ac(32px, 20px);
        color: var(--cl-white);

        &>*:not(:last-child) {
            margin-bottom: ac(16px, 10px);
        }
    }

    &__btn {
        z-index: 1;
    }

    .sign-up__form {
        width: 100%;
        padding: 0;
        z-index: 1;

        .sign-up__input {
            line-height: ac(56px, 40px);
        }

        .thanks {
            color: var(--cl-black);
        }
    }

    @mixin max-md {
        &__card {
            margin: 0 auto;
            width: 100%;
            max-width: 500px;
        }

        &__name {
            width: calc(100% - ac(178px, 80px, 320, 768));
        }

        &__icon {
            width: ac(158px, 70px, 320, 768);
            height: ac(129px, 70px, 320, 768);
        }
    }

    @mixin max-sm {
        .sign-up__form {
            border: 0;

            .sign-up__input {
                border: 1px solid var(--cl-black);
            }
        }
    }
}