.news-grid {
    margin-top: ac(80px, 60px);

    &__wrapper {
        background-color: var(--cl-light-green);
        grid-template-columns: 1fr;
        padding: ac(16px, 0px, 320, 1440);
    }

    &__card {
        padding: ac(24px, 15px);
        display: flex;
        flex-direction: column;
        cursor: pointer;
        height: 100%;
        transition: .3s ease;
        background-color: var(--cl-white);

        &:hover {
            img {
                transform: scale(1.1);
            }

            .news-grid__name, .news-grid__date {
                color: var(--cl-dark-green);
            }
        }
    }

    &__img {
        position: relative;
        margin: ac(-24px, -15px) ac(-24px, -15px) 0;
        width: calc(100% + ac(24px, 15px) * 2);
        height: ac(192px, 180px);
        overflow: hidden;

        img {
            transition: .3s ease-in-out;
        }
    }

    &__descr {
        margin-top: ac(25px, 15px);
        font-size: ac(26px, 18px);
        line-height: ac(36px, 24px);
        font-weight: 500;
        margin-bottom: ac(24px, 19px);
        max-height: calc(ac(36px, 24px));
        @mixin max-line-length 1;
        transition: .3s ease;
    }

    &__bottom {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        margin-top: auto;
        gap: 5px;
        border-bottom: 1px solid var(--cl-green);
        padding-bottom: ac(22px, 15px);
    }

    &__name, &__date {
        margin-right: ac(36px, 30px);
        font-size: 16px;
        font-weight: 400;
        transition: .3s ease;
    }

    &__date, &__arrow {
        flex-shrink: 0;
    }

    &__arrow {
        margin-bottom: 5px;
        margin-left: auto;
        width: 14px;
        height: 14px;
        margin-right: ac(0px, 4px);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left bottom;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14'%3E%3Cg fill='none' stroke='%23569C3A'%3E%3Cpath d='M.354 13.222h12.868V.354' data-name='Контур 19'/%3E%3Cpath d='m.354.354 12.952 12.952' data-name='Контур 20'/%3E%3C/g%3E%3C/svg%3E");
        transition: .3s ease;
    }

    @mixin md {
        margin-top: ac(-358px, -300px);

        &__wrapper {
            grid-template-columns: 1.75fr 1fr;
            grid-template-areas:
            'A  B'
            'A  C';
        }

        &__card {
            &:nth-child(1) {
                grid-area: A;
            }
            &:nth-child(2) {
                grid-area: B;
            }
            &:nth-child(3) {
                grid-area: C;
            }
        }
        &__card--xl {
            padding-left: ac(40px, 20px);
            padding-right: ac(40px, 20px);

            .news-grid__img {
                height: 502px;
                margin: ac(-24px, -15px) ac(-40px, -20px) 0;
                width: calc(100% + ac(40px, 20px) * 2);
            }

            .news-grid__descr {
                max-width: 24ch;

                font-size: ac(32px, 22px);
                line-height: ac(40px, 30px);
                font-weight: 700;
                margin-top: ac(23px, 15px);
                margin-bottom: ac(23px, 15px);

                max-height: calc(ac(40px, 30px) * 2);
                @mixin max-line-length 2;
            }
        }
    }

    @mixin max-lg {
        &__name {
            width: 100%;
        }
    }

    @mixin max-md {
        &__wrapper {
            margin: 0 auto;
            max-width: 500px;
        }

        &__name {
            width: auto;
        }

        &__img {
            height: ac(262px, 180px, 320, 768);
        }
    }
    @mixin max-sm {
        &__name {
            width: 100%;
        }
    }
}