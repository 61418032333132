.footer {
	position: relative;
	margin-top: auto;
	background-color: var(--cl-light-green);
	z-index: 1;

	&__decor-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        overflow: hidden;
    }

    &__decor {
        position: absolute;
		bottom: -36px;
		left: ac(170px, 20px, 1100, 1440);
		width: ac(630px, 200px);
		height: ac(590px, 180px);
		transform: translate(-50%, 50%);
		z-index: -1;

        img {
            object-fit: contain;
            object-position: right bottom;
        }
    }

	&__wrapper {
		position: relative;
		padding: ac(70px, 68px) 0 ac(60px, 40px);
		display: flex;
	}

	&__logo {
		position: absolute;
		top: 0;
		left: 0;
		width: ac(162px, 105px);
		height: ac(77px, 50px);
		z-index: 2;
		transform: translateY(-50%);
		transition: .3s ease;

		img {
			position: absolute;
			object-fit: contain;
			transition: 0.3s ease;

			&:nth-child(2) {
				opacity: 0;
			}
		}

		&:hover {
			img {
				&:nth-child(1) {
					opacity: 0;
				}

				&:nth-child(2) {
					opacity: 1;
				}
			}
		}
	}

	&__col {
		max-width: 200px;
		width: 100%;
		display: flex;
		flex-shrink: 0;
		flex-direction: column;
		align-items: flex-start;
		margin-left: ac(322px, 80px);

		&--nav {
			margin-left: ac(156px, 35px);
			padding-right: 20px;
		}

		&--copy {
			margin-left: ac(94px, 0px);

			.footer__link {
				&:not(:last-child) {
					margin-bottom: ac(0px, 10px);
				}
			}
		}
	}

	&__link {
		font-size: 16px;
		line-height: 24px;
		transition: .3s ease;

		&:not(:last-child) {
			margin-bottom: ac(6px, 10px);
		}

		&:hover {
			color: var(--cl-green);
		}
	}

	&__tel {
		color: var(--cl-navy);
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		transition: .3s ease;

		&:not(:last-child) {
			margin-bottom: 5px;
			margin-top: 10px;
		}

		&:hover {
			color: var(--cl-green);
		}
	}

	&__copy {
		margin-top: auto;
		font-family: var(--font-sec);
		font-weight: 300;
		line-height: 24px;
		font-size: 14px;
	}

	@mixin md {
		&__col:not(.footer__col--nav, .footer__col--copy) {
			max-width: 240px;
			margin-left: ac(322px, 0px);
		}

		&__col--nav {
			margin-left: ac(116px, 35px);
		}
	}

	@mixin max-md {
		&__decor {
			left: unset;
			width: 300px;
			height: 280px;
			bottom: 78px;
			right: -168px;
			transform: rotate(-30deg);
		}

		&__wrapper {
			flex-wrap: wrap;
			justify-content: space-between;
			padding-bottom: 171px;
			max-width: 500px;
			margin: 0 auto;
		}

		&__col {
			max-width: calc(62% - 5px);
			margin-left: 0;
			padding-right: 0;
			order: 1;
		}

		&__col--nav {
			order: 3;
			margin-top: 20px;
		}

		&__col--copy {
			max-width: calc(40% - 5px);
			order: 2;
			align-items: flex-end;

			.footer__link {
				text-align: right;
			}
		}

		&__copy {
			position: absolute;
			bottom: 40px;
			left: 0;
		}

		&__tel {
			position: absolute;
			bottom: 90px;

			&:nth-child(2) {
				bottom: 115px;
			}
		}
	}

	@mixin max-xs {
		&__link {
			font-size: 15px;
		}
	}
}
