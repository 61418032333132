.jobs {
    margin-top: ac(80px, 60px, 375, 1440);
    padding: ac(80px, 60px) 0 ac(189px, 120px, 375, 1440);
    z-index: 1;
    background-color: #679A48;

    &--job {
        margin-top: ac(118px, 60px, 375, 1440);

        .jobs__img {
            top: 44px;
            width: ac(258px, 140px, 320, 1440);
            height: ac(225px, 140px, 320, 1440);
            left: ac(-105px, -40px, 320, 1440);
        }

        .jobs__decor {
            width: ac(413px, 200px, 900, 1440);
            height: ac(360px, 201px, 900, 1440);
            bottom: 20px;
            right: ac(-180px, -120px, 900, 1440);
        }
    }

    &--main {
        margin-top: 0;
        padding: ac(88px, 60px) 0 ac(120px, 60px);
        background-color: var(--cl-white);
        
        .slider-nav {
            left: 50%;
            bottom: 0px !important;
            transform: translate(calc(-50% + ac(275px, 160px, 1024, 1440)), 50%);
        }
        
        .jobs {
            &__decor-box {
                top: ac(-225px, -100px);
                height: calc(100% + ac(368px, 200px));
            }
        
            &__decor {
                width: ac(223px, 140px);
                height: ac(225px, 141px);
                right: ac(-100px, -120px);
            }
        
            &__img {
                width: ac(346px, 140px);
                height: ac(296px, 140px);
                left: ac(-160px, -80px, 320, 1440);
            }
            
            &__wrapper {
                flex-direction: row;
                max-width: unset;
                align-items: flex-start;
            }

            &__slider-box {
                margin-top: 0;
                height: ac(480px, 450px, 1024, 1440);
            }

            &__title-box {
                margin-top: ac(72px, 30px, 1024, 1440);
                max-width: ac(502px, 300px, 1024, 1440);
                width: 100%;
                flex-shrink: 0;
                margin-right: ac(48px, 20px);
            }

            &__name {
                max-width: ac(495px, 410px, 1024, 1440);
            }

            &__card {
                border-color: var(--cl-dark-green);
                padding-bottom: ac(20px, 18px);
            }

            &__info, &__title {
                color: var(--cl-black);
            }

            &__info {
                &:not(:last-child) {
                    margin-bottom: ac(10px, 0px);
                }
            }

            &__title {
                text-align: left;
                margin-bottom: ac(32px, 15px);
            }

            &__subtitle {
                margin-bottom: ac(20px, 10px);
                font-weight: 500;
                font-size: ac(26px, 20px);
                line-height: ac(36px, 28px);
            }

            &__descr {
                margin-left: 16px;
            }

            &__name {
                margin-right: 18px;
                color: var(--cl-dark-green);   
                padding-bottom: 0;
            }

            &__arrow {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14'%3E%3Cg fill='none' stroke='%23000'%3E%3Cpath d='M.354 13.222h12.868V.354' data-name='Контур 19'/%3E%3Cpath d='m.354.354 12.952 12.952' data-name='Контур 20'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }

    &__decor-box {
        position: absolute;
        top: ac(-150px, -100px);
        left: 0;
        width: 100%;
        height: calc(100% + ac(300px, 200px));
        overflow: hidden;
        pointer-events: none;
    }

    &__decor {
        position: absolute;
        width: ac(388px, 200px, 900, 1440);
        height: ac(392px, 201px, 900, 1440);
        bottom: 50px;
        right: ac(-180px, -120px, 900, 1440);

        img {
            object-fit: contain;
        }
    }

    &__img {
        position: absolute;
        width: ac(454px, 140px, 320, 1440);
        height: ac(388px, 140px, 320, 1440);
        top: 50px;
        left: ac(-230px, -80px, 320, 1440);

        img {
            object-fit: contain;
        }
    }

    .slider-nav {
        bottom: ac(80px, 40px);

        &__btn {
            &:hover {
                background-color: var(--cl-yellow-sec);
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 956px;
        width: 100%;
        margin: 0 auto;
    }

    &__title {
        text-align: center;
        color: var(--cl-white);
    }

    &__slider-box {
        margin-top: ac(39px, 25px);
        width: 100%;
        height: ac(588px, 445px);
    }

    &__swiper,
    &__slider {
        height: 100% !important;
        overflow: hidden !important;
    }

    &__card {
        position: relative;
        display: flex;
        align-items: flex-end;
        border-bottom: 1px solid var(--cl-white);
        padding-bottom: ac(22px, 18px);
        transition: .3s ease;
        cursor: pointer;
       
        &:hover {
            border-color: var(--cl-black);
            
            .jobs__name, .jobs__info {
                color: var(--cl-black);
            }

            .jobs__arrow {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14'%3E%3Cg fill='none' stroke='%23000'%3E%3Cpath d='M.354 13.222h12.868V.354' data-name='Контур 19'/%3E%3Cpath d='m.354.354 12.952 12.952' data-name='Контур 20'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }

    &__name {
        flex-shrink: 0;
        width: 100%;
        padding-bottom: 5px;
        max-width: ac(510px, 360px);
        margin-right: ac(168px, 25px);
        margin-bottom: auto;
        color: var(--cl-white);
        font-weight: 500;
        font-size: ac(24px, 17px);
        line-height: ac(34px, 22px);
        transition: .3s ease;
    }

    &__info-box {
        display: flex;
        flex-direction: column;
        margin-right: auto;
        padding-right: 15px;
    }

    &__info {
        font-size: 16px;
        line-height: 24px;
        transition: .3s ease;
        color: var(--cl-white);

        &:not(:last-child) {
            margin-bottom: ac(5px, 0px);
        }
    }

    &__arrow {
        margin-bottom: ac(1px, 5px);
        margin-right: ac(0px, 4px);
        flex-shrink: 0;
        width: 14px;
        height: 14px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left bottom;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14'%3E%3Cg fill='none' stroke='%23fff'%3E%3Cpath d='M.354 13.222h12.868V.354' data-name='Контур 19'/%3E%3Cpath d='m.354.354 12.952 12.952' data-name='Контур 20'/%3E%3C/g%3E%3C/svg%3E");
        transition: .3s ease;
    }

    @mixin max-lg {
        &--main {
            .slider-nav {
                transform: translate(-50%, 50%);
            }

            .jobs__slider-box {
                height: 270px;
            }

            .jobs__title-box {
                max-width: unset;
                margin-right: 0;
                margin-bottom: 25px;
            }

            .jobs__wrapper {
                flex-direction: column;
            }

            .jobs__name {
                max-width: ac(580px, 350px, 768, 1024);
            }

            .jobs__descr {
                margin-left: 5px;

                br {
                    display: none;
                }
            }
        }
    }


    @mixin max-md {
        br {
            display: none;
        }

        &__card {
            flex-wrap: wrap;
        }
        &__name {
            max-width: 100%;
            margin-right: 0;
            margin-bottom: 10px;
        }

        &__slider-box {
            height: 564px;
        }

        &--main {
            .jobs__slider-box {
                height: ac(420px, 480px, 375, 768);
            }

            .jobs__info-box {
                margin-left: 0;
                padding-right: 0;
                width: calc(100% - 40px);
            }

            .jobs__name {
                max-width: unset;
            }
        }
    }

    @mixin max-xs {
        &__name {
            font-size: 16px;
        }

        &--main {
            .jobs__name, .jobs__info {
                font-size: 14px;
            }
        }
    }
}