.btn {
    display: inline-flex;
    align-items: center;
    padding: 0 ac(56px, 40px);
    line-height: ac(56px, 40px);
    font-size: ac(18px, 16px);
    font-weight: 600;
    background-color: var(--cl-white);
    box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.20);
    border-radius: 100px;
    transition: .3s ease;
    text-transform: capitalize;
    
    span {
        margin-right: 8px;
        width: 27px;
        height: 20px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='20' fill='none'%3E%3Cpath stroke='%23000' stroke-width='2' d='M11.217 19.099 2.118 10l9.1-9.099M27 10H2'/%3E%3C/svg%3E");
        transition: .3s ease;
    }

    &:hover {
        background-color: var(--cl-black);
        color: var(--cl-white);

        span {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='20' fill='none'%3E%3Cpath stroke='%23fff' stroke-width='2' d='M11.217 19.099 2.118 10l9.1-9.099M27 10H2'/%3E%3C/svg%3E");
        }
    }

    &--black {
        background-color: var(--cl-black);
        color: var(--cl-white);

        &:hover {
            background-color: var(--cl-white);
            color: var(--cl-black);
        }
    }
}

.swiper-scrollbar-drag {
    cursor: pointer;
}

.scroll-btn {
    position: fixed;
    bottom: ac(50px, 85px, 1366);
    right: ac(80px, 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border: 1px solid rgba(255, 255, 255, .3);
    border-radius: 100%;
    cursor: pointer;
    transition: .3s ease;
    z-index: 10;

    &__icon {
        pointer-events: none;
        width: 19px;
        height: 19px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='19'%3E%3Cg fill='none' stroke='%23fff'%3E%3Cpath d='m.559 9.6 9.099 9.098 9.1-9.099' data-name='Контур 19'/%3E%3Cpath d='M9.658.5v18.317' data-name='Контур 20'/%3E%3C/g%3E%3C/svg%3E");
        transition: .3s ease;
    }

    &:hover {
        background-color: var(--cl-white);

        .scroll-btn__icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='19'%3E%3Cg fill='none' stroke='%23000'%3E%3Cpath d='m.559 9.6 9.099 9.098 9.1-9.099' data-name='Контур 19'/%3E%3Cpath d='M9.658.5v18.317' data-name='Контур 20'/%3E%3C/g%3E%3C/svg%3E");
            transition: .3s ease;
        }
    }

    &.is-scrolled {
        /* right: 20px; */
        /* bottom: ac(108px, 40px); */
        background-color: var(--cl-white);
        box-shadow: 5px 10px 10px var(--cl-black-o);

        .scroll-btn__icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='19'%3E%3Cg fill='none' stroke='%2364844b'%3E%3Cpath d='M18.757 9.806 9.658.706l-9.1 9.1' data-name='Контур 19'/%3E%3Cpath d='M9.658 18.905V.588' data-name='Контур 20'/%3E%3C/g%3E%3C/svg%3E");
        }

        &:hover {
            border-color: var(--cl-green);
            background-color: var(--cl-green);
    
            .scroll-btn__icon {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='19'%3E%3Cg fill='none' stroke='%23fff'%3E%3Cpath d='M18.757 9.806 9.658.706l-9.1 9.1' data-name='Контур 19'/%3E%3Cpath d='M9.658 18.905V.588' data-name='Контур 20'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }

    @mixin max-md {
        visibility: hidden;
        opacity: 0;

        &.is-scrolled {
            visibility: visible;
            opacity: 1;
        }
    }
}

.slider-nav {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    user-select: none;
    z-index: 10;
    gap: 10px;

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border-radius: 100%;
        background-color: #F6F9F5;
        transition: .3s ease;
        cursor: pointer;
        filter: drop-shadow(5px 10px 10px rgba(0, 0, 0, 0.20));

        &.swiper-button-lock {
            display: none !important;
        }

        &.swiper-button-disabled {
            pointer-events: none;
            opacity: .5;
        }

        &:hover {
            background-color: var(--cl-green);

            .slider-nav__icon {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='19'%3E%3Cg fill='none' stroke='%23fff'%3E%3Cpath d='M18.757 9.806 9.658.706l-9.1 9.1' data-name='Контур 19'/%3E%3Cpath d='M9.658 18.905V.588' data-name='Контур 20'/%3E%3C/g%3E%3C/svg%3E");

                &--next {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.317' height='19.405'%3E%3Cg fill='none' stroke='%23fff'%3E%3Cpath d='m.559 9.6 9.099 9.098 9.1-9.099' data-name='Контур 19'/%3E%3Cpath d='M9.658.5v18.317' data-name='Контур 20'/%3E%3C/g%3E%3C/svg%3E");
                }
            }
        }

        @mixin max-xs {
            width: 40px;
            height: 40px;
        }
    }

    &__icon {
        width: 19px;
        height: 19px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='19'%3E%3Cg fill='none' stroke='%2364844b'%3E%3Cpath d='M18.757 9.806 9.658.706l-9.1 9.1' data-name='Контур 19'/%3E%3Cpath d='M9.658 18.905V.588' data-name='Контур 20'/%3E%3C/g%3E%3C/svg%3E");
        transition: .3s ease;

        &--next {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.317' height='19.405'%3E%3Cg fill='none' stroke='%2364844b'%3E%3Cpath d='m.559 9.6 9.099 9.098 9.1-9.099' data-name='Контур 19'/%3E%3Cpath d='M9.658.5v18.317' data-name='Контур 20'/%3E%3C/g%3E%3C/svg%3E");
        }
    }
     
    &__btn--prev, &__icon--next {
        transform: rotate(-90deg);
    }

    &__btn--prev{
        filter: drop-shadow(-10px -5px 10px rgba(0, 0, 0, 0.20));
    }

    &--alt {
        .slider-nav__btn--prev, .slider-nav__icon--next {
            transform: rotate(0deg);

        }
        .slider-nav__btn--prev {
            filter: drop-shadow(5px 10px 10px rgba(0, 0, 0, 0.20));
        }
    }

    &--orange {
        .slider-nav {
            &__icon {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.317' height='19.405'%3E%3Cg fill='none' stroke='%23b27335'%3E%3Cpath d='M18.757 9.806 9.658.706l-9.1 9.1' data-name='Контур 19'/%3E%3Cpath d='M9.658 18.905V.588' data-name='Контур 20'/%3E%3C/g%3E%3C/svg%3E");
        
                &--next {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.317' height='19.405'%3E%3Cg fill='none' stroke='%23b27335'%3E%3Cpath d='m.559 9.6 9.099 9.098 9.1-9.099' data-name='Контур 19'/%3E%3Cpath d='M9.658.5v18.317' data-name='Контур 20'/%3E%3C/g%3E%3C/svg%3E");
                }
            }
        }
    }
}

.pagination {
    margin: ac(80px, 30px) auto 0;
	display: flex;
	align-items: center;

    &__btn, &__num {
        display: flex;
		align-items: center;
		justify-content: center;
		width: ac(60px, 36px);
		height: ac(60px, 36px);
		background-color: #F6F9F5;
        cursor: pointer;
		border: 1px solid rgba(0, 0, 0, 0.05);
		transition: .3s ease;

        &.is-disabled {
			opacity: .5;
			pointer-events: none;			
		}
    }

	&__num {
		margin-right: ac(10px, 5px);
		font-weight: 600;
		font-size: ac(18px, 16px);
		line-height: 22px;
		color: var(--cl-black);
		cursor: pointer;
		user-select: none;
        border-radius: 5px;
		transition: .3s ease;

		&:last-of-type {
			margin-right: 0;
		}

        &.is-active, &--dots {
			pointer-events: none;
		}

        &--dots {
            border: 0;
            background-color: var(--cl-white);
        }

		&.is-active, &:hover {
            background-color: var(--cl-white);
            color: var(--cl-dark-green);
            border-color: var(--cl-dark-green);
		}
	}

	&__btn {
		border-radius: 100%;
	
		&--prev {
			margin-right: ac(33px, 5px);

			.pagination__icon {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath stroke='%23000' d='M10.06 19.1.96 10l9.1-9.099M19.159 10H.842'/%3E%3C/svg%3E");
			}
		}

		&--next {
			margin-left: ac(33px, 5px);

			.pagination__icon {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath stroke='%23000' d='m9.94 19.1 9.1-9.1L9.94.901M.843 10h18.316'/%3E%3C/svg%3E");
			}
		}

		&:hover {
            border-color: var(--cl-dark-green);
            background-color: var(--cl-white);

            .pagination__btn--prev {
                .pagination__icon {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath stroke='%234D8739' d='M10.06 19.1.96 10l9.1-9.099M19.159 10H.842'/%3E%3C/svg%3E");
                }
            }
    
            .pagination__btn--next {
                .pagination__icon {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath stroke='%234D8739' d='m9.94 19.1 9.1-9.1L9.94.901M.843 10h18.316'/%3E%3C/svg%3E");
                }
            }
		}
	}

	&__icon {
		width: 20px;
		height: 20px;
		background-position: center;
		background-repeat: repeat;
		background-size: contain;
		transition: .3s ease;
	}
}
