.cards {
    margin-top: -157px;

    &__wrapper {
        display: flex;
        gap: ac(16px, 5px);
    }

    &__card {
        position: relative;
        width: 100%;
        padding: ac(40px, 20px) ac(32px, 15px);
        background-color: var(--cl-green);
        border-radius: 15px;
        overflow: hidden;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            right: -10px;
            bottom: -65px;
            width: 152px;
            height: 176px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='152' height='176' fill='none'%3E%3Cpath fill='%234D8739' d='M113.721 10.522a14.128 14.128 0 0 0-1.629 8.767 14.104 14.104 0 0 0 3.948 7.99c-2.326 8.857-6.819 16.072-10.782 21.116A31.294 31.294 0 0 0 84.11 51.17c-11.941-9.517-15.58-17.503-16.595-22.325l.002-.004a19.468 19.468 0 0 0 1.621-12.966A19.44 19.44 0 0 0 49.82.64a19.477 19.477 0 0 0-18.813 15.889A19.455 19.455 0 0 0 33.05 29.43a19.438 19.438 0 0 0 9.742 8.697 19.46 19.46 0 0 0 13.052.573l.004.001c7.557 5.22 14.246 11.973 19.864 18.783a31.467 31.467 0 0 0-8.556 22.732c-11.001 2.899-23.988 1.409-30.848.243a19.445 19.445 0 0 0-10.043-8.331 19.468 19.468 0 0 0-13.053-.11A19.49 19.49 0 0 0 .265 92.945a19.452 19.452 0 0 0 30.457 13.418 19.49 19.49 0 0 0 7.573-10.639 70.837 70.837 0 0 1 30.413-6.895 31.263 31.263 0 0 0 12.206 16.173c-1.786 16.946-6.239 26.396-10.325 31.632a19.488 19.488 0 0 0-18.998 16.746 19.45 19.45 0 0 0 13.588 21.348 19.48 19.48 0 0 0 13.471-.784 19.495 19.495 0 0 0 9.734-9.352 19.477 19.477 0 0 0 1.323-13.43 19.451 19.451 0 0 0-7.725-11.057c2.482-10.961 7.138-21.496 11.75-30.091a31.315 31.315 0 0 0 20.594-3.952c6.192 5.762 9.436 10.47 11.04 14.155a14.13 14.13 0 0 0 .001 16.342 14.108 14.108 0 0 0 15.423 5.39 14.14 14.14 0 0 0 10.199-12.777 14.114 14.114 0 0 0-8.678-13.841 14.07 14.07 0 0 0-9.956-.323c-4.803-4.229-8.546-9.131-11.464-14.063a31.259 31.259 0 0 0 6.551-9.914c5.733-13.792.891-29.335-10.811-37.604 1.593-11.543 4.204-18.283 6.812-22.212a14.124 14.124 0 0 0 13.796-5.228 14.14 14.14 0 0 0 2.878-7.103 14.107 14.107 0 0 0-13.572-15.657 14.13 14.13 0 0 0-12.824 7.297'/%3E%3C/svg%3E");
            z-index: -1;
        }

        &--orange {
            background-color: #C87E27;

            &::before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='152' height='176' fill='none'%3E%3Cpath fill='%23BB7023' d='M113.721 10.522a14.128 14.128 0 0 0-1.629 8.767 14.104 14.104 0 0 0 3.948 7.99c-2.326 8.857-6.819 16.072-10.782 21.116A31.294 31.294 0 0 0 84.11 51.17c-11.941-9.517-15.58-17.503-16.595-22.325l.002-.004a19.468 19.468 0 0 0 1.621-12.966A19.44 19.44 0 0 0 49.82.64a19.477 19.477 0 0 0-18.813 15.889A19.455 19.455 0 0 0 33.05 29.43a19.438 19.438 0 0 0 9.742 8.697 19.46 19.46 0 0 0 13.052.573l.004.001c7.557 5.22 14.246 11.973 19.864 18.783a31.467 31.467 0 0 0-8.556 22.732c-11.001 2.899-23.988 1.409-30.848.243a19.445 19.445 0 0 0-10.043-8.331 19.468 19.468 0 0 0-13.053-.11A19.49 19.49 0 0 0 .265 92.945a19.452 19.452 0 0 0 30.457 13.418 19.49 19.49 0 0 0 7.573-10.639 70.837 70.837 0 0 1 30.413-6.895 31.263 31.263 0 0 0 12.206 16.173c-1.786 16.946-6.239 26.396-10.325 31.632a19.488 19.488 0 0 0-18.998 16.746 19.45 19.45 0 0 0 13.588 21.348 19.48 19.48 0 0 0 13.471-.784 19.495 19.495 0 0 0 9.734-9.352 19.477 19.477 0 0 0 1.323-13.43 19.451 19.451 0 0 0-7.725-11.057c2.482-10.961 7.138-21.496 11.75-30.091a31.315 31.315 0 0 0 20.594-3.952c6.192 5.762 9.436 10.47 11.04 14.155a14.13 14.13 0 0 0 .001 16.342 14.108 14.108 0 0 0 15.423 5.39 14.14 14.14 0 0 0 10.199-12.777 14.114 14.114 0 0 0-8.678-13.841 14.07 14.07 0 0 0-9.956-.323c-4.803-4.229-8.546-9.131-11.464-14.063a31.259 31.259 0 0 0 6.551-9.914c5.733-13.792.891-29.335-10.811-37.604 1.593-11.543 4.204-18.283 6.812-22.212a14.124 14.124 0 0 0 13.796-5.228 14.14 14.14 0 0 0 2.878-7.103 14.107 14.107 0 0 0-13.572-15.657 14.13 14.13 0 0 0-12.824 7.297'/%3E%3C/svg%3E");
            }
        }

        &--purple {
            background-color: #824499;

            &::before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='152' height='176' fill='none'%3E%3Cpath fill='%23743D8A' d='M113.721 10.522a14.128 14.128 0 0 0-1.629 8.767 14.104 14.104 0 0 0 3.948 7.99c-2.326 8.857-6.819 16.072-10.782 21.116A31.294 31.294 0 0 0 84.11 51.17c-11.941-9.517-15.58-17.503-16.595-22.325l.002-.004a19.468 19.468 0 0 0 1.621-12.966A19.44 19.44 0 0 0 49.82.64a19.477 19.477 0 0 0-18.813 15.889A19.455 19.455 0 0 0 33.05 29.43a19.438 19.438 0 0 0 9.742 8.697 19.46 19.46 0 0 0 13.052.573l.004.001c7.557 5.22 14.246 11.973 19.864 18.783a31.467 31.467 0 0 0-8.556 22.732c-11.001 2.899-23.988 1.409-30.848.243a19.445 19.445 0 0 0-10.043-8.331 19.468 19.468 0 0 0-13.053-.11A19.49 19.49 0 0 0 .265 92.945a19.452 19.452 0 0 0 30.457 13.418 19.49 19.49 0 0 0 7.573-10.639 70.837 70.837 0 0 1 30.413-6.895 31.263 31.263 0 0 0 12.206 16.173c-1.786 16.946-6.239 26.396-10.325 31.632a19.488 19.488 0 0 0-18.998 16.746 19.45 19.45 0 0 0 13.588 21.348 19.48 19.48 0 0 0 13.471-.784 19.495 19.495 0 0 0 9.734-9.352 19.477 19.477 0 0 0 1.323-13.43 19.451 19.451 0 0 0-7.725-11.057c2.482-10.961 7.138-21.496 11.75-30.091a31.315 31.315 0 0 0 20.594-3.952c6.192 5.762 9.436 10.47 11.04 14.155a14.13 14.13 0 0 0 .001 16.342 14.108 14.108 0 0 0 15.423 5.39 14.14 14.14 0 0 0 10.199-12.777 14.114 14.114 0 0 0-8.678-13.841 14.07 14.07 0 0 0-9.956-.323c-4.803-4.229-8.546-9.131-11.464-14.063a31.259 31.259 0 0 0 6.551-9.914c5.733-13.792.891-29.335-10.811-37.604 1.593-11.543 4.204-18.283 6.812-22.212a14.124 14.124 0 0 0 13.796-5.228 14.14 14.14 0 0 0 2.878-7.103 14.107 14.107 0 0 0-13.572-15.657 14.13 14.13 0 0 0-12.824 7.297'/%3E%3C/svg%3E");
            }
        }
        
        &:hover {
            .cards__btn {
                color: var(--cl-black);
                border-color: var(--cl-black);

                span {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='14' fill='none'%3E%3Cpath stroke='%23000' d='M1.024 13.392h12.868V.524M1.024.524l12.952 12.952'/%3E%3C/svg%3E");
                }
            }
        }
        
    }

    &__name, &__descr, &__btn {
        color: var(--cl-white);
    }

    &__name {
        margin-bottom: 16px;
        font-size: ac(32px, 24px);
        line-height: ac(42px, 34px);
        font-weight: 700;
    }

    &__descr {
        margin-bottom: ac(29px, 15px);
        font-size: ac(20px, 18px);
        line-height: ac(30px, 28px);
        font-weight: 400;
    }

    &__btn {
        padding-bottom: 5px;
        display: inline-flex;
        align-items: baseline;
        margin-top: auto;
        border-bottom: 1px solid var(--cl-white);
        transition: .3s ease;

        span {
            margin-left: 15px;
            width: 15px;
            height: 14px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='14' fill='none'%3E%3Cpath stroke='%23fff' d='M1.024 13.392h12.868V.524M1.024.524l12.952 12.952'/%3E%3C/svg%3E");
            transition: .3s ease;
        }
    }

    @mixin max-md {
        margin-top: 60px;

        &__wrapper {
            gap: 15px;
            flex-direction: column;
        }
    }
}