.popup-studies {
    position: relative;
    max-width: 1080px;
    max-height: 90dvh;
    padding: ac(24px, 5px);
    border-radius: ac(40px, 20px);
    border: 1px solid rgba(8, 48, 77, 0.20);
    background: #F6F9F5;

    .simplebar-track.simplebar-vertical {
        width: ac(24px, 5px);
        top: 20px !important;
        bottom: 20px !important;
        right: 0px;
        background:  #F6F9F5;
    }

    .simplebar-scrollbar::before {
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        background-color: #88C1CD !important;
    }

    &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: ac(33px, 15px);
        right: ac(18px, 15px);
        width: 44px;
        height: 44px;
        border: 1px solid #C0C0C0;
        border-radius: 100%;
        transition: .3s ease;
        cursor: pointer;
        z-index: 5;

        &::after {
            content: '';
            width: 12px;
            height: 12px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none'%3E%3Cpath fill='%23000' d='m7.06 6 4.41-4.41A.75.75 0 0 0 10.41.53L6 4.94 1.59.53A.75.75 0 1 0 .53 1.59L4.94 6 .53 10.41a.75.75 0 0 0 1.06 1.06L6 7.06l4.41 4.41a.75.75 0 1 0 1.06-1.06L7.06 6Z'/%3E%3C/svg%3E");
            cursor: pointer;
            transition: .3s ease;
        }

        &:hover {
            background-color: var(--cl-green);

            &::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none'%3E%3Cpath fill='%23fff' d='m7.06 6 4.41-4.41A.75.75 0 0 0 10.41.53L6 4.94 1.59.53A.75.75 0 1 0 .53 1.59L4.94 6 .53 10.41a.75.75 0 0 0 1.06 1.06L6 7.06l4.41 4.41a.75.75 0 1 0 1.06-1.06L7.06 6Z'/%3E%3C/svg%3E");
            }
        }
    }

    &__wrapper {
        border-radius: 20px;
        border: 1px solid rgba(8, 48, 77, 0.20);
        background-color: var(--cl-white);
        overflow: hidden;
        z-index: 1;
    }

    &__decor {
        width: ac(248px, 100px, 320);
        height: ac(237px, 100px, 320);
        left: ac(-128px, -50px, 320);
        top: ac(-109px, -50px, 320);
        z-index: -1;

        &--sec {
            top: unset;
            left: unset;
            bottom: ac(-106px, -50px, 320);
            right: ac(-100px, -50px, 320);
            width: ac(234px, 100px, 320);
            height: ac(246px, 100px, 320);
        }
    }

    &__head {
        background-color: #F6F9F5;
        border-bottom: 1px solid rgba(8, 48, 77, 0.2);
    }

    &__logo {
        height: 100%;
        max-width: 238px;
        max-height: 100px;
        padding: 6px ac(38px, 20px);
        border-right: 1px solid rgba(8, 48, 77, 0.2);
    }

    &__title-box {
        padding: 21px 60px 21px 24px;
        gap: 7px;
    }

    &__suptitle {
        color: #4D8739;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }

    &__title {
        font-size: ac(26px, 20px);
        line-height: ac(36px, 26px);
        font-weight: 500;
    }

    &__text-box {
        width: 100%;
        padding: ac(33px, 20px) ac(30px, 20px) 4px ac(38px, 20px);

        .details__text {
            padding-right: ac(50px, 40px);

            h6 {
                &:not(:first-child) {
                    margin-top: ac(24px, 20px);
                }
            }

            p, li {
                font-size: 16px;
                line-height: 26px;
            }

            &>* {
                &:last-child {
                    margin-bottom: ac(20px, 10px);
                }
            }
        }
    }

    &__scroll {
        position: relative;
        max-height: calc(71dvh - 255px);

        &.is-bottom {
            &::before {
                opacity: 0;
            }
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: -38px;
            width: 100%;
            height: 118px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.80) 27.72%, #FFF 83.97%);
            pointer-events: none;
            transition: .3s ease;
            z-index: 1;
        }

        .simplebar-track.simplebar-vertical {
            width: ac(18px, 10px);
            top: 0px !important;
            right: 0px;
            border-radius: 100px;
            border: 1px solid rgba(136, 193, 205, 0.30);
            background:  #F6F9F5;
        }

        .simplebar-scrollbar::before {
            top: 2px !important;
            left: 2px !important;
            right: 2px !important;
            bottom: 2px !important;
            background-color: #88C1CD !important;
            opacity: 1;
            border-radius: 100px !important;
        }
    }

    &__bottom {
        gap: 16px;
        border-top: 1px solid rgba(8, 48, 77, 0.20);
        background: #F6F9F5;
        padding: 20px ac(40px, 20px) 20px ac(45px, 20px);
        z-index: 1;
    }

    &__soc-box {
        gap: ac(24px, 10px);
        margin-right: auto;
    }

    .details__soc-box {
        gap: 12px;
    }

    .details__share {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        color: var(--cl-green);
        text-transform: capitalize;

        span {
            width: 32px;
            height: 32px;
            margin-right: 12px;

            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='%23569C3A' d='M23 20a5 5 0 0 0-3.89 1.89l-7.31-4.57a4.46 4.46 0 0 0 0-2.64l7.31-4.57A5 5 0 1 0 18 7c.004.447.072.892.2 1.32l-7.31 4.57a5 5 0 1 0 0 6.22l7.31 4.57A4.788 4.788 0 0 0 18 25a5 5 0 1 0 5-5Zm0-16a3 3 0 1 1 0 6 3 3 0 0 1 0-6ZM7 19a3 3 0 1 1 0-5.999 3 3 0 0 1 0 6Zm16 9a3 3 0 1 1 0-5.999 3 3 0 0 1 0 6Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h32v32H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        }
    }

    .details__soc {
        background-color: var(--cl-green);
        border: 1px solid rgba(8, 48, 77, 0.20);
    }

    &__btn {
        gap: 8px;
        padding: 0px ac(28px, 10px, 375);
        line-height: ac(52px, 36px);
        border: 2px solid var(--cl-black);

        &::before, &::after {
            width: 27px;
            height: 20px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            transition: .3s ease;
        }
        &--prev {
            &::before {
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='20' fill='none'%3E%3Cpath fill='%23000' fill-rule='evenodd' d='M10.214.194.408 10l9.806 9.806 1.415-1.414L4.237 11h22.467V9H4.237l7.392-7.392L10.214.194Z' clip-rule='evenodd'/%3E%3C/svg%3E");
            }

            &:hover {
                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='20' fill='none'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M10.214.194.408 10l9.806 9.806 1.415-1.414L4.237 11h22.467V9H4.237l7.392-7.392L10.214.194Z' clip-rule='evenodd'/%3E%3C/svg%3E");
                }
            }
        }
        &--next {
            &::after {
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='20' fill='none'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M17.194.194 27 10l-9.806 9.806-1.414-1.414L23.172 11H.704V9h22.468L15.78 1.608 17.194.194Z' clip-rule='evenodd'/%3E%3C/svg%3E");
            }
            &:hover {
                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='20' fill='none'%3E%3Cpath fill='%23000' fill-rule='evenodd' d='M17.194.194 27 10l-9.806 9.806-1.414-1.414L23.172 11H.704V9h22.468L15.78 1.608 17.194.194Z' clip-rule='evenodd'/%3E%3C/svg%3E");
                }
            }
        }
    }

    @mixin max-lg {
        &__bottom {
            flex-wrap: wrap;
            justify-content: center;
            padding: 15px;
        }

        &__soc-box {
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;
        }
    }

    @mixin max-md {
        max-width: 500px;

        &__wrapper {
            flex-direction: column;
        }


        &__head {
            flex-direction: column;
            align-items: flex-start;
        }

        &__logo {
            max-width: 220px;
            height: 80px;
            padding-top: 15px;
            padding-left: 15px;
            border-right: 0;
        }

        &__title-box {
            padding: 0 15px 15px 15px;
            gap: 5px;
        }

        &__text-box {
            padding: 15px 5px 15px 15px;
        }
    }
}