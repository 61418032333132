.studies {
    //margin-top: ac(59px, 40px);
    background-color: #5B8643;
    padding: ac(166px, 60px) 0 ac(195px, 60px);
    overflow: hidden;
    z-index: 1;

    &__decor {
        top: -631px;
        left: -760px;
        width: 2075px;
        height: 1776px;
        z-index: -1;
    }

    &__suptitle {
        font-size: ac(20px, 16px);
        line-height: 1em;
        font-family: var(--font-sec);
        opacity: .5;
        color: var(--cl-white);
    }

    &__title {
        margin-top: ac(23px, 10px);
        padding-bottom: ac(23px, 15px);
        border-bottom: 1px solid rgba(255, 255, 255, .5);
        color: var(--cl-white);
    }

    &__subtitle {
        margin-top: ac(21px, 15px);
        font-size: ac(20px, 16px);
        line-height: ac(32px, 20px);
        color: var(--cl-white);
    }

    &__descr {
        margin-top: ac(20px, 10px);
        color: var(--cl-white);

        &>*:not(:last-child) {
            margin-bottom: ac(12px, 5px);
        }
    }

    &__slider-decor {
        position: absolute;
        top: -70px;
        right: -100px;
        width: 197px;
        height: 228px;
    }

    &__slide {
        opacity: 0 !important;

        &.swiper-slide-active {
            opacity: 1 !important;

            .team__img, .team__text-box {
                opacity: 1;
                transform: translateX(0%);
            }
        }
    }

    &__btn {
        bottom: ac(20px, 28px, 1024, 1440);
        right: ac(20px, 25px, 1024, 1440);
        padding: 0 ac(56px, 20px);
    }

    &__card {
        position: relative;
        height: 100%;
        padding: 40px 40px 100px;
        border-radius: ac(20px, 25px);
        overflow: hidden;

        .studies__descr {
            margin-top: 0;
            padding-left: 0;
            padding-right: 10px;
            color: var(--cl-black);
        }

        .simplebar-track.simplebar-vertical  {
            background-color: rgba(0, 0, 0, .2);
        }

        .js-scroll-wrap .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
            background-color: var(--cl-black);
        }
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 120%;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='610' height='404' fill='none'%3E%3Cpath fill='url(%23a)' d='M0 0h610v404H0z'/%3E%3Cpath fill='url(%23b)' d='M0 0h610v404H0z'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='610' x2='610' y1='404' y2='0' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='.318' stop-color='%23fff'/%3E%3Cstop offset='1' stop-color='%23fff' stop-opacity='.5'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='610' y1='0' y2='0' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='.237' stop-color='%23fff'/%3E%3Cstop offset='1' stop-color='%23fff' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E") center / cover no-repeat;
        }

        img {
            object-position: top right;
        }
    }

    &__scroll {
        width: calc(100% + 10px);
        max-height: 142px;
    }

    &__top {
        margin-bottom: 10px;
        color: var(--cl-dark-green);
        font-size: ac(18px, 16px);
        font-weight: 600;
        line-height: ac(34px, 25px); 
    }

    &__name {
        padding-bottom: ac(20px, 15px);
        margin-bottom: ac(20px, 15px);
        font-size: ac(32px, 22px);
        line-height: ac(44px, 32px);
        border-bottom: 1px solid rgba(77, 137, 57, .2);
    }

    .slider-nav {
        width: 200px;
        justify-content: space-between;
        margin-left: 30px;
        margin-bottom: 20px;

        .swiper-pagination {
            gap: 10px;
            bottom: unset;
            left: unset;
            top: unset;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto 0;
            pointer-events: none;
            font-size: 18px;
            font-weight: 600;
            color: rgba(0,0,0, .4);

            span {
                &:first-child {
                    color: var(--cl-dark-green);
                }

                &:last-child {
                    color: var(--cl-black);
                }
            }
        }
        &__btn--prev, &__icon--next {
            transform: rotate(0deg);
        }
        &__btn {
            flex-shrink: 0;
            width: 60px;
            height: 60px;
            border-radius: 0;
            background-color: transparent;
          
            box-shadow: none;

            &.swiper-button-disabled {
                pointer-events: none;
                opacity: .4;
            }
        
            &:hover {
                background-color: transparent;
    
                .slider-nav__icon {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='20' fill='none'%3E%3Cpath stroke='%234D8739' stroke-width='2' d='M11.06 19.1 1.96 10l9.1-9.099M20.157 10H1.842'/%3E%3C/svg%3E");
    
                    &--next {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='20' fill='none'%3E%3Cpath stroke='%234D8739' stroke-width='2' d='m9.94 19.1 9.1-9.1L9.94.901M.843 10h18.316'/%3E%3C/svg%3E");
                    }
                }
            }
    
            @mixin max-xs {
                width: 40px;
                height: 40px;
            }
        }
    
        &__icon {
            width: 21px;
            height: 20px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='20' fill='none'%3E%3Cpath stroke='%23000' stroke-width='2' d='M11.06 19.1 1.96 10l9.1-9.099M20.157 10H1.842'/%3E%3C/svg%3E");
    
            &--next {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='20' fill='none'%3E%3Cpath stroke='%23000' stroke-width='2' d='m9.94 19.1 9.1-9.1L9.94.901M.843 10h18.316'/%3E%3C/svg%3E");
            }
        }
    }
 
    @mixin lg {
        &__slider {
            width: 100%;
            margin: 0;
            max-width: ac(610px, 300px);
        }

        &__col {
            max-width: ac(566px, 400px);
            margin-right: ac(48px, 20px);
        }

        &__subtitle, &__descr {
            padding-left: ac(16px, 5px);
        }
    }

    @mixin max-lg {
        &__slider {
            max-width: ac(566px, 300px, 320, 768);
            margin: 25px auto 0;
        }

        &__card {
            padding: 20px 20px 100px;
        }

        .slider-nav {
            width: 150px;
            margin-bottom: 20px;
            margin-left: 10px;
        }
    }

    @mixin max-md {
        &__btn {
            left: 50%;
            right: unset;
            bottom: 80px;
            transform: translate(-50%);
        }

        &__card {
            padding: 20px 20px 130px;
        }

        .slider-nav {
            width: 100%;
            padding: 0 10px 10px;
            margin: 0;
        }
    }
}