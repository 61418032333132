.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 300;
	padding: 30px 0;
	transition: .3s ease;
	
	&.sticky {
		padding: 10px 0;
		background-color: rgba(8, 42, 64, .7);
		backdrop-filter: blur(13px);

		.header__navbar-box{
			padding-top: 10px;
			padding-bottom: 10px;
		}

		.header__logo {
			width: 142px;
			height: 57px;
		}
	}

	&--dark {
		&.sticky {
			.header {
				&__link {
					color: var(--cl-white);
				}

				&__logo {
					img {
						&:nth-child(2), &:nth-child(1) {
							opacity: 0;
						}
						&:nth-child(3) {
							opacity: 1;
						}
					}

					&:hover {
						img {
							&:nth-child(1), &:nth-child(3) {
								opacity: 0;
							}
			
							&:nth-child(2) {
								opacity: 1;
							}
						}
					}
				}

				&__burger {
					border-color: var(--cl-white);
	
					span {
						background-color: var(--cl-white);
					}

					&:hover {
						border-color: var(--cl-green);
	
						span {
							background-color: var(--cl-green);
						}
					}
				}
			}
		}

		&.active {
			&.header .btn {
				background-color: var(--cl-white);
				color: var(--cl-black);

				&:hover {
					color: var(--cl-white);
					background-color: var(--cl-green);
				}
			}

			.header {
				&__link {
					color: var(--cl-white);
				}

				&__logo {
					img {
						&:nth-child(2), &:nth-child(1) {
							opacity: 0;
						}
						&:nth-child(3) {
							opacity: 1;
						}
					}

					&:hover {
						img {
							&:nth-child(1), &:nth-child(3) {
								opacity: 0;
							}
			
							&:nth-child(2) {
								opacity: 1;
							}
						}
					}
				}

				&__burger {
					border-color: var(--cl-white);
	
					span {
						background-color: var(--cl-white);
					}

					&:hover {
						border-color: var(--cl-green);
	
						span {
							background-color: var(--cl-green);
						}
					}
				}
			}
		}

		&.header .btn {
			background-color: var(--cl-black);
			color: var(--cl-white);

			&:hover {
				background-color: var(--cl-green);
			}
		}

		.header {
			&__burger {
				border-color: var(--cl-black);

				span {
					background-color: var(--cl-black);
				}
			}
			&__link {
				color: var(--cl-black);
			}

			&__logo {
				img {
					&:nth-child(2), &:nth-child(3) {
						opacity: 0;
					}
				}
		
				&:hover {
					img {
						&:nth-child(1) {
							opacity: 0;
						}
		
						&:nth-child(2) {
							opacity: 1;
						}
					}
				}
			}
		}
	}

	&--job {
		.header__link {
			&:before {
				background-color: var(--cl-black);
			}
			&:hover {
				color: var(--cl-black);
			}
		}

		&.header .btn {
			&:hover {
				background-color: var(--cl-black);
			}
		}
	}
	&--industries {

		.header__link {
			&:before {
				background-color: var(--cl-yellow-sec);
			}
			&:hover {
				color: var(--cl-yellow-sec);
			}
		}

		&.header .btn {
			&:hover {
				color: var(--cl-black);
				background-color: var(--cl-yellow-sec);
			}
		}
	}

	.btn {
		padding: 0 25px;
		line-height: 46px;
		flex-shrink: 0;
		&:hover {
			background-color: var(--cl-green);
		}
	}

	&__wrapper {
		display: flex;
		align-items: center;
	}

	&__logo {
		position: relative;
		width: 162px;
		height: 77px;
		margin-right: auto;
		flex-shrink: 0;
		z-index: 5;
		transition: .3s ease;

		img {
			position: absolute;
			object-fit: contain;
			transition: 0.3s ease;

			&:nth-child(2) {
				opacity: 0;
			}
		}

		&:hover {
			img {
				&:nth-child(1) {
					opacity: 0;
				}

				&:nth-child(2) {
					opacity: 1;
				}
			}
		}
	}

	&__link {
		position: relative;
		color: var(--cl-white);
		font-size: ac(20px, 16px, 1024, 1440);
		font-weight: 700;
		line-height: normal;
		transition: .3s ease;

		&::before {
			content: '';
			position: absolute;
			bottom: ac(-2px, -4px);
			left: 0;
			width: 0%;
			height: 2px;
			background-color: var(--cl-green);
			
			transition: .3s ease;
		}

		&.active, &:hover {
			&::before {
				width: 100%;
				opacity: 1;
			}
		}

		&:hover {
			color: var(--cl-green);
		}
	}

	&__navbar {
		display: flex;
	}

	&__layout {
		position: absolute;
		width: 100%;
		height: 100vh;
		top: 0;
		left: 0;
		background-color: var(--cl-white);
		visibility: hidden;
		opacity: 0;
		transition: 0.3s ease;
		z-index: -1;

		&.active {
			opacity: 0.3;
			visibility: visible;
			cursor: pointer;
		}
	}

	@media (min-width: 1025px) {
		&__burger, &__decor, &__bottom {
			display: none;
		}

		&__navbar-box {
			width: calc(100% - 330px);
			position: relative;
			padding: 24px 0px 24px ac(80px, 40px, 1024, 1440);
		}

		&__link {
			&:not(:last-of-type) {
				margin-right: ac(29px, 20px, 1024, 1440);
			}
		}

		.simplebar-content-wrapper, .simplebar-mask {
			overflow: visible !important;
		}
		
		.simplebar-track {
			display: none !important;
		}
		
		.simplebar-content {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}

	@media not all and (min-width: 1025px) {
	padding: 20px 0;

	&__logo {
		max-width: 105px;
		height: 50px;
	}

	&__bottom {
		margin-top: auto;
		padding-top: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__info {
		color: var(--cl-white);
		font-size: 20px;
		line-height: 30px;
		font-weight: 700;
		transition: .3s ease;

		&:hover {
			color: var(--cl-green);
		}
	}

	&__copy {
		margin-top: 14px;
		color: var(--cl-white);
		font-family: var(--font-sec);
		font-size: 14px;
		font-weight: 300;
	}

    &.active {
      .header__navbar-box {
        transform: translateX(0%);
        opacity: 1;
        visibility: visible;
      }

      .header__burger {

        span {
          &:nth-child(2) {
            width: 0;
            opacity: 0;
          }

          &:nth-child(1) {
            transform: rotate(45deg) translate(4px, 4px);
          }

          &:nth-child(3) {
            transform: rotate(-45deg) translate(4px, -4px);
          }
        }
      }
    }

    &__burger {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-left: auto;
		width: 44px;
		height: 44px;
		background: transparent;
		border-radius: 100%;
		border: 1px solid var(--cl-white);
		transition: .3s ease;
		z-index: 4;
		cursor: pointer;

		span {
			pointer-events: none;
			width: 19px;
			height: 2px;
			background-color: var(--cl-white);
			transition: 0.3s ease;

			&:nth-child(2) {
				margin: 4px 0;
			}
		}

		&:hover {
			border-color: var(--cl-green);

			span {
				background-color: var(--cl-green);
			}
		}
    }

    &__navbar-box {
		width: 100%;
		max-width: 400px;
		position: absolute;
		right: 0;
		top: 0;
		margin-right: 0 !important;

		display: flex;
		flex-direction: column;
		padding: 75px 20px 29px;
		background: var(--cl-blue);
		transform: translateX(100%);
		opacity: 0;
		visibility: hidden;
		overflow: hidden;
		transition: 0.3s ease;

		height: calc(var(--vh) * 100);
    }

    &__decor {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		background-color: transparent;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='375' height='812' fill='none'%3E%3Cpath fill='%2314304B' d='M-315.187 405.352c37.962-4.003 72.831 16.608 86.84 48.38 53.993 7.056 103.91-.717 140.697-10.439 7.998-41.279 30.556-78.269 63.524-104.168-25.09-82.835-60.7-119.074-84.898-134.807h-.022c-60.326 4.271-114.65-36.537-123.246-94.099-8.974-60.06 35.107-115.662 98.51-124.162 63.403-8.5 122.097 33.257 131.071 93.318a105.692 105.692 0 0 1-3.272 45.599 105.339 105.339 0 0 1-22.211 39.897c11.455 50.69 33.322 100.184 57.956 144.194a196.78 196.78 0 0 1 144.509 4.641c41.034-51.042 62.487-121.943 71.72-160.2a106.163 106.163 0 0 1-22.673-57.602C218.635 35.408 266.571-17.252 330.452-21.7c63.881-4.448 119.418 40.972 124.155 101.466 4.682 60.493-43.256 113.144-107.113 117.598-7.368.512-14.767.36-22.107-.454-46.118 39.056-82.807 88.127-107.301 143.512 30.95 24.914 52.497 59.71 61.071 98.619 97.246 27.399 159.362 24.654 197.471 14.709 17.794-38.974 58.766-66.231 106.416-66.231C647.098 387.52 699 436.684 699 497.361c0 60.678-51.902 109.842-115.956 109.842-55.604 0-102.067-37.094-113.32-86.57-65.927-10.809-134.49-9.365-192.271-3.94-10.653 40.006-34.98 74.966-68.715 98.75 17.554 44.75 36.045 71.838 52.642 88.179a89.647 89.647 0 0 1 18.557-1.939c46.456 0 84.071 35.672 84.071 79.638 0 43.966-37.636 79.679-84.071 79.679-46.434 0-84.07-35.672-84.07-79.679.056-19.561 7.538-38.363 20.917-52.569a321.674 321.674 0 0 0-51.152-90.181 194.998 194.998 0 0 1-69.455 12.673c-88.905 0-163.326-58.882-182.1-137.777-67.092-16.691-110.117-17.681-137.668-12.605-7.188 32.805-35.959 58.984-73.005 62.884-46.163 4.842-87.741-26.662-92.881-70.38-5.139-43.718 28.139-83.1 74.29-87.973v-.041Z'/%3E%3C/svg%3E");
		z-index: -1;
    }

    &__navbar {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
    }

    .header__simplebar {
		margin-top: auto;
		max-height: calc(100% - 20px);
		padding-right: ac(20px, 10px, 374, 375);
		width: calc(100% + ac(20px, 10px, 374, 375));
    }

    .simplebar-track {
		width: 6px;
		background-color: rgb(8, 2, 35, .2);
		border-radius: 0;
    }

    .simplebar-content-wrapper {
      	padding: 0 ac(30px, 15px) 0 0;
    }

    .simplebar-scrollbar::before {
		background-color: var(--cl-green);
		border-radius: 0;
		opacity: 1;
		top: 0 !important;
		left: 0;
		bottom: 0 !important;
		right: 0;
    }

    &__link {
		font-size: 30px;
		margin-bottom: 22px;
    }
  }

  
  @mixin max-sm {
	&__navbar-box {
		max-width: unset;
	}
  }
  @mixin max-xs {
	&__link {
		font-size: 24px;
	}
  }
}