.benefits {
    margin-top: ac(80px, 60px);

    &__decor {
        top: ac(-80px, -60px);
        width: 100%;
        height: 100%;
        z-index: -1;

        img {
            object-position: right top;
        }
    }

    &__wrapper {
        padding-bottom: ac(108px, 80px, 375, 1440);
    }

    &__scrollbar {
        width: calc(100% - 178px) !important;
        left: 0 !important;
        bottom: 29px !important;
        background: #000;
        height: 2px !important;
        border-radius: 0 !important;
        opacity: .3;

        .swiper-scrollbar-drag {
            top: -4px;
            background: #000;
            border-radius: 24px !important;
            height: 10px;

        }
    }

    &__nav {
        right: 0;
        left: unset;
        width: auto;
    }

    &__slider {
        margin-top: ac(48px, 25px);
        max-width: 1064px;
        margin-left: 0;
    }

    &__card {
        padding: 0px ac(40px, 20px, 320, 1440) ac(60px, 30px, 320, 1440);
        border-radius: 20px;
        background-color: #4B6F36;
        color: var(--cl-white);
        overflow: hidden;
        transition: .3s ease;

        &:hover {
            background-color: var(--cl-green-alt);
        }
    }

    &__icon-box {
        margin-top: -39px;
        width: ac(105px, 80px);
        height: ac(184px, 150px);
        padding-bottom: 13px;

        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='105' height='184' fill='none'%3E%3Cpath fill='%23569C3A' d='M32.523 83.053C38.333 65.23 47.42 31.21 44.013.758L67.598 0a190.533 190.533 0 0 0 6.433 83.746A52.518 52.518 0 0 1 99.439 108.1a52.399 52.399 0 0 1 4.303 34.902 52.396 52.396 0 0 1-18.737 29.757 52.514 52.514 0 0 1-33.351 11.234 52.569 52.569 0 0 1-32.971-12.341A52.469 52.469 0 0 1 .917 141.285a52.373 52.373 0 0 1 5.426-34.739 52.433 52.433 0 0 1 26.18-23.493Z'/%3E%3Cpath stroke='%23fff' stroke-opacity='.3' stroke-width='2' d='m32.902 83.978.428-.175.143-.44c5.75-17.64 14.752-51.244 11.648-81.64l21.32-.685a191.534 191.534 0 0 0 6.635 83.003l.134.432.412.185a51.52 51.52 0 0 1 24.923 23.89 51.397 51.397 0 0 1 4.221 34.236 51.396 51.396 0 0 1-18.38 29.189 51.513 51.513 0 0 1-32.716 11.02 51.567 51.567 0 0 1-32.343-12.106 51.47 51.47 0 0 1-17.428-29.788 51.372 51.372 0 0 1 5.323-34.076 51.433 51.433 0 0 1 25.68-23.045Z'/%3E%3C/svg%3E") center / contain no-repeat;
    
        
        &--sm {
            padding-bottom: 18px;

            .benefits__icon {
                width: ac(66px, 50px);
                height: ac(70px, 56px);
            }
        }
    }

    &__icon {
        width: ac(80px, 60px);
        height: ac(80px, 60px);
    }

    &__name {
        margin-top: ac(19px, 15px);
        font-size: ac(32px, 24px);
        line-height: ac(36px, 28px);
    }

    &__sep  {
        margin-top: ac(19px, 15px);
        width: ac(80px, 60px);
        height: 1px;
        background-color: var(--cl-white);
        opacity: .3;
    }

    &__simplebar {
        margin-top: ac(16px, 12px);
        width: calc(100% + 16px);
        max-height: calc(ac(32px, 24px) * 7);
    }

    &__descr {
        padding: 0 8px;
        font-size: ac(20px, 18px);
        line-height: ac(32px, 24px);
    }

    @mixin max-xs {
        &__wrapper {
            padding-bottom: 60px;
        }

        &__scrollbar {
            width: calc(100% - 130px) !important;
            bottom: 21px !important;
        }
    }
}