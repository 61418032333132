.resources {
    background-color: #B27335;
    padding-top: ac(131px, 92px);
    padding-bottom: ac(93px, 90px);
    z-index: 1;

    &--details {
        padding-top: ac(141px, 92px);
        margin-top: ac(80px, 60px);
        padding-bottom: ac(479px, 400px);
        overflow: hidden;

        .resources__suptitle {
            margin-bottom: ac(21px, 12px);
            font-family: var(--font-main);
            opacity: 1;
        }

        .resources__nav {
            transform: unset;
            bottom: ac(356px, 300px);
        }

        .resources__circle {
            top: ac(-342px, -50px, 320, 1440);
            right: ac(-908px, -250px, 320, 1440);
            width: ac(1387px, 350px, 320, 1440);
            height: ac(1386px, 600px, 320, 1440);
            border-radius: 100%;
        }
    
    }

    &__decor-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        overflow: hidden;
    }

    &__decor {
        position: absolute;
        top: ac(-80px, -30px, 320, 1440);
        left: 50%;
        width: ac(1600px, 750px, 320, 1440);
        height: ac(1600px, 750px, 320, 1440);
        transform: rotate(195deg) translateX(calc(-50% + ac(1494px, 750px, 320, 1440)));
        
        svg {
            path {
                fill: #BF813B
            }
        }
    }


    &__circle {
        position: absolute;
     
        right: ac(0px, -40px);
        top: 0;
        height: 100%; 
        width: ac(465px, 317px, 375, 1440);
        clip-path: url("#resources-circle");
        z-index: -1;
        overflow: hidden;

        transition: .3s linear;

        img {
            width: 100%;
            height: 156%;
            object-position: left ac(-338px, 0px);
        }
    }

    &__title, &__suptitle, &__descr, &__name, &__date {
        color: var(--cl-white);
    }

    &__suptitle {
        font-family: var(--font-sec);
        margin-bottom: ac(18px, 12px);
        font-size: ac(20px, 18px);
        line-height: ac(24px, 22px);
        opacity: .5 !important;
    }

    &__slider-box {
        display: flex;
        max-width: 904px;
        margin-top: ac(48px, 39px);
    }

    &__nav {
        transform: translateY(50%);
    }

    &__slider {
        overflow: visible;
    }

    &__card {
        padding-bottom: ac(27px, 17px);
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid var(--cl-white);
        cursor: pointer;
        height: 100%;
        transition: .3s ease;

        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }

    &__img {
        position: relative;
        width: 100%;
        height: ac(250px, 215px);
        overflow: hidden;

        img {
            transition: .3s ease-in-out;
        }
    }

    &__tag {
        font-size: 14px;
        line-height: 29px;
        padding: 0 8px;
        font-weight: 500;
        font-family: var(--font-sec);
        background-color: var(--cl-white);
        border-radius: 55px;
        z-index: 1;
    }

    &__descr {
        margin-top: ac(33px, 17px);
        font-size: ac(26px, 18px);
        line-height: ac(36px, 24px);
        font-weight: 500;
        margin-bottom: ac(24px, 19px);
        max-height: calc(ac(36px, 24px) * 3);
        @mixin max-line-length 3;
        transition: .3s ease;
    }

    &__bottom {
        display: flex;
        align-items: flex-end;
        margin-top: auto;
    }

    &__name, &__date {
        margin-right: ac(36px, 30px);
        font-size: 16px;
        font-weight: 400;
        transition: .3s ease;
    }

    &__date, &__arrow {
        flex-shrink: 0;
    }

    &__arrow {
        margin-bottom: 5px;
        margin-left: auto;
        width: 14px;
        height: 14px;
        margin-right: ac(0px, 4px);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left bottom;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14'%3E%3Cg fill='none' stroke='%23fff'%3E%3Cpath d='M.354 13.222h12.868V.354' data-name='Контур 19'/%3E%3Cpath d='m.354.354 12.952 12.952' data-name='Контур 20'/%3E%3C/g%3E%3C/svg%3E");
        transition: .3s ease;
    }

    @mixin xxl {
        &__slider-box {
            max-width: unset;
        }
    }

    @mixin md {
        &__title {
            max-width: 439px;
        }
    }

    @mixin max-md {
        &:not(.resources--details) {
            .resources__circle {
                right: -190px;
                height: calc(100% - 131px);
    
                img {
                    height: 100%;
                    object-position: right;
                }
            }
        }
    }

    @mixin max-xs {
        &:not(.resources--details) {
            .resources__circle {
                right: -200px;
            }
        }
    }
}