:root {
  --pp-main: #707070;
  --pp-main-rgb: 112 112 112;

  --pp-black: #000;
  --pp-black-rgb: 0 0 0;

  --pp-white: #ffffff;
  --pp-white-rgb: 255 255 255;

  --pp-dark-blue: #0e1127;
  --pp-dark-blue-rgb: 14 17 39;

  --pp-blue: #3ab4ff;
  --pp-blue-rgb: 58 180 255;

  --pp-dark-gray: #343434;
  --pp-dark-gray-rgb: 52 52 52;

  --pp-light-gray: #dce1e4;
  --pp-light-gray-rgb: 220 225 228;

  --pp-font-main: var(--font-main);
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8999;
  display: none;

  &.active {
    display: block;
  }

  &__close-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: -1;
    transition: all 0.5s ease;
    animation-duration: 0.3s;
    animation-name: opacityPopupBg;
  }

  &__block {
    --pp-default-translate-x-from: -50%;
    --pp-default-translate-x-to: -50%;

    --pp-default-translate-y-from: -50%;
    --pp-default-translate-y-to: -50%;

    --pp-opacity-from: 0;
    --pp-opacity-to: 1;

    background: var(--pp-white);

    position: absolute;
    top: 50%;
    left: 50%;

    width: 90%;
    max-width: ac(850px, 600px);
    padding: ac(40px, 30px) ac(40px, 24px);

    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    transition: all 300ms ease-out;
    animation-duration: 0.6s;

    &.without-opacity {
      --pp-opacity-from: 1;
    }

    &.pos-l,
    &.pos-r {
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;
    }

    &.pos-t,
    &.pos-b {
      --pp-default-translate-y-from: 0;
      --pp-default-translate-y-to: 0;
    }

    &.pos-t {
      top: 0;
      bottom: initial;
    }

    &.pos-r {
      right: 0;
      left: initial;
    }

    &.pos-b {
      top: initial;
      bottom: 0;
    }

    &.pos-l {
      right: initial;
      left: 0;
    }

    &.full-height {
      max-height: 100vh;
      height: 100%;
      right: 0;
      left: initial;
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;

      .popup__content {
        max-height: calc(var(--vh, 1vh) * 72);
      }
    }

    &.slide-l {
      --pp-default-translate-x-from: 100%;
    }

    &.slide-r {
      --pp-default-translate-x-from: -100%;
    }

    &.slide-t {
      --pp-default-translate-y-from: -100%;
    }

    &.slide-b {
      --pp-default-translate-y-from: 100%;
    }

    &.slide-t,
    &.slide-r,
    &.slide-b,
    &.slide-l {
      animation-name: popupSlide;
    }
  }

  &__title {
    color: var(--pp-dark-blue);
    font-size: ac(24px, 20px);
    line-height: 1.2;
    font-family: var(--pp-font-main);
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: ac(32px, 24px);
  }

  &__btn-close {
    position: absolute;
    right: ac(25px, 15px);
    top: ac(25px, 15px);
    transition: all 300ms ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .close-clip {
      transition: inherit;
      width: ac(24px, 20px);
      height: ac(24px, 20px);
      background: var(--pp-dark-blue);
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
    }

    i {
      transition: inherit;
      font-size: ac(24px, 20px);
      color: var(--pp-dark-blue);
    }

    &:hover {
      .close-clip {
        background: var(--pp-blue);
        transform: scale(1.05);
      }

      i {
        color: var(--pp-blue);
        transform: scale(1.05);
      }
    }
  }

  &__content {
    max-height: calc(var(--vh, 1vh) * 66);

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--pp-light-gray);
      border-radius: 4px;
      transform: translateX(10px);

      .simplebar-scrollbar {
        background: var(--pp-dark-blue);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }

    .popup-form {
      &__container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 15px;
        grid-row-gap: 15px;

        @mixin max-sm {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }

      &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;
        padding-top: ac(24px, 20px);
        flex-wrap: wrap;

        .popup-btn {
          margin-left: auto;
        }
      }
    }

    .popup-btn {
      font-size: ac(16px, 14px);
      font-weight: 700;
      font-family: var(--pp-font-main);
      line-height: 1.2;
      text-transform: uppercase;
      background-color: var(--pp-dark-blue);
      color: var(--pp-white);
      border: none;
      outline: none;
      border-radius: 4px;
      padding: 12px 18px;
      cursor: pointer;
      transition: 0.3s ease-in-out all;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: skew(45deg);
        pointer-events: none;
      }

      &:hover {
        &::after {
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.7) 100%
          );
          animation: gradientPopupBtn 0.3s linear forwards;
        }
      }

      &:active {
        transform: scale(0.9);
        opacity: 0.9;
      }
    }
  }
}

@keyframes popupSlide {
  from {
    transform: translateY(var(--pp-default-translate-y-from))
      translateX(var(--pp-default-translate-x-from));
    opacity: var(--pp-opacity-from);
  }

  to {
    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    opacity: var(--pp-opacity-to);
  }
}

@keyframes opacityPopupBg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gradientPopupBtn {
  0% {
    transform: translateX(-100%) skew(45deg);
  }

  100% {
    transform: translateX(100%) skew(45deg);
  }
}

.video-popup {
  height: ac(500px, 200px, 320, 1440);
  padding: 40px!important;
  min-height: unset !important;
  border-radius: 20px;
  overflow: hidden;

  .popup-team__close {
    top: 15px;
    right: 15px;
    background-color: var(--cl-white);

  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100%;
    z-index: -1;

    .plyr__poster {
      //opacity: 0;
    }
    .plyr__video-embed {
      aspect-ratio: inherit;
    }


    iframe {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .plyr {
      height: 100%;
      border-radius: 0;
    }
    .plyr__poster {
      background-size: cover;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--cl-black);
        opacity: .4;
        z-index: -1;
      }
    }

    video {
      object-fit: cover;
    }

    .plyr__video-wrapper {
      z-index: -1 !important;
    }
  }
}


.popup-apply-now {
  padding: 0;
  max-width: 845px;
  background-color: var(--cl-white);

  .choices .choices__list--dropdown {
    padding-right: 0;
    width: calc(100% + ac(30px, 0px));
    left: 50%;
    transform: translateX(-50%);

    .choices__item {
      font-size: 14px;
    }
  }

  .choices .choices__list--dropdown .choices__list>div {
    width: 100%;
    padding-right: 0px;

    .simplebar-vertical {
      right: -1px;
    }
  }

  .popup-services__close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='25' fill='none'%3E%3Cpath fill='%23FFF' d='M20.955 25c-.324 0-.632-.046-.924-.137-.26-.122-.519-.335-.778-.64l-8.508-9.735-1.653-1.508-8.46-9.644C.243 2.88.032 2.392 0 1.874 0 1.356.162.914.486.548.843.183 1.346 0 1.993 0c.713 0 1.28.229 1.702.686l8.265 9.506 1.264 1.051 9.14 10.512c.454.518.665 1.036.633 1.554-.033.488-.243.899-.632 1.234-.39.305-.86.457-1.41.457ZM2.188 25c-.616 0-1.102-.168-1.459-.503a1.83 1.83 0 0 1-.535-1.325c0-.518.211-1.02.633-1.508l8.508-10.01 2.43 3.2-7.973 9.46c-.421.457-.956.686-1.604.686Zm11.766-11.792-3.015-2.559L18.961.777c.26-.305.519-.503.778-.594.292-.122.616-.183.973-.183.648 0 1.134.198 1.458.594.357.366.519.823.486 1.371 0 .518-.21 1.021-.632 1.508l-8.07 9.735Z'/%3E%3C/svg%3E");
    cursor: pointer;

    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='25' fill='none'%3E%3Cpath fill='%23E4B700' d='M20.955 25c-.324 0-.632-.046-.924-.137-.26-.122-.519-.335-.778-.64l-8.508-9.735-1.653-1.508-8.46-9.644C.243 2.88.032 2.392 0 1.874 0 1.356.162.914.486.548.843.183 1.346 0 1.993 0c.713 0 1.28.229 1.702.686l8.265 9.506 1.264 1.051 9.14 10.512c.454.518.665 1.036.633 1.554-.033.488-.243.899-.632 1.234-.39.305-.86.457-1.41.457ZM2.188 25c-.616 0-1.102-.168-1.459-.503a1.83 1.83 0 0 1-.535-1.325c0-.518.211-1.02.633-1.508l8.508-10.01 2.43 3.2-7.973 9.46c-.421.457-.956.686-1.604.686Zm11.766-11.792-3.015-2.559L18.961.777c.26-.305.519-.503.778-.594.292-.122.616-.183.973-.183.648 0 1.134.198 1.458.594.357.366.519.823.486 1.371 0 .518-.21 1.021-.632 1.508l-8.07 9.735Z'/%3E%3C/svg%3E");
    }
  }

  .ready__label {
    &>span {
      font-size: ac(18px, 14px);
    }

    input {
      &,&::placeholder {
        font-size: ac(18px, 14px);
      }
    }
    textarea {
      &,&::placeholder {
        font-size: ac(16px, 14px);
      }
    }
  }

  .footer__form {
    display: flex;
    flex-wrap: wrap;
    gap: ac(20px, 10px) 0;
    justify-content: space-between;
    max-width: ac(844px, 470px);
    width: 100%;

    .insights__select, input {
      margin-top: auto;
    }

    .thanks {
      color: var(--cl-white);
      margin: 0 auto;

      @mixin md {
        margin: ac(200px, 150px) auto 0;
      }

      .text-description {
        margin-top: 20px;
        font-weight: 300;
        font-size: ac(22px, 18px);
        line-height: ac(32px, 26px);
      }
    }
  }

  .ready__label--area {
    width: 100%;
  }

  .footer__btn-box {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
  }

  .footer__upload-box {
    position: relative;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    margin-right: 20px;

    input {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      z-index: -1;
    }

    div {
      margin-right: 15px;
      border-radius: 5px;
      padding: 0 ac(20px, 10px);
      line-height: 44px;
      font-weight: 600;
      color: var(--cl-black);
      border: 1px solid #C0C0C0;
      background:  #F6F9F5;
      border-bottom-color: var(--cl-green);
      transition: .3s ease;
    }

    &:hover {
      div {
        color: var(--cl-white);
        background-color: var(--cl-dark-green);
      }

      .footer__upload-text {
        color: var(--cl-dark-green);
      }
    }
  }

  .footer__upload-text {
    line-height: 48px;
    max-height: 48px;
    font-weight: 600;
    color: var(--cl-black);
    transition: .3s ease;
    @mixin max-line-length 1;
  }

  .footer__btn {
    margin-left: auto;
  }

  .popup__title {
    color: var(--cl-black);
  }

  .footer__form {
    max-width: unset;
    padding-right: 1px;

    .title, .thanks, .title-small {
      color: var(--cl-black);
      margin: 0 auto ac(51px, 20px, 320, 1440);
    }
  }

  .popup-services__close {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='25' fill='none'%3E%3Cpath fill='%23000' d='M20.955 25c-.324 0-.632-.046-.924-.137-.26-.122-.519-.335-.778-.64l-8.508-9.735-1.653-1.508-8.46-9.644C.243 2.88.032 2.392 0 1.874 0 1.356.162.914.486.548.843.183 1.346 0 1.993 0c.713 0 1.28.229 1.702.686l8.265 9.506 1.264 1.051 9.14 10.512c.454.518.665 1.036.633 1.554-.033.488-.243.899-.632 1.234-.39.305-.86.457-1.41.457ZM2.188 25c-.616 0-1.102-.168-1.459-.503a1.83 1.83 0 0 1-.535-1.325c0-.518.211-1.02.633-1.508l8.508-10.01 2.43 3.2-7.973 9.46c-.421.457-.956.686-1.604.686Zm11.766-11.792-3.015-2.559L18.961.777c.26-.305.519-.503.778-.594.292-.122.616-.183.973-.183.648 0 1.134.198 1.458.594.357.366.519.823.486 1.371 0 .518-.21 1.021-.632 1.508l-8.07 9.735Z'/%3E%3C/svg%3E");

    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='25' fill='none'%3E%3Cpath fill='%234D8739' d='M20.955 25c-.324 0-.632-.046-.924-.137-.26-.122-.519-.335-.778-.64l-8.508-9.735-1.653-1.508-8.46-9.644C.243 2.88.032 2.392 0 1.874 0 1.356.162.914.486.548.843.183 1.346 0 1.993 0c.713 0 1.28.229 1.702.686l8.265 9.506 1.264 1.051 9.14 10.512c.454.518.665 1.036.633 1.554-.033.488-.243.899-.632 1.234-.39.305-.86.457-1.41.457ZM2.188 25c-.616 0-1.102-.168-1.459-.503a1.83 1.83 0 0 1-.535-1.325c0-.518.211-1.02.633-1.508l8.508-10.01 2.43 3.2-7.973 9.46c-.421.457-.956.686-1.604.686Zm11.766-11.792-3.015-2.559L18.961.777c.26-.305.519-.503.778-.594.292-.122.616-.183.973-.183.648 0 1.134.198 1.458.594.357.366.519.823.486 1.371 0 .518-.21 1.021-.632 1.508l-8.07 9.735Z'/%3E%3C/svg%3E");
    }
  }

  .footer__upload-box {
    width: 100%;
    margin-right: 0;
  }

  .footer__btn {
    margin-top: 20px;
    margin-bottom: ac(51px, 20px, 320, 1440);
  }

  .agreement {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 150px);

    .checkBox {
      display: flex;
      position: relative;
      width: 100%;

      input {
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        visibility: hidden;
      }
      input:checked + span:before {
        background: var(--cl-dark-green);
      }

      span {
        padding-left: ac(35px, 30px);
        margin-bottom: 0;
        color: var(--cl-black);
        cursor: pointer;

        a {
          display: inline-block;
          color: var(--cl-black);
          text-decoration: underline;

          &:hover {
            color: var(--cl-dark-green);
          }
        }

        &::before {
          content: '';
          background: transparent;
          border: 1px solid var(--cl-black);
          box-sizing: border-box;
          content: "";
          height: 20px;
          left: 0;
          position: absolute;
          top: 10px;
          transition: all .3s ease-out;
          width: 20px;
          cursor: pointer;
        }
      }
    }
  }

  #popup-cv-upload,
  #popup-apply-now {
    max-height: 85vh;
    width: 100%;
    padding: 51px ac(60px, 10px, 320, 1440) 0 ac(61px, 10px, 320, 1440);

    p {
      color: var(--cl-black);
    }

    .simplebar-track.simplebar-vertical {
      width: 6px;
      right: 0px;
      background-color: rgba(0, 0, 0, .2);
    }

    .simplebar-scrollbar::before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--cl-dark-green);
      opacity: 1;
      border-radius: 0;
    }
  }

  @mixin max-lg {
    .footer__upload-box {
      flex-direction: row;
      justify-content: flex-start;
    }

    .footer__upload-text {
      margin: 0 10px;
    }
  }

  @mixin max-sm {
    .agreement {
      width: 100%;
    }
    .footer__btn {
      width: 100%;
      justify-content: center;
    }

    .footer__upload-box {
      flex-direction: column;
      align-items: center;

      &>div {
        margin-right: 0;
      }
    }

  }
}

.choice-us {
  &__btn {
    position: absolute;
    top: 15px;
    left: 15px;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px 12px;
    border-radius: 5px;
    background-color: var(--cl-dark-green);
    box-shadow: 5px 10px 10px rgba(0, 0, 0, .2);
    transition: .3s ease;
    cursor: pointer;
    z-index: 2;

    &--prev {
      .choice-us__arrow {
        transform: rotate(135deg);
      }

      &:not(.swiper-button-disabled) {
        & + .choice-us__btn--submit {
        //opacity: 1;
        //visibility: visible;
        }
      }
    }

    &.swiper-button-disabled {
      opacity: 0;
      visibility: hidden;
    }

    span:last-child {
      font-size: ac(20px, 14px);
      font-weight: 600;
      color: var(--cl-white);
      transition: .3s ease;
    }

    &:hover {
      background-color: var(--cl-light-green);

      span:last-child {
        color: var(--cl-green);
      }

      .choice-us__arrow {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14'%3E%3Cg fill='none' stroke='%235B8643'%3E%3Cpath d='M.354 13.222h12.868V.354' data-name='Контур 19'/%3E%3Cpath d='m.354.354 12.952 12.952' data-name='Контур 20'/%3E%3C/g%3E%3C/svg%3E");
      }
    }
  }

  &__arrow {
    width: 14px;
    height: 14px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14'%3E%3Cg fill='none' stroke='%23fff'%3E%3Cpath d='M.354 13.222h12.868V.354' data-name='Контур 19'/%3E%3Cpath d='m.354.354 12.952 12.952' data-name='Контур 20'/%3E%3C/g%3E%3C/svg%3E");
    transition: .3s ease;
  }

  &__slider {
    overflow: hidden;

    .btn {
      box-shadow: -5px 10px 10px 0 rgba(0,0,0,.2);
    }
  }
  .ready__form {
    margin-bottom: ac(51px, 20px, 320, 1440);
    font-size: ac(34px, 24px, 320);
    line-height: ac(50px, 30px, 320);
    justify-content: center;

    .ready__input {
      margin-bottom: 0;

      span:last-child {
        font-size: 20px;
        border: 2px solid var(--cl-green);
      }
    }

  }
  &__slide {
    opacity: 0 !important;
    visibility: hidden;

    &>* {
      transform: translateY(-50%);
      transition: .3s ease;
    }

    &.swiper-slide-active {
      opacity: 1 !important;
      visibility: visible;

      &>* {
        transform: translateY(0);
      }
    }

    &--form {
      &.swiper-slide-active {
        padding-top: 40px;
      }
    }
  }
}


.franchise-popup {
  ul {
    list-style: inside disc;
  }
  &__slider {
    overflow: hidden;
  }

  &__slide {
    opacity: 0 !important;
    visibility: hidden;

    &.swiper-slide-active {
      opacity: 1 !important;
      visibility: visible;
    }
  }

  &__head {
    font-size: ac(46px, 30px);
    line-height: ac(56px, 44px);

    &--sm {
      font-size: ac(28px, 20px);
      line-height: ac(34px, 26px);
    }
  }

  &__group {
    gap: ac(15px, 10px) 10px;
    justify-content: space-between;
  }

  &__name {
    margin-top: 10px;
    font-size: ac(24px, 20px);
    line-height: ac(32px, 26px);
  }

  .insights__select {
    [data-simplebar] {
      width: 100%;
      padding-right: 5px;

      .simplebar-content {
        &>.choices__item:first-child {
          display: none;
        }
      }
    }
  }

  .btn {
    margin-bottom: ac(40px, 20px);
    cursor: pointer;
  }

  &__submit {
    margin-top: 40px;
    display: none !important;
    border: 1px solid var(--cl-green);
    &.btn:hover {
      background-color: var(--cl-green);
    }
  }

  &__btn {
    margin-top: 40px;
    border: 1px solid var(--cl-green);
    &.btn:hover {
      background-color: var(--cl-green);
    }

    &.swiper-button-disabled {
      display: none;

      &+.franchise-popup__btn--next {
        margin-left: auto;
      }

      &.franchise-popup__btn--next~.franchise-popup__submit {
          display: inline-flex !important;
      }
    }
  }

  .insights__select {
    .choices__list--single .choices__item {
      font-size: ac(18px, 14px);
    }
  }
  .ready__input {
    span {
      border: 1px solid var(--cl-green);
    }
  }
  .ready__label--sign {
    margin-top: ac(20px, 15px);

    .btn {
      margin: 10px auto -10px;
      line-height: 40px;
      border: 1px solid var(--cl-green);

      &:hover {
        background-color: var(--cl-green);
      }
    }
  }

  &__signature-box {
    position: relative;
    width: 100%;
    height: 200px;
    user-select: none;
    border-radius: 30px;
    border: 1px solid var(--cl-green);
    overflow: hidden;

    canvas {
      width: 100%;
      height: 100%;
    }
  }

  &__pad {
    position: absolute;
    left: 0;
    top: 0;
    width:400px;
    height:200px;
    background-color: white;
  }

  &__pagination {
    top: -9px !important;
    height: 10px !important;
    background-color: var(--cl-light-blue);

    .swiper-pagination-progressbar-fill {
      background: var(--cl-green) !important;
    }
  }
}



