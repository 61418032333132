.ready {
    margin-top: ac(142px, 84px);

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        font-family: var(--font-sec);
        text-align: center;
        font-weight: 400;
        line-height: 155%;

        span {
            font-weight: 700;
            font-family: var(--font-main);
            font-size: ac(70px, 34px);
        }
    }

    &__slider-box {
        position: relative;
        margin-top: ac(37px, 23px);
        width: 100%;
        max-width: ac(460px, 350px);
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &>div {
            font-size: 20px;
            line-height: 30px;
            font-weight: 600;
            width: 100%;
        }
    }

    &__input {
        position: relative;

        &:hover {
            span {
                background-color: var(--cl-dark-green);
                color: var(--cl-white);
            }
        }

        input {
            position: absolute;
            width: 0;
            height: 0;
            z-index: -1;
            visibility: hidden;

            &:checked~span {
                background-color: var(--cl-dark-green);
                color: var(--cl-white);
            }
        }

        span {
            width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            height: 50px;
            color: var(--cl-green);
            background-color: var(--cl-light-green);
            padding: 0 ac(32px, 5px, 320, 1440);
            cursor: pointer;
            text-align: center;
            transition: .3s ease;
        }
    }

    &__slide {
        opacity: 0 !important;
        visibility: hidden;
        padding-bottom: 100px;

        &>* {
            transform: translateY(-50%);
            transition: .3s ease;
        }

        &.swiper-slide-active {
            opacity: 1 !important;
            visibility: visible;

            &>* {
                transform: translateY(0);
            }
        }

        &--form {
            gap: ac(20px, 10px);
            padding: 0 ac(37px, 0px) ac(143px, 160px, 320, 1440);

            &.swiper-slide-active {
                padding-bottom: ac(65px, 180px, 320, 1440);
            }
        }
    }

    &__label {
        width: 100%;
        display: flex;
        flex-direction: column;

        &:hover {
            input, textarea {
                border-color: var(--cl-green);
            }
        }

        span {
            padding-left: 12px;
            margin-bottom: ac(8px, 5px);
            font-size: 14px;
            line-height: 20px;
            font-family: var(--font-sec);
        }

        input {
            padding: ac(14px, 10px) 12px;
            width: 100%;
            border-radius: 5px;
            border: 1px solid #C0C0C0;
            background:  #F6F9F5;
            border-bottom-color: var(--cl-green);
            transition: .3s ease;

            &,&::placeholder {
                font-size: 14px;
                line-height: 20px;
                font-family: var(--font-sec);
            }

            &::placeholder {
                opacity: 1;
                color: #878787;
            }
        }
    }

    &__bottom {
        margin-top: ac(20px, 10px);
        gap: 15px;
        width: 100%;
        align-items: center;

        .btn {
            margin-left: auto;
        }
    }



    @mixin sm {
        &__label {
            width: calc(100% / 2 - 10px);
        }
    }

    @mixin md {
        &__form {
            justify-content: center;
            gap: 10px;
        }

        .ready__form {
            gap: 10px ac(20px, 10px);
        }
    }

    @mixin max-md {
        &__form {
            margin-bottom: -10px;

        }

        &__input {
            width: calc(100% / 2 - 5px);
            margin-bottom: 10px;

            span {
                font-weight: 300;
            }
        }

        &__title {
            font-size: 26px;
            font-family: var(--font-main);
            line-height: 172%;
        }
    }

    @mixin max-sm {
        &__bottom {
            flex-direction: column;
        }
    }
}