.testimonials {
    background-color: #64844B;
    padding-top: ac(113px, 61px);
    padding-bottom: ac(85px, 81px);

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    &__decor-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    &__decor {
        position: absolute;
        bottom: ac(-147px, -30px);
        left: 50%;
        width: ac(2182px, 1259px);
        height: ac(1867px, 1077px);
        transform: translate(calc(-50% - ac(241px, 220px)), 0);
        transition: 1.5s linear;
        img {
            object-fit: contain;
            object-position: right bottom;
        }
    }

    &__title, &__name, &__descr, &__position {
        color: var(--cl-white);
    }

    &__title {
        width: 100%;
        text-align: center;
    }

    &__slider-box {
        display: flex;
        max-width: ac(1105px, 1280px, 1366, 1440);
        width: 100%;
        margin-top: ac(41px, 39px);
        margin-left: ac(88px, 0px, 1366, 1440);
    }

    &__slider {
        overflow: visible !important;
    }

    &__nav {
        transform: translateY(50%);
    }

    &__card {
        height: 100%;
        padding: ac(51px, 20px) ac(52px, 20px);
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        background-color: #4B6F36;
    }

    &__name {
        font-size: ac(26px, 18px);
        line-height: ac(36px, 22px);
        font-weight: 500;
        margin-bottom: ac(12px, 15px);
    }

    &__simplebar {
        margin-bottom: ac(23px, 28px);
        max-height: calc(ac(26px, 17px) * 4);
        max-width: calc(100% + 10px);
    }

    &__descr {
        padding-right: 10px;
        font-size: ac(16px, 14px);
        line-height: ac(26px, 17px);
    }

    &__position {
        margin-top: auto;
        font-size: ac(20px, 16px);
        line-height: ac(24px, 20px);
        font-weight: 700;
    }

    @mixin xxl {
        &__slider-box {
            max-width: unset;
            margin-left: 0;
        }
    }

    @mixin max-md {
        &__card {
            border-radius: 10px;
        }
    }
}