.sign-up {
    margin-top: -230px;
    z-index: 1;

    &--unsub {
        margin: ac(300px, 150px) 0 ac(150px, 100px);

        .sign-up__form {
            h4 {
                width: 100%;
                text-align: center;
                color: #fff;
            }
        }
    }

    &--insights, &--jobs {
        margin-top: ac(160px, 60px);
    }

    &--insights {
        .sign-up__descr {
            font-size: ac(17px, 15px);
            line-height: ac(26px, 20px);

            a {
                color: var(--cl-dark-green);
                font-size: inherit;
                line-height: inherit;
                font-weight: inherit;
                transition: .3s ease;

                &:hover {
                    opacity: .7;
                }
            }
        }
    }

    &--details {
        margin-top: ac(75px, 60px);
    }

    &--jobs, &--details {
        .sign-up__wrapper {
            border-color: #743D8A;
        }

        .sign-up__col, .sign-up__sep {
            background-color: #743D8A;
        }
    }

    &--jobs {
        .sign-up__form {
            h4 {
                width: 100%;
                text-align: center;
                color: #fff;
            }
        }
    }

    &__wrapper {
        border: 8px solid var(--cl-dark-green);
        border-radius: 20px;
        margin: 0 auto;
    }

    &__decor {
        width: ac(234px, 100px);
        height: ac(245px, 100px);
        z-index: 1;

        &:nth-child(1) {
            top: ac(-121px, -50px);
            left: ac(-130px, -50px);
        }

        &:nth-child(2) {
            right: ac(-120px, -10px, 1200, 1440);
            bottom: ac(-124px, -95px, 1024, 1440);
        }
    }

    &__text-box, &__col {
        padding: ac(56px, 10px, 320, 1440);
        width: 100%;
        min-height: ac(460px, 200px);
    }

    &__text-box {
        background-color: var(--cl-white);
    }

    &__sep {
        width: 100%;
        height: 1px;
        background-color: var(--cl-dark-green);
        margin: ac(32px, 15px) 0;
    }

    &__col {
        background-color: var(--cl-dark-green);
    }

    &__name {
        max-width: 15ch;
        font-weight: 400;
        font-size: ac(32px, 20px);
        line-height: ac(44px, 30px);
        color: var(--cl-white);
        margin-bottom: ac(32px, 20px);
    }

    &__descr {
        font-size: ac(20px, 18px);
        line-height: ac(32px, 26px);
    }

    &__form {
        width: ac(430px, 370px);
        padding: 8px;
        border: 1px solid var(--cl-black);
        border-radius: 100px;

        .thanks {
            width: 100%;
            text-align: center;
            color: var(--cl-white);
            margin: 0;
            line-height: ac(56px, 42px);
        }
    }

    &__input {
        width: 100%;
        padding-right: 70px;
        margin-right: -50px;
        background-color: var(--cl-white);
        border-radius: 100px;

        &,
        &::placeholder {
            font-size: 16px;
            line-height: 56px;
        }
    }

    &__btn {

        &:hover {
            background-color: var(--cl-yellow-sec);
        }
    }

    @mixin md {
        &__text-box {
            max-width: ac(576px, 300px);
        }

        &__text-box {
            border-radius: 10px 0 0 10px;
        }

        &__col {
            border-radius: 0 10px 10px 0;
        }
    }

    @mixin max-md {
        &__wrapper {
            max-width: 500px;
        }

        &__text-box {
            border-radius: 10px 10px 0 0px;
        }

        &__col {
            border-radius: 0 0px 10px 10px;
        }
    }

    @mixin max-sm {
        &__input {
            margin-right: 0;
            border-radius: 20px;
            margin-bottom: 10px;
            padding-right: 20px;
        }

        &__form {
            width: 100%;
            border-radius: 20px;
            flex-direction: column;
            align-items: center;
        }
    }
}