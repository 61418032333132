.team-list {
    margin-top: ac(80px, 60px);

    &__subtitle {
        margin-top: 20px;
        max-width: 720px;
        font-size: 26px;
        line-height: 40px;
        font-family: var(--font-sec);
    }

    &__filter {
        margin-top: ac(41px, 25px);
        max-width: unset;

        span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            line-height: 24px;
        }

        .insights__select {
            max-width: 196px;
            height: 40px;
            margin-left: 0;
        }
    }

    &__select-box {
        margin-top: ac(40px, 20px);
        justify-content: flex-start;
    }

    &__card-box {
        margin-top: ac(79px, 30px);
        gap: ac(32px, 15px);
    }

    &__card {
        padding: ac(28px, 20px) ac(48px, 20px) ac(28px, 20px) ac(32px, 20px);
        border-radius: ac(193px, 100px);
        border: 1px solid rgba(8, 48, 77, .2);
        transition: .3s ease;
        cursor: pointer;

        &:hover {
            border-color: var(--cl-green);

            .team-list__btn {
                border-color: var(--cl-green);
                color: var(--cl-green);
        
                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none'%3E%3Cpath stroke='%23569C3A' d='M.524 13.392h12.868V.524M.524.524l12.952 12.952'/%3E%3C/svg%3E");
                }
            }
        }

        &:nth-child(6n + 1) {
            .team-list__img {
                background-color: var(--cl-orange-sec);
            }
        }
        &:nth-child(6n + 2) {
            .team-list__img {
                background-color: var(--cl-green);
            }
        }
        &:nth-child(6n + 3) {
            .team-list__img {
                background-color: var(--cl-pink);
            }
        }
        &:nth-child(6n + 4) {
            .team-list__img {
                background-color: var(--cl-yellow-sec);
            }
        }
        &:nth-child(6n + 5) {
            .team-list__img {
                background-color: var(--cl-green);
            }
        }
        &:nth-child(6n + 6) {
            .team-list__img {
                background-color: #88C1CD;
            }
        }
    }

    &__img {
        width: ac(248px, 150px);
        height: ac(248px, 150px);
        margin-right: 31px;
    }

    &__name {
        font-weight: 500;
        font-size: ac(26px, 18px);
        line-height: ac(36px, 22px);
    }

    &__position {
        margin-top: 7px;
        line-height: ac(34px, 24px);
        font-size: ac(18px, 16px);
        font-weight: 600;
        color: var(--cl-dark-green);
    }

    &__descr {
        margin-top: ac(17px, 10px);
        margin-bottom: ac(27px, 20px);
        @mixin max-line-length 4;
        max-height: calc(4 * 26px);
        text-align: left;
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
    }

    &__btn {
        display: inline-flex;
        padding-bottom: 2px;
        font-size: 18px;
        font-weight: 600;
        border-bottom: 1px solid var(--cl-black);
        transition: .3s ease;

        &::after {
            content: '';
            width: 14px;
            height: 14px;
            margin-left: 25px;
            transform: translate(-10px, 6px);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none'%3E%3Cpath stroke='%23000' d='M.524 13.392h12.868V.524M.524.524l12.952 12.952'/%3E%3C/svg%3E");
            transition: .3s ease;
        }
    }

    @mixin max-lg {
        &__descr {
            text-align: center;
        }

        &__img {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    @mixin max-md {
        &__descr {
            text-align: left;
        }

        &__img {
            margin-right: 31px;
            margin-bottom: 0;
        }

        &__col {
            align-items: flex-start;
        }

        &__select-box {
            justify-content: space-between;

            .insights__select {
                width: 100%;
                max-width: calc(50% - 8px);
            }
        }
    }

    @mixin max-sm {
        &__img {
            margin-right: 0;
            margin-bottom: 20px;
        }

        &__card {
            flex-direction: column;
            align-items: center;
        }

        &__descr {
            text-align: center;
        }

        &__col {
            align-items: center;
        }
    }
}