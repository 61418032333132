.shoes {
  margin-top: ac(80px, 60px);

  &--franchising {
    padding-top: ac(120px, 60px);
    padding-bottom: ac(80px, 40px);
    margin-top: 0;
    background-color: #b27335;

    .shoes {
      &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &__title {
        color: var(--cl-white);
      }

      &__slider {
        margin-top: ac(44px, 25px);
      }

      &__scrollbar {
        background: #fff;
        .swiper-scrollbar-drag {
          background: #fff;
        }
      }
    }
  }

  &__wrapper {
    padding-bottom: ac(124px, 80px, 375, 1440);
  }

  &__suptitle,
  &__title {
    width: 100%;
  }

  &__suptitle {
    margin-bottom: ac(15px, 10px);
    font-size: ac(20px, 16px);
    font-family: var(--font-sec);
    opacity: 0.5;
    color: var(--cl-navy);
  }

  &__scrollbar {
    width: calc(100% - 178px) !important;
    left: 0 !important;
    bottom: 29px !important;
    background: #000;
    height: 2px !important;
    border-radius: 0 !important;
    opacity: 0.3;

    .swiper-scrollbar-drag {
      top: -4px;
      background: #000;
      border-radius: 24px !important;
      height: 10px;
    }
  }

  &__nav {
    right: 0;
    left: unset;
    width: auto;
  }

  &__slider {
    margin-top: ac(56px, 25px);
    max-width: 720px;
    margin-left: 0;
  }

  &__card {
    border-radius: 20px;
    overflow: hidden;
    background-color: #11293c;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__text-box {
    padding: ac(88px, 56px, 375, 1440) ac(115px, 15px, 375, 1440)
      ac(32px, 22px, 375, 1440) ac(40px, 16px, 375, 1440);
  }

  &__scroll {
    position: relative;
    max-height: calc(ac(30px, 24px) * 8);

    &::after {
      content: '';
      position: absolute;
      top: -21px;
      left: 21px;
      width: 96%;
      height: 1px;
      background-color: var(--cl-green);
    }

    &::before {
      content: '';
      position: absolute;
      top: -48px;
      left: 0;
      width: 35px;
      height: 28px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='28' fill='none'%3E%3Cpath fill='%23569C3A' d='M.702 28H13.28a.7.7 0 0 0 .702-.702V14.713a.7.7 0 0 0-.702-.702H3.495v-.013a8.005 8.005 0 0 1 3.571-6.675l6.138-4.094c.805-.536 1.005-1.61.482-2.422C12.853-.5 11.559.126 11.264.326L5.126 4.42A11.515 11.515 0 0 0 0 14.005v13.3c0 .385.31.695.702.695Zm20.972 0h12.578c.386 0 .702-.31.702-.695V14.72a.705.705 0 0 0-.702-.702h-9.784v-.014a8.005 8.005 0 0 1 3.57-6.674l6.139-4.094c.805-.537.97-1.59.481-2.422-.722-1.252-2.126-.681-2.422-.482L26.1 4.426a11.477 11.477 0 0 0-5.127 9.579v13.3c0 .385.31.695.702.695Z'/%3E%3C/svg%3E")
        center / contain no-repeat;
    }
  }

  &__descr {
    font-size: ac(20px, 16px);
    line-height: ac(30px, 24px);
    padding-right: 10px;
    color: var(--cl-white);

    & > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__bottom {
    align-items: flex-start;
    /* height: 100%; */
    margin-top: auto;
    gap: 17px;
    padding: ac(32px, 16px, 375, 1440) ac(40px, 22px, 375, 1440)
      ac(40px, 28px, 375, 1440);
    background-color: #08304d;
  }

  &__ava {
    margin-right: ac(22px, 16px);

    width: ac(98px, 68px);
    height: ac(98px, 68px);
    padding: ac(5px, 3px);
    border: 1px solid var(--cl-light-blue);

    &,
    img {
      border-radius: 100%;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    margin-left: auto;
    width: 100%;
    max-width: 206px;
    min-height: 98px;
    padding: ac(20px, 16px) ac(20px, 8px);
    border-top: 1px solid var(--cl-light-blue);
    border-bottom: 1px solid var(--cl-light-blue);

    img {
      max-width: 165px;
      object-fit: contain;
    }
  }

  &__name {
    margin-top: 7px;
    font-size: ac(32px, 24px);
    font-weight: 400;
    line-height: ac(44px, 36px);
    color: var(--cl-white);
  }

  &__pos {
    margin-top: 6px;
    font-size: ac(20px, 16px);
    font-weight: 600;
    line-height: ac(34px, 28px);
    color: var(--cl-green);
  }

  @mixin xxxl {
    &__slider {
      max-width: unset;
    }
  }

  @mixin max-sm {
    &__title,
    &__suptitle {
      text-align: center;
    }
    &__bottom {
      flex-wrap: wrap;
    }

    &__logo {
      display: flex;
      justify-content: flex-end;
      max-width: unset;
      border-bottom: 0;
      min-height: 56px;
      padding-bottom: 0;
    }

    &__scroll {
      &::after {
        top: -21px;
        left: 21px;
        width: calc(100% - 21px);
      }
    }
  }
}
