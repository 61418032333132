.insights {
    margin-top: ac(80px, 60px);

    &__wrapper {
        align-items: flex-start;
    }

    &__select {
        display: flex;
        align-items: center;
        width: 268px;
        margin-left: auto;
        padding: 0 10px;
        height: 50px;

        border-radius: 5px;
        border: 1px solid #D1D1D1;
        border-bottom: 1px solid var(--cl-dark-green);
        background: var(--cl-light-green);
        

        &>span {
            font-size: 14px;
            line-height: 20px;
            font-family: var(--font-sec);
            flex-shrink: 0;
            color: var(--cl-dark-green);
        }

        .choices {
            &:before {
                right: 3px;
                width: 12px;
                height: 7px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7' fill='none'%3E%3Cpath fill='%234D8739' d='M11.853.646a.5.5 0 0 1 .001.707L6.389 6.837a.55.55 0 0 1-.78 0L.146 1.353A.5.5 0 1 1 .853.647L6 5.812 11.146.647a.5.5 0 0 1 .707-.001Z'/%3E%3C/svg%3E");
            }
            &__inner {
                background-color: transparent;
                padding: 0 30px 0 5px !important;
            }

            &__list--single {
                .choices__item {
                    padding-top: 9px;
                    font-size: 14px;
                    line-height: 20px;
                    font-family: var(--font-sec);
                    font-weight: 500;
                }
            }
        }
    }

    &__filter {
        margin-top: ac(37px, 23px);
        max-width: 927px;
        width: 100%;
        justify-content: flex-start;
        gap: 5px;

        span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            line-height: 24px;
        }
    }

    &__card-box {
        margin-top: ac(63px, 25px);
        gap: ac(64px, 20px) ac(40px, 20px);
    }

    &__card {
        padding-bottom: ac(27px, 17px);
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid var(--cl-green);
        cursor: pointer;
        height: 100%;
        transition: .3s ease;

        &:hover {
            img {
                transform: scale(1.1);
            }
        }

        &:hover {
            .insights__name, .insights__date {
                color: var(--cl-dark-green);
            }
        }
    }

    &__img {
        position: relative;
        width: 100%;
        height: ac(250px, 215px);
        overflow: hidden;

        img {
            transition: .3s ease-in-out;
        }
    }

    &__tag {
        font-size: 14px;
        line-height: 29px;
        padding: 0 8px;
        font-weight: 500;
        font-family: var(--font-sec);
        background-color: var(--cl-white);
        border-radius: 55px;
        z-index: 1;
    }

    &__descr {
        margin-top: ac(33px, 17px);
        font-size: ac(26px, 18px);
        line-height: ac(36px, 24px);
        font-weight: 500;
        margin-bottom: ac(23px, 19px);
        max-height: calc(ac(36px, 24px) * 3);
        @mixin max-line-length 3;
        transition: .3s ease;
    }

    &__bottom {
        display: flex;
        align-items: flex-end;
        margin-top: auto;
        gap: 5px;
        flex-wrap: wrap;
    }

    &__name, &__date {
        margin-right: ac(36px, 30px);
        font-size: 16px;
        font-weight: 400;
        transition: .3s ease;
    }

    &__date, &__arrow {
        flex-shrink: 0;
    }

    &__arrow {
        margin-bottom: 5px;
        margin-left: auto;
        width: 14px;
        height: 14px;
        margin-right: ac(0px, 4px);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left bottom;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14'%3E%3Cg fill='none' stroke='%234D8739'%3E%3Cpath d='M.354 13.222h12.868V.354' data-name='Контур 19'/%3E%3Cpath d='m.354.354 12.952 12.952' data-name='Контур 20'/%3E%3C/g%3E%3C/svg%3E");
        transition: .3s ease;
    }

    @mixin max-lg {
        &__name {
            width: 100%;
        }
    }
    @mixin max-md {
        &__card-box {
            max-width: 500px;
        }

        &__name {
            width: auto;
        }

        .ready__input {
            margin-bottom: 0;
        }
    }
    @mixin max-sm {
        &__title {
            margin-bottom: 15px;
            width: 100%;
        }
        &__name {
            width: 100%;
        }

        &__select {
            margin-left: 0;
        }
    }

    
}