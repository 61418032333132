.agreement {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @mixin sm {
        max-width: 345px;
    }

    a {
        display: inline-block;
        font-size: inherit;
        line-height: inherit;
        color: var(--cl-black);
        text-decoration: underline;
        transition: .3s ease;

        &:hover {
            color: var(--cl-green);
        }
    }

    .checkBox {
      display: flex;
      position: relative;
      width: 100%;

        input {
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            visibility: hidden;
        }

        input:checked + span:before {
            background: var(--cl-dark-green) url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6' fill='none'%3E%3Cpath fill='%23fff' d='M8.265.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L.72 3.53a.75.75 0 0 1 1.06-1.06l1.705 1.704L7.205.235a.75.75 0 0 1 1.06-.03Z'/%3E%3C/svg%3E") center / 9px 6px no-repeat;
        }
    
        span {
            padding-left: ac(40px, 30px);
            margin-bottom: 0;
            color: #878787;
            cursor: pointer;

            &, a {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                font-family: var(--font-sec);
            }
    

    
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: ac(8px, 0px);
                width: 16px;
                height: 16px;
                background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6' fill='none'%3E%3Cpath fill='%23fff' d='M8.265.205a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.075.015L.72 3.53a.75.75 0 0 1 1.06-1.06l1.705 1.704L7.205.235a.75.75 0 0 1 1.06-.03Z'/%3E%3C/svg%3E") center / 9px 6px no-repeat;
                border: 1px solid var(--cl-dark-green);
                border-radius: 2px;
                transition: .3s ease;
            }
        }
    }
}
input, textarea  {
    width: 100%;
    padding: 0px 20px;
    background-color: transparent;
    border-radius: 0;
    @mixin transparent-bg-input var(--cl-black);

    &,
    &::placeholder {
        color: var(--cl-black);
        font-size: 20px;
        font-weight: 400;
        line-height: 38px;

        @mixin max-md {
            font-size: 16px;
            line-height: 50px;
        }
    }

    &::placeholder {
        opacity: .3;
    }
}

textarea{
    padding: ac(14px, 10px) 12px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #C0C0C0;
    background:  #F6F9F5;
    border-bottom-color: var(--cl-green);
    transition: .3s ease;

    &,&::placeholder {
        font-size: 14px;
        line-height: 20px;
        font-family: var(--font-sec);
    }

    &::placeholder {
        opacity: 1;
        color: #878787;
    }

    height: 110px;
    resize: none;
    scrollbar-width: thin;
    scrollbar-color: var(--cl-dark-green) rgba(5, 21, 59, .2);
    overflow-x: hidden;
    overflow-y: auto;
    @mixin transparent-bg-input var(--cl-black);

    &,&::placeholder {
        line-height: ac(25px, 20px);
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(5, 21, 59, .2);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--cl-dark-green);
        border-radius: 10px;
    }
}

.single-range { 
    padding-right: 15px;
    height: 2px;
    border-radius: 0;
    border: none;
    box-shadow: none;
    background: lightgrey;
  
    .noUi-connect {
      background: orange;
    }
  
    .noUi-handle {
      background: orange;
      width: 18px;
      height: 18px;
      border: 2px solid white;
      cursor: pointer;
      border-radius: 50%;
      box-shadow: none;
  
      &:before,
      &:after {
        content: none;
      }
    }
  }
  
  .single-range-inputs {
    width: 100%;
    &__container {
      display: flex;
      align-items: center;
    }
  
    .single-range-input {
      border: 1px solid orange;
      background: transparent;
      border-radius: 4px;
      padding: 6px 10px;
      width: 38%;
      line-height: 1.2;
      height: auto;
      color: red;
  
      &:focus {
        border: 1px solid blue;
      }
    }
  
    span {
      font-size: 16px;
      line-height: 1;
      color: white;
  
      &.unit {
        margin-right: 2px;
      }
    }
}

form:not(.hero__form) {
    input:-webkit-autofill, input:-webkit-autofill:focus {
        box-shadow: 0 0 0 1000px #f6f9f5 inset;
        -webkit-text-fill-color: var(--cl-black);
    }
}
.choices {
    flex-grow: 1;
    margin-bottom: 0;

    &::after {
        display: none;
    }

    &::before {
        content: '';
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        width: 14px;
        height: 8px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8'%3E%3Cpath fill='none' stroke='%23000' d='m.354.353 6.671 6.671L13.696.353' data-name='Контур 26'/%3E%3C/svg%3E");
        transition: .3s ease;
    }

    &.is-open {
        &::before {
            transform: translateY(-50%) rotate(-180deg);
        }
    }

    &__inner {
        padding: 0 30px 0 20px!important;
        border-radius: 0px !important;
        min-height: 38px !important;
        display: flex;
        align-items: center;
    }

    .choices__list {
        width: 100%;
        overflow: hidden !important;
        display: flex;
        flex-direction: column;

        &--single {
            .choices__item {
                width: 100%;
                color: var(--cl-black);
                font-size: 20px;
                font-weight: 400;
                line-height: 38px;

                @mixin max-line-length 1;

                @mixin max-md {
                    font-size: 16px;
                    line-height: 50px;
                }
            }
        }

        &--dropdown {
            border-radius: 0px !important;
            z-index: 200;

            .choices__item, .choices__input {
                font-size: 18px;
                line-height: 24px;
                padding-right: 15px;
            }

            .choices__item {
                word-break: break-word;

                &:first-child, &::after {
                    display: none;
                }

                &.is-selected {
                    color: var(--cl-white);
                    background-color: var(--cl-green);
                }
            }
        }

        & > div {
            padding-right: 20px;
            width: calc(100% + 20px);
            max-height: 180px;
        }

        .simplebar-track.simplebar-vertical {
            width: 9px;
            right: 20px;
        }

        .simplebar-scrollbar::before {
            width: 5px;
            background-color: var(--cl-green);
            opacity: 1;
        }

    }

    .choices__inner,
    .choices__list--single {
        padding: 0;
        padding-bottom: 0 !important;
    }

    .choices__inner {
        background-color: var(--cl-white);
        border: 0;
    }

    .choices__item--selectable {
        font-size: 15px;
        font-weight: 300;
        line-height: 48px;
        opacity: 1;
    }
}

.daterangepicker {
    z-index: 10000 !important;
    margin-top: -17px !important;

    .drp-buttons .btn {
        padding: 0 8px;
        max-width: 100px;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0px 15px;
        border-radius: 5px;
        line-height: 34px;
        border: 1px solid var(--cl-green);

        &:hover {
            background-color: var(--cl-green);
        }
    }
    td.active,
    .daterangepicker td.active:hover {
        background-color: var(--cl-green) !important;
    }

    th select {
        scrollbar-width: thin;
        scrollbar-color: transparent var(--cl-green);

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background: var(--cl-green);
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: var(--cl-green);
        }
    }
}