.team {
    margin-top: ac(80px, 60px);
    background-color: #B27335;
    overflow: hidden;

    &__decor {
        width: ac(1234px, 900px);
        height: ac(1463px, 1100px);
        transform: translate(calc(-50% + 105px), calc(-50% - ac(17px, 40px)));
    }
    
    &__wrapper {
        padding: ac(94px, 60px) 0 114px;
    }

    &__slider-box {
        max-width: 1100px;
        margin: 0 auto;
    }

    &__slide {
        opacity: 0;
        &.swiper-slide-active {
          
            opacity: 1;

            .team__img, .team__text-box {
                opacity: 1;
                transform: translateX(0%);
            }
        }
    }

    &__img-box {
        margin-right: ac(90px, 30px);
        width: ac(385px, 250px);
        height: ac(385px, 250px);
        background-color: #BF813B;
        border: 10px solid rgba(255,255,255, .5);
    }

    &__img {
        width: 100%;
        height: 100%;

        transition: .5s ease;
        transform: translateX(-50%);
        opacity: 0;
    }

    &__text-box {
        transition: .5s ease;
        transform: translateX(100%);
        opacity: 0;
    }

    &__suptitle {
        font-size: ac(20px, 16px);
        line-height: 1em;
        font-family: var(--font-sec);
        opacity: .5;
        color: var(--cl-white);

    }

    &__name {
        width: 100%;
        margin-top: ac(22px, 15px);
        padding-bottom: ac(31px, 15px);
        border-bottom: 1px solid rgba(255, 255, 255, .5);
        color: var(--cl-white);

    }

    &__simplebar {
        position: relative;
        padding-left: ac(84px, 40px, 320, 1440);
        margin-top: ac(21px, 15px);
        width: calc(100% + 8px);
        max-height: calc(32px * 7);

        &::before {
            content: '';
            position: absolute;
            top: ac(11px, 5px, 320, 1440);
            left: ac(7px, 2px, 320, 1440);
            width: ac(50px, 25px, 320, 1440);
            height: ac(40px, 20px, 320, 1440);
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='40' fill='none'%3E%3Cpath fill='%23fff' d='M1.035 40h17.969a1 1 0 0 0 1.002-1.003V21.02a1 1 0 0 0-1.002-1.003H5.026v-.02c0-3.843 1.907-7.401 5.102-9.534l8.768-5.849c1.15-.767 1.435-2.3.688-3.46-1.19-1.868-3.038-.973-3.46-.688L7.356 6.314A16.45 16.45 0 0 0 .033 20.006v19.001c0 .55.442.993 1.002.993Zm29.961 0h17.969a.998.998 0 0 0 1.002-.993V21.03c0-.55-.452-1.003-1.002-1.003H34.987v-.02c0-3.843 1.907-7.401 5.102-9.534l8.767-5.849c1.15-.767 1.386-2.27.689-3.46-1.032-1.79-3.038-.973-3.46-.688l-8.769 5.849a16.395 16.395 0 0 0-7.323 13.682v19.001c0 .55.443.993 1.003.993Z' opacity='.5'/%3E%3C/svg%3E") left top / contain no-repeat;
        }
    }

    &__descr {
        padding-right: 8px;
        font-size: ac(20px, 16px);
        line-height: ac(32px, 20px);
        color: var(--cl-white);

        &>*:not(:last-child) {
            margin-bottom: ac(16px, 10px);
        }
    }

    &__btn {
        margin-top: ac(35px, 20px);
        margin-left: ac(84px, 40px, 320, 1440);
    }
    
    &__nav {
        width: auto;
        bottom: ac(-18px, 10px);
        left: ac(128px, 68px);
    }

    @mixin md {
        &__text-box {
            padding-top: 2px;
            width: ac(610px, 400px);
        }
    }

    @mixin max-md {
        &__decor {
            width: ac(1000px, 700px, 320, 768);
            height: ac(1200px, 900px, 320, 768);
            transform: translate(calc(-50% + ac(100px, 60px, 320, 768)), calc(-50% - ac(-300px, -120px, 320, 768)));
        }

        &__img-box {
            margin: 0 auto 25px;
            width: ac(320px, 250px, 320, 768);
            height: ac(320px, 250px, 320, 768);
        }

        &__img {
            &__decor {
                width: ac(1234px, 900px, 76);
                height: ac(1463px, 1100px);
                transform: translate(calc(-50% + 105px), calc(-50% - ac(17px, 40px)));
            }
        }

        .slider-nav {
            left: 50%;
            bottom: -20px;
            transform: translate(-50%, 100%);
        }
    }
}